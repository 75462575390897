import React from 'react'
import ImgError404 from '../../assets/img/illustrations/girl-doing-yoga-light.png';
const error_503 = () => {
  return (
      <div className="misc-wrapper text-center">
        <h2 className="mb-2 mx-2">¡Bajo Mantenimiento!</h2>
        <p className="mb-4 mx-2">Perdón por las molestias, pero estamos realizando algunos tareas de mantenimiento en este momento.</p>
        <div className="mt-3">
          <img src={ImgError404} alt="girl-doing-yoga-light" width={500} className="img-fluid" data-app-dark-img="illustrations/girl-doing-yoga-dark.png" data-app-light-img="illustrations/girl-doing-yoga-light.png" />
        </div>
      </div>
  )
}

export default error_503