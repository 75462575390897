import React, {useState, useEffect} from 'react'
import Cookies from 'universal-cookie';
import { Chart as Chartjs, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend, defaults } from 'chart.js'
import { Bar } from 'react-chartjs-2'

Chartjs.register(CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
  )

const cookies = new Cookies();
const dtUser = cookies.get('dtUser'); 
//console.log(dtUser.sl_dt_suc[0].suc_nombre);

export const BarChart1 = () => {

    const txt_Sucursal = dtUser.sl_dt_suc[0].suc_nombre
    //const txt_IdSuperv = dtUser.idsupervisor
    const txt_IdSuperv = dtUser.CodigoSupervisor
    const [chart, setCharts] = useState([])

    useEffect(() => {
        
        const showData1 = async () => {
            const apig = '/api-eje-grafico1'
            const body = { txt_Sucursal: txt_Sucursal, txt_IdSuperv: txt_IdSuperv };
            const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
            const response = await fetch(apig,opPe);
            const datadtos = await response.json();
            //console.log(datadtos);
            setCharts(datadtos)
        }
        showData1()
    },[txt_Sucursal,txt_IdSuperv])

    

    const options = {
        responsive: true,
        plugins: { legend: {display: false, position: 'left' }, title: { display: false, text: 'Chart.js Bar Chart', }, },

    }
    //label: 'My First Dataset',
    const data = {
        labels: chart?.map(x => x.Linea),
        datasets: [{
            label: 'S/ ',
            data: chart?.map(x => x.Count),
            backgroundColor: ['rgba(255, 99, 132, 0.2)',],
            borderColor: ['rgb(255, 99, 132)',],
            borderWidth: 1
        }]
    };

  return (
    <div>
        <Bar
            data={data}
            height={100}
            options={options}
        />
    </div>
  )
}

export const BarChart2 = () => {  
    const txt_Sucursal = dtUser.sl_dt_suc[0].suc_nombre
    //const txt_IdSuperv = dtUser.idsupervisor
    const txt_IdSuperv = dtUser.CodigoSupervisor
    const [chart, setCharts] = useState([])

    useEffect(() => { 
        const showData1 = async () => {
            const apig = '/api-eje-grafico2'
            const body = { txt_Sucursal: txt_Sucursal, txt_IdSuperv: txt_IdSuperv};
            const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
            const response = await fetch(apig,opPe);
            const datadtos = await response.json();
            //console.log(datadtos);
            setCharts(datadtos)

        }
        showData1()
    },[txt_Sucursal,txt_IdSuperv])

    const options = {
        responsive: true,
        plugins: { legend: {display: false, position: 'left' }, title: { display: false, text: 'Chart.js Bar Chart', }, },
    }
    //label: 'My First Dataset',
    const data = {
        labels: chart?.map(x => x.Canal),
        datasets: [{
            label: 'S/ ',
            data: chart?.map(x => x.Count1),
            backgroundColor: [ 'rgba(53, 162, 235, 0.2)', ],
            borderColor: [ 'rgb(53, 162, 235)', ],
            borderWidth: 1
        }]
    };

  return (
    <div>
        <Bar
            data={data}
            height={100}
            options={options}
        />
    </div>
  )
}
export const BarChart3 = () => {
    const txt_Sucursal = dtUser.sl_dt_suc[0].suc_nombre
    //const txt_IdSuperv = dtUser.idsupervisor
    const txt_IdSuperv = dtUser.CodigoSupervisor
    const [chart, setCharts] = useState([])

    useEffect(() => {
        
        const showData1 = async () => {
            const apig = '/api-eje-grafico3'
            const body = { txt_Sucursal: txt_Sucursal, txt_IdSuperv: txt_IdSuperv };
            const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
            const response = await fetch(apig,opPe);
            const datadtos = await response.json();
            //console.log(datadtos);
            setCharts(datadtos)

        }
        showData1()
    },[txt_Sucursal,txt_IdSuperv])

    const options = {
        responsive: true,
        plugins: { legend: {display: false, position: 'left', labels: { font: { size: 8 } } }, title: { display: false, text: 'Chart.js Bar Chart', }, },      
    }

    const data = {
        labels: chart?.map(x => x.Canal),
        datasets: [{
            label: 'S/ ',
            data: chart?.map(x => x.Count1),
            backgroundColor: [ 'rgba(53, 162, 235, 0.2)', ],
            borderColor: [ 'rgb(53, 162, 235)', ],
            borderWidth: 1
        }]
    };

  return (
    <div>
        <Bar
            data={data}
            height={100}
            options={options}
        />
    </div>
  )
}

export const BarChart4 = ({dtPorLineaVe}) => {
    const txt_Sucursal = dtUser.sl_dt_suc[0].suc_nombre
    //const txt_IdSuperv = dtUser.idsupervisor
    const txt_IdSuperv = dtUser.CodigoSupervisor
    const txt_CodigoVe = dtPorLineaVe.CodigoVendedor
    const txt_GrupoVen = dtPorLineaVe.CodigoGrupoVendedor
    const [chart, setCharts] = useState([])

    useEffect(() => {
        
        const showData1 = async () => {
            const apig = '/api-eje-grafico4'
            const body = { txt_Sucursal: txt_Sucursal, txt_IdSuperv: txt_IdSuperv, txt_CodigoVe: txt_CodigoVe, txt_GrupoVen: txt_GrupoVen };
            const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
            const response = await fetch(apig,opPe);
            const datadtos = await response.json();
            //console.log(datadtos);
            setCharts(datadtos)

        }
        showData1()
    },[txt_Sucursal,txt_IdSuperv,txt_CodigoVe,txt_GrupoVen])
    defaults.font.size = 9;
    const options = {
        indexAxis: "y",
        elements: { bar: { borderWidth: 2 } },
        responsive: true,
        plugins: { legend: {display: false, position: 'right' }, title: { display: true, text: 'AVANCE DE VENTAS POR CATEGORIA', }, },      
    }

    const data = {
        labels: chart?.map(x => x.Producto),
        datasets: [{
            label: 'S/ ',
            data: chart?.map(x => x.CantVent),
            backgroundColor: [ 'rgba(53, 162, 235, 0.2)', ],
            borderColor: [ 'rgb(53, 162, 235)', ],
            borderWidth: 1
        }]
    };

  return (
    <div>
        <Bar
            style={{fontSize: '8px'}}
            data={data}
            height={200}
            options={options}
        />
    </div>
  )
}

export const BarChart5 = ({dtPorLineaVe}) => {
    const txt_Sucursal = dtUser.sl_dt_suc[0].suc_nombre
    //const txt_IdSuperv = dtUser.idsupervisor
    const txt_IdSuperv = dtUser.CodigoSupervisor
    const txt_CodigoVe = dtPorLineaVe.CodigoVendedor
    const txt_GrupoVen = dtPorLineaVe.CodigoGrupoVendedor
    const [chart, setCharts] = useState([])

    useEffect(() => {
        
        const showData1 = async () => {
            const apig = '/api-eje-grafico5'
            const body = { txt_Sucursal: txt_Sucursal, txt_IdSuperv: txt_IdSuperv, txt_CodigoVe: txt_CodigoVe, txt_GrupoVen: txt_GrupoVen };
            const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
            const response = await fetch(apig,opPe);
            const datadtos = await response.json();
            //console.log(datadtos);
            setCharts(datadtos)

        }
        showData1()
    },[txt_Sucursal,txt_IdSuperv,txt_CodigoVe,txt_GrupoVen])
    defaults.font.size = 9;
    const options = {
        indexAxis: "y",
        elements: { bar: { borderWidth: 2 } },
        responsive: true,
        plugins: { legend: {display: false, position: 'right' }, title: { display: true, text: 'AVANCE DE VENTAS POR UNIDAD DE NEGOCIO', }, },      
    }

    const data = {
        labels: chart?.map(x => x.Producto),
        datasets: [{
            label: 'S/ ',
            data: chart?.map(x => x.CantVent),
            backgroundColor: [ 'rgba(255, 99, 132, 0.2)', ],
            borderColor: [ 'rgb(255, 99, 132)', ],
            borderWidth: 1
        }]
    };

  return (
    <div>
        <Bar
            style={{fontSize: '8px'}}
            data={data}
            height={200}
            options={options}
        />
    </div>
  )
}

/**export const BarChart3 = () => {

    const [chart, setCharts] = useState([])

    useEffect(() => {
        const apig = '/api-eje-grafico2'
        const body = { sl_t_suc: "piura",};
        const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        const showData = async () => {
            const response = await fetch(apig,opPe);
            const datadtos = await response.json();
            console.log(datadtos);
            setCharts(datadtos)

        }
        showData()
    },[])

    

    const options = {
        responsive: true,
        plugins: {
            legend: {
              position: 'top' ,
            },
            title: {
              display: false,
              text: 'Chart.js Bar Chart',
            },
          },
        
        
    }

    const data = {
        labels: chart?.map(x => x.Canal),
        datasets: [
            {
                label: 'My First Dataset',
                data: chart?.map(x => x.Count1),
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 1
            },
            {
                label: 'Dataset 2',
                data: chart?.map(x => x.Count2),
                backgroundColor: 'rgba(53, 162, 235, 0.2)',
                borderColor: 'rgb(53, 162, 235)',
                borderWidth: 1

            },
            {
                label: 'Dataset 3',
                data: chart?.map(x => x.Count3),
                backgroundColor: 'rgba(53, 20, 155, 0.2)',
                borderColor: 'rgb(53, 20, 155)',
                borderWidth: 1

            },
        
        ]
    };






  return (
    <div>
        <Bar
            data={data}
            width={500}
            options={options}
        />
    </div>
  )
}**/
//export  default BarChart    