import React, {useState, useEffect} from 'react'
import Cookies from 'universal-cookie';

export const SelectSucursal = ({ id, value, onChange, disabled}) => {
  const cookies = new Cookies();
  const dtUser = cookies.get('dtUser');
  //const dtgrve = dtUser.ls_gr_ven SucursalSeleccionado
  //const dtSucu = dtUser.sl_dt_suc[0].suc_nombre
  return (
    <select id={id} className="form-select form-select-sm" value={value} onChange={onChange} disabled={disabled}>
        {
          dtUser.sl_dt_suc?.map((item,index) => {
            return <option value={item?.suc_cod} key={index} >{item?.suc_nombre}</option>
          })
        }
    </select>
  )
}

export const SelectTipo = ({ id, url, value, onChange, disabled}) => {
  const [select, setSelect] = useState([])
  useEffect(() => {
    const showData1 = async () => {
      const apig = url
      //const body = { txt_tipo: 'TE', };
      const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
      const response = await fetch(apig,opPe);
      const datadtos = await response.json();
      //console.log(datadtos);
      setSelect(datadtos)
    }
    showData1()
  },[url])

  return (
    <select id={id} className="form-select form-select-sm" value={value} onChange={onChange} disabled={disabled}>
        <option value='9999' >-- Seleccione --</option>
        {
          select?.map((item,index) => {
            return <option value={item?.cot_cod} key={index} >{item?.cot_descri1}</option>
          })
        }
    </select>
  )
}
