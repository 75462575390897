/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
//useEffect , useEffect, useCallback
import React, { useState,useEffect, useCallback } from "react";
import Cookies from 'universal-cookie';
import {Tooltip, Box }from '@mui/material';
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { SelectTipo } from '../components/Selects/SelectsPrincipales';

/**
 *
 * <div class="dropdown">
                    <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown"><i class="bx bx-dots-vertical-rounded"></i></button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="javascript:void(0);"><i class="bx bx-edit-alt me-1"></i> Edit</a>
                        <a class="dropdown-item" href="javascript:void(0);"><i class="bx bx-trash me-1"></i> Delete</a>
                    </div>
                </div>
 *
 */

const MasterClientes = () => {
    const cookies = new Cookies();
    const dtUser = cookies.get('dtUser');
    const dtgrve = dtUser.ls_gr_ven
    const dtcodu = dtUser.usu_dni
    const [pending, setPending] = useState(false);
    const [users, setUsers] = useState( [] )

    useEffect(() => {
        savePathInCookie();
      }, []);
      function savePathInCookie() {
        const path = window.location.pathname.substr(1); // Obtiene la parte después de la barra diagonal
    
        // Guarda el valor en una cookie con nombre "ultimapag"
        document.cookie = `ultimapag=${path}`;
      }

    const [sl_filtro, setsl_filtro] = useState('');
    const changeSl_filtro = (event) => { setsl_filtro(event.target.value); }
    //var sucur = dtUser.SUCURSAL const txt_Sucursal = dtUser.sl_dt_suc[0].suc_nombre
    //if (sucur === 'PIURA') { sucur = '01'; } else if (sucur === 'TUMBES') { sucur = '02'; } else if (sucur === 'TRUJILLO') { sucur = '03'; } else if (sucur === 'CHIMBOTE') { sucur = '04'; } else if (sucur === 'CHICLAYO') { sucur = '05'; } else if (sucur === 'CAJAMARCA') { sucur = '06'; } else if (sucur === 'HUAMACHUCO') { sucur = '08'; } else { sucur = '9999'; }
    const dtSucu = dtUser.sl_dt_suc[0].suc_nombre;
    const [sl_t_suc2] = useState(dtSucu);//
    const [sl_t_suc, setsl_t_suc] = useState(dtUser.sl_dt_suc[0].suc_coo);
    const changeSl_t_suc = (event) => {
        setsl_t_suc(event.target.value);
        //fc_lstGrupoVenta(event.target.value);
    }
    const [inp_text, setInp_text] = useState('');
    const changeInp_text = (event) => { setInp_text(event.target.value); }
    const [sl_t_cat, setsl_t_cat] = useState('');
    const changeSl_t_cat = (event) => {
        setInp_text("")
        setsl_t_cat(event.target.value);
    }

    const [sl_t_grupoventa, setsl_t_grupoventa] = useState('9999');
    const changeSl_t_grupoventa = (event) => { setsl_t_grupoventa(event.target.value); }
    const [sl_grupoventa, setSl_grupoventa] = useState([]);
    const fc_lstGrupoVenta = useCallback(async (r_suc) => {
        const url_ven = '/post-data-grupoventas'
        let body = { usu_tipo: dtUser.tpu_id, sl_t_suc: r_suc, usu_dni: dtcodu };
        const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        const response = await fetch(url_ven,opPe);
        const data = await response.json();
        setSl_grupoventa(data)
    },[dtcodu,dtUser.tpu_id])
    useEffect(() => {
        fc_lstGrupoVenta(sl_t_suc)
    },[fc_lstGrupoVenta,sl_t_suc])

    const [sl_t_mesas, setsl_mesas] = useState('');
    const changeSetsl_mesas = (event) => { setsl_mesas(event.target.value); }
    
    const URL = '/post-clientes'
    const url1 = '/api-sl-mesas';
    let body_t = { 
        idt_text: dtUser.tpu_id,
        sl_t_suc:sl_t_suc, 
        sl_filtro: sl_filtro, 
        inp_text: inp_text,
        sl_t_cat:sl_t_cat, 
        lst_graven: dtgrve, 
        sl_t_grupoventa: sl_t_grupoventa,
        sl_t_mesas: sl_t_mesas ,};

    const opti_t = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body_t) }
    const columns = [
        { name: 'Sucursal',     selector: row => row.Sucursal,         cellExport: row => row.Sucursal,         sortable: true },
        { name: 'Codigo',       selector: row => row.CodCliente,       cellExport: row => row.CodCliente,       sortable: true, width: '9rem' },
        { name: 'Cliente',      selector: row => row.Cliente,          cellExport: row => row.Cliente,          sortable: true, width: '15rem' },
        { name: 'Mesa',         selector: row => row.Mesa,       cellExport: row => row.Mesa,       sortable: true },
        { name: 'GrupoVenta',   selector: row => row.GrupoVenta,       cellExport: row => row.GrupoVenta,       sortable: true },
        { name: 'Identidad',    selector: row => row.DocIdentidad,     cellExport: row => row.DocIdentidad,     sortable: true },
        { name: 'Categoria',    selector: row => row.CategoriaCliente, cellExport: row => row.CategoriaCliente, sortable: true },
        { name: 'Codigo Ruta',  selector: row => row.RutaCodigo,       cellExport: row => row.RutaCodigo,       sortable: true },
        { name: 'Direccion',    selector: row => row.Direccion,        cellExport: row => row.Direccion,        sortable: true, width: '15rem' },
        //{ name: 'Saldo',        selector: row => row.Saldo,            cellExport: row => row.Saldo,            sortable: true },
        { name: 'AvanceP.',     selector: row => row.avancemp,         cellExport: row => row.avancemp,         sortable: true },
        
    ];
    const onClickProce = async () => {
        setPending(true)
        document.getElementById('btn_procesar').disabled = "true";
        document.getElementById('btn_procesar_icon').setAttribute('class', 'd-none');
        document.getElementById('btn_procesar_spinner').className = 'spinner-border';

        const showData = async () => {
            const response = await fetch(URL,opti_t);
            const data = await response.json();
            const tableData = { columns, data, };
            setUsers(tableData)
            document.getElementById('btn_procesar').disabled = "";
            document.getElementById('btn_procesar_icon').setAttribute('class', 'bx bx-cloud-download bx-ms');
            document.getElementById('btn_procesar_spinner').className = 'spinner-border d-none';
            setPending(false)
        }
        showData();
    }

    const onClickExcel = async () => {
        const fileType = "xlsx"
        const nameFileType = "LST_CLIENTES"
        document.getElementById('btn_procesar_excel').disabled = "true";
        document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'd-none');
        document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border';
        const showData = async () => {
          const response = await fetch(URL,opti_t);
          const datadtos = await response.json();
          const ws = XLSX.utils.json_to_sheet(datadtos);
          const wb = {Sheets:{data:ws}, SheetNames:["data"]};
          const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
          const data = new Blob([excelBuffer], {type:fileType});
          FileSaver.saveAs(data, `${nameFileType}.xlsx`);

          document.getElementById('btn_procesar_excel').disabled = "";
          document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
          document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';
        }
        showData();
    }
    

  return (
    <div>
        <h4 className="fw-bold py-3 mb-4 d-none"> Filtrar Por </h4>
        <div className="card card-action mb-4">
            <div className="card-header">
                <div className="card-action-title">Filtrar Por:</div>
                <div className="card-action-element">
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                            <a href="javascript:void(0);" className="card-expand d-none"><i className="tf-icons bx bx-fullscreen"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body">
                    
                <div className="row">
                    <div className="col-md-2">Sucursal:</div>
                    <div className="col-md-2">
                        <select className="form-select form-select-sm"  value={sl_t_suc} onChange={changeSl_t_suc} >
                            {
                                dtUser.sl_dt_suc?.map((item,index) => {
                                    return <option value={item?.suc_coo} key={index} >{item?.suc_nombre}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-2">Mesa:</div>
                    <div className="col-md-2">
                    <SelectTipo id="slc_semanas" url={url1+'?idt_text='+dtUser.tpu_id+'&sl_t_suc='+sl_t_suc2+'&inp_text='+dtcodu} value={sl_t_mesas} onChange={changeSetsl_mesas} />
                  </div>
                    <div className="col-md-2">GrupoVenta:</div>
                    <div className="col-md-2">
                        <select className="form-select form-select-sm"  value={sl_t_grupoventa} onChange={changeSl_t_grupoventa} >
                            <option value={"9999"} >TODOS</option>
                            {   
                                sl_grupoventa?.map((item,index) => {
                                    return <option value={item?.GrupoVenta} key={index} >{item?.DescripcionGrupoVentas}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-md-2">Filtro:</div>

                    <div className="col-md-4">
                        <Tooltip title="Seleccionar filtro" placement="top">
                        <Box>
                            <select className="form-select form-select-sm"  value={sl_filtro} onChange={changeSl_filtro} >
                                <option value="" >--Seleccione--</option>
                                <option value={"a.CodigoCliente"} >Codigo</option>
                                <option value={"replace(a.Nombre,',','')"} >Nombre</option>
                                <option value={"a.NumeroDocumento"} >DocIdentidad</option>
                                <option value={"a.Categoria"} >Categoria</option>
                            </select>
                        </Box>
                        </Tooltip>
                    </div>
                    <div className="col-md-4">
                        {
                            (sl_filtro==='a.Categoria') ?
                                <select id="sl_categoria" className="form-select form-select-sm"  value={sl_t_cat} onChange={changeSl_t_cat}  >
                                    <option value={"9999"} >TODOS</option>
                                    <option value={"Mayorista"} >Mayorista</option>
                                    <option value={"Mercados"} >Mercados</option>
                                    <option value={"Bodegas"} >Bodegas</option>
                                </select>
                            :   <input type="text" id="txt_filtro" name="txt_filtro" className="form-control form-control-sm" value={inp_text} onChange={changeInp_text} ></input>
                        }
                    </div>
                    <div className="col-md-2 text-end ">
                        <Tooltip title="Consultar información" placement="top">
                            <button className="btn btn-primary btn-sm" id="btn_procesar" onClick={onClickProce} >
                                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                            </button>
                        </Tooltip>
                        <Tooltip title="Exportar Excel" placement="top">
                            <button className="btn btn-success btn-sm" id="btn_procesar_excel" onClick={onClickExcel} >
                                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                                <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div className="card card-action mb-4">
            <div className="card-header">
                <div className="card-action-title">Lista Clientes</div>
                <div className="card-action-element">
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                            <a href="javascript:void(0);" className="card-expand d-none" ><i className="tf-icons bx bx-fullscreen"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body">
                <div className="card-datatable table-responsive">
                    <DataTableExtensions {...users} >
                    <DataTable
                        Header
                        defaultSortField="id"
                        defaultSortAsc={true}
                        pagination
                        responsive
                        highlightOnHover
                        progressPending={pending}
                        noContextMenu="No hay registros para mostrar"
                    />
                    </DataTableExtensions>
                </div>
            </div>
        </div>
        <div className="modal fade" id="basicModal" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel1">Modal title</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="row">
                    <div className="col mb-3">
                        <label htmlFor="nameBasic" className="form-label">Name</label>
                        <input type="text" id="nameBasic" className="form-control" placeholder="Enter Name" />
                    </div>
                    </div>
                    <div className="row g-2">
                    <div className="col mb-0">
                        <label htmlFor="emailBasic" className="form-label">Email</label>
                        <input type="text" id="emailBasic" className="form-control" placeholder="xxxx@xxx.xx" />
                    </div>
                    <div className="col mb-0">
                        <label htmlFor="dobBasic" className="form-label">DOB</label>
                        <input type="text" id="dobBasic" className="form-control" placeholder="DD / MM / YY" />
                    </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button>
                </div>
                </div>
            </div>
            </div>
    </div>
  )
}

export default MasterClientes