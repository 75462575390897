import { useState } from "react";
import * as RB from "reactstrap";
import * as FaIcons from "react-icons/fa";
import React, { useEffect } from 'react'
import { ToastContainer } from 'react-toastify';
import {getDateA,getDateD,notify_warning} from '../components/utils';
import {Tooltip, Box }from '@mui/material';
//import DataTable from "react-data-table-component";
//import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import Select from 'react-select';
//import { downloadExcel } from "react-export-table-to-excel";
import InputMask from 'react-input-mask';
//import {Prueba} from './pru_view';
//import DatePicker from "react-datepicker";  
//import "react-datepicker/dist/react-datepicker.css";

/** excel */
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const Home =  () =>{
  
  const URL = '/post-data' //api de la data;
  //const URL = '/api' //api de la data;
  /** inputs */
  const [sl_t_suc, setsl_t_suc] = useState('');
  const changeSl_t_suc = (event) => { setsl_t_suc(event.target.value); };
  const [sl_t_po1, setsl_t_po1] = useState('T101');
  const changeSl_t_po1 = (event) => { setsl_t_po1(event.target.value); };
  const [sl_t_po4, setsl_t_po4] = useState('Todos');
  const changeSl_t_po4 = (event) => { setsl_t_po4(event.target.value); };
  const [sl_t_po6, setsl_t_po6] = useState('TF01');
  const changeSl_t_po6 = (event) => { setsl_t_po6(event.target.value); };
  const [sl_t_agr, setsl_t_agr] = useState([]);
  const [ip_f_ann, setip_f_ann] = useState(getDateA());
  const changeIp_f_ann = (event) => { setip_f_ann(event.target.value); };

  const [ip_f_did, setip_f_did] = useState(getDateD("-"));
  const changeIp_f_did = (event) => { setip_f_did(event.target.value); };
  const [ip_f_dih, setip_f_dih] = useState(getDateD("-"));
  const changeIp_f_dih = (event) => { setip_f_dih(event.target.value); };

  /** Listar excel */
  //const [pending, setPending] = useState(true);
  //const [users, setUsers] = useState( [] )

  //**guardar en el cookie
  useEffect(() => {
    savePathInCookie();
  }, []);
  function savePathInCookie() {
    const path = window.location.pathname.substr(1); // Obtiene la parte después de la barra diagonal

    // Guarda el valor en una cookie con nombre "ultimapag"
    document.cookie = `ultimapag=${path}`;
  }

  /** Fu para dar click en el tipo de select de fechas */
  const clickSTipfec = (event) => {
    if (sl_t_po6 === "TF02" ){
      document.getElementById('inp_perd').className = "d-none";
      document.getElementById('inp_perh').className = "d-none";
      document.getElementById('inp_per').className = "col-md-3" ;
      setTimeout(() => {
        document.getElementById('ip_f_ann').select();
      }, 100);
    }else{
      document.getElementById('inp_perd').className = "col-md-3";
      document.getElementById('inp_perh').className = "col-md-3";
      document.getElementById('inp_per').className = "d-none" ;
      setTimeout(() => {
        document.getElementById('ip_f_did').select();
      }, 100);
    }

  }
  
  const onKeyDownFdesde = event => {
        if (event.key === 'Enter') {
          console.log('do validate')
          document.getElementById('ip_f_dih').select();
    }
  };

  // Fuc para enviar valores por ele api de los datos para el backend
  let body = { 
    sl_t_suc: sl_t_suc, 
    sl_t_po1: sl_t_po1,
    sl_t_po4: sl_t_po4,
    sl_t_po6: sl_t_po6,
    sl_t_agr: sl_t_agr,
    ip_f_ann: ip_f_ann,
    ip_f_did: ip_f_did.replace("-","").replace("-",""),
    ip_f_dih: ip_f_dih.replace("-","").replace("-","")
  };
  // Estructura de envio por metodo POST, asi mismo enviando los datos en formato js
  const opPe = {
    method: "POST",
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body)
  }

  /** Func para exportar datos en un excel 
  const onClickExcel = async () => {
    if (!sl_t_suc) { notify_warning("Selecionar Sucursal") }

    if (sl_t_suc ) {
      const header = [
        "IDSucursal"
        ,"Sucursal"
        ,"Estado"
        ,"Periodo"
        ,"FechaEmision"
        ,"CodCliente"
        ,"Cliente"
        ,"DocIdentidad"
        ,"Direccion"
        ,"CategoriaCliente"
        ,"CodTipoNegocio"
        ,"TipoNegocio"
        ,"Cod_Vehiculo"
        ,"Vehiculo"
        ,"Placa"
        ,"Conductor"
        ,"Documento"
        ,"CondVenta"
        ,"TipoItem"
        ,"Motivo_Anulacion"
        ,"IDLinea"
        ,"Linea"
        ,"Marca"
        ,"Familia"
        ,"SubFamilia"
        ,"CodProducto"
        ,"Producto"
        ,"Cantidad"
        ,"Unidad"
        ,"Factor"
        ,"CantidaBase"
        ,"Cantidad_Rechazo"
        ,"Monto_Origen"
        ,"MontoRechazo"
        ,"Cod_Promocion"
        ,"Promocion"
        ,"ValorVentaBonif"
        ,"CodReglaDscto"
        ,"DescuentoPorcentual"
        ,"ValorDescuento"
        ,"VentaAfecta"
        ,"ValorIGV"
        ,"VentaTotal"
        ,"ID_Agente"
        ,"CodVendedor"
        ,"Vendedor"
        ,"CodZona"
        ,"Zona"
        ,"Cod_Concepto"
        ,"Concepto"
        ,"TipoDoc_Referencia"
        ,"Doc_Referencia"
        ,"TipoDeCambio"
        ,"GUIA"
        ,"CostoReal"
        ,"CostoPromedio"
        ,"GrupoVenta"
        ,"Mesa"
        ,"Canal"
        ,"Proveedor"
        ,"Categoria"
        ,"SubCategoria"
        ,"IdSupervisor"
      ];
      const response = await fetch(URL,opPe);
      const data = await response.json();
      setUsers(data.recordsets[0])
      downloadExcel({
        fileName: "react-export-table-to-excel -> downloadExcel method",
        sheet: "react-export-table-to-excel",
        tablePayload: {
          header,
          // accept two different data structures
          body: users,
        },
      });
    }
  }**/
  /**const onClickExcel = async () => {
    document.getElementById('btn_procesar_excel').disabled = "true";
    document.getElementById('btn_excel_icon').setAttribute('class', 'd-none');
    document.getElementById('btn_excel_spinner').className = 'me-1 spinner-border-sm spinner-border';
  }**/
  //const [users, setUsers] = useState([]);
  
  /** Funciona 1
  const onClickExcel = async () => {
    document.getElementById('btn_procesar_excel').disabled = "true";
    document.getElementById('btn_excel_icon').setAttribute('class', 'd-none');
    document.getElementById('btn_excel_spinner').className = 'me-1 spinner-border-sm spinner-border';

    if (!sl_t_suc) { 
      notify_warning("Selecionar Sucursal")
      setTimeout(() => { 
        document.getElementById('btn_procesar_excel').disabled = "";
        document.getElementById('btn_excel_icon').setAttribute('class', 'me-1');
        document.getElementById('btn_excel_spinner').className = 'me-1 d-none spinner-border-sm spinner-border';
      }, 1000);

    }
    //if (!sl_t_agr.length) { notify_warning("Selecionar un nivel!") }
    if (sl_t_suc ) {
      const header = [
        "IDSucursal"
        ,"Sucursal"
        ,"Estado"
        ,"Periodo"
        ,"FechaEmision"
        ,"CodCliente"
        ,"Cliente"
        ,"DocIdentidad"
        ,"Direccion"
        ,"CategoriaCliente"
      ];
      const response = await fetch(URL,opPe);
      const data = await response.json();
      //setUsers(data)
      downloadExcel({
        fileName: "react-export-table-to-excel -> downloadExcel method",
        sheet: "react-export-table-to-excel",
        tablePayload: {
          header,
          // accept two different data structures
          body: data,
        },
      });
    
      document.getElementById('btn_procesar_excel').disabled = "";
      document.getElementById('btn_excel_icon').setAttribute('class', 'me-1');
      document.getElementById('btn_excel_spinner').className = 'me-1 d-none spinner-border-sm spinner-border';
    }
  }
  /** Funciona 2 **/
  const fileType = "xlsx"
  const nameFileType = "Ejemplo"
  //const [users, setUsers] = useState([]);
  const onClickExcel = async () => {
    document.getElementById('btn_procesar_excel').disabled = "true";
    document.getElementById('btn_excel_icon').setAttribute('class', 'd-none');
    document.getElementById('btn_excel_spinner').className = 'me-1 spinner-border-sm spinner-border';

    if (!sl_t_suc) { 
      notify_warning("Selecionar Sucursal")
      setTimeout(() => { 
        document.getElementById('btn_procesar_excel').disabled = "";
        document.getElementById('btn_excel_icon').setAttribute('class', 'me-1');
        document.getElementById('btn_excel_spinner').className = 'me-1 d-none spinner-border-sm spinner-border';
      }, 1000);

    }

    if (sl_t_suc ) {
      const showData = async () => {
        const response = await fetch(URL,opPe);
        const datadtos = await response.json();
        //setTimeout(()=> {
          console.log("datadtos",datadtos);
          //setUsers(datadtos)
        //}, 1000)
        const ws = XLSX.utils.json_to_sheet(datadtos);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);

        document.getElementById('btn_procesar_excel').disabled = "";
        document.getElementById('btn_excel_icon').setAttribute('class', 'me-1');
        document.getElementById('btn_excel_spinner').className = 'me-1 d-none spinner-border-sm spinner-border';
      }
      showData();        
    }
  }
  /** funciona 1
  const onClickExcel = async () => {
    if (!sl_t_suc) { notify_warning("Selecionar Sucursal") }

    if (sl_t_suc ) {
      const showData = async () => {
        const response = await fetch(URL,opPe);
        const datadtos = await response.json();
        setTimeout(()=> {
          const dataarr = [{
            category:"product",
            data:datadtos.recordsets[0]
          }]
          setProductData(dataarr)
        }, 1000)
        productData.map((item, index)=> {
           console.log("item", item)
           item['json'] = XLSX.utils.json_to_sheet(item.data);
        })
        const obj = {
          Sheets:{},
          SheetNames:[]
        }
        productData.map((item, key)=> {
          return( obj.Sheets[item.category] = item.json,
          obj.SheetNames.push(item.category)
          )
        })
        console.log("obj", obj)
        const test = {...obj}
        const excelBuffer = XLSX.write(test, {bookType:"xlsx", type:"array"});
        const nwdata = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(nwdata, "myfile"+".xlsx")
      }
      showData();        
    }
  }**/

  const options = [
    { value: 'N01', label: 'Clase' },
    { value: 'Cliente', label: 'Cliente' },
    { value: 'N03', label: 'Giro' },
    { value: 'N04', label: 'Linea' },
    { value: 'N05', label: 'Mesa' },
    { value: 'N06', label: 'Proveedor' },
    { value: 'N07', label: 'Producto' },
    { value: 'N08', label: 'Ruta' },
    { value: 'N09', label: 'Vendedor' },
    { value: 'N10', label: 'Zona' },

  ]
  

    return (
      <div >
        
        <RB.Container className='py-4'>           
          <RB.Card className='w-100 '>
            <RB.CardHeader>
              <RB.CardTitle tag="h5">
                Listar
              </RB.CardTitle>
              <RB.CardSubtitle className="text-muted" tag="h6" >
                Datos del Sql Server
              </RB.CardSubtitle>
            </RB.CardHeader>
            <RB.CardBody>
            <RB.Row>
                <RB.Col lg={8}>
                  <RB.Row>
                    <RB.Col md={6}>
                      <Tooltip title="Selecionar Sucursales" placement="top">
                        <Box>
                          <RB.FormGroup>
                            <RB.Label >Sucursales</RB.Label>
                            <RB.Input bsSize="sm" className="mb-3" id="sl_t_suc" name="sl_t_suc" type="select"  value={sl_t_suc}  onChange={changeSl_t_suc}  >
                              <option value={""} >-- Elija --</option>
                              <option value={"0010"} >Tumbes</option>
                              <option value={"0012"} >Trujillo</option>
                              <option value={"0014"} >Chiclayo</option>
                              <option value={"0015"} >Chimbote</option>
                              <option value={"0016"} >Piura</option>
                              <option value={"0017"} >Huamachuco</option>
                              <option value={"006"} >Cajamarca</option>
                              <option value={"9999"} >Todos</option>
                            </RB.Input>
                            
                          </RB.FormGroup>
                        </Box>
                      </Tooltip>
                    </RB.Col>
                    <RB.Col md={3}>
                      <RB.FormGroup>
                      <RB.Label >Seleccione</RB.Label>
                        <RB.Input bsSize="sm" className="mb-0" id="sl_t_po1" name="sl_t_po1" type="select" value={sl_t_po1} onChange={changeSl_t_po1}  >
                          <option value={"T101"} >Facturación</option>
                          <option value={"T102"} >Cobertura</option>
                        </RB.Input>  
                      </RB.FormGroup>
                    </RB.Col>
                    <RB.Col md={3}>
                      <RB.FormGroup>
                        <RB.Label >Canales</RB.Label>
                        <RB.Input bsSize="sm" className="mb-0" id="sl_t_po4" name="sl_t_po4" type="select" value={sl_t_po4} onChange={changeSl_t_po4}  >
                          <option value={"Todos"} >Todos</option>
                          <option value={"Mayorista"}>Mayoristas</option>
                          <option value={"Mercados"} >Mercados</option>
                          <option value={"Bodegas"} >Bodegas</option>
                        </RB.Input>  
                      </RB.FormGroup>
                    </RB.Col> 
                  </RB.Row>
                  <RB.Row>
                    <RB.Col md={3}>
                      <RB.FormGroup>
                        <RB.Label >Fechas</RB.Label>
                        <RB.Input bsSize="sm" 
                          className="mb-0" 
                          id="sl_t_po6" 
                          name="sl_t_po6" 
                          type="select" 
                          value={sl_t_po6}  
                          onChange={changeSl_t_po6} 
                          onClick={clickSTipfec} 
                          
                        >
                          <option value={"TF01"} >Rango fechas</option>
                          <option value={"TF02"} >Meses</option>
                        </RB.Input>  
                      </RB.FormGroup>
                    </RB.Col>
                    <RB.Col md={3} id="inp_per" className="d-none">
                      <Tooltip title="Consultar Año" placement="top">
                      <Box>
                        <RB.FormGroup>
                          <RB.Label >Año</RB.Label>
                          <RB.Input bsSize="sm" 
                            className="mb-0 text-center" 
                            id="ip_f_ann" 
                            name="ip_f_ann" 
                            type="text"
                            value={ip_f_ann} 
                            onChange={changeIp_f_ann}
                            mask="9999"
                            maskChar="_"
                            tag={InputMask}
                          />
                        </RB.FormGroup>
                        </Box>
                    </Tooltip>
                    </RB.Col>
                    <RB.Col md={3} id="inp_perd" >
                      <Tooltip title="Periodo Desde" placement="top">
                        <Box>
                          <RB.FormGroup>
                            <RB.Label >Periodo Desde</RB.Label>
                            <RB.Input 
                              bsSize="sm" 
                              className="mb-0 text-center" 
                              id="ip_f_did" 
                              name="ip_f_did"
                              type="text"
                              value={ip_f_did}
                              onChange={changeIp_f_did}
                              onKeyDown={onKeyDownFdesde}
                              mask="9999-99-99"
                              maskChar="_"
                              tag={InputMask}
                            />
                            
                          </RB.FormGroup>
                        </Box>
                      </Tooltip>
                    </RB.Col>
                    <RB.Col md={3} id="inp_perh"  >
                      <Tooltip title="Periodo Hasta" placement="top">
                        <Box>
                          <RB.FormGroup>
                          <RB.Label >Periodo Hasta</RB.Label>
                            <RB.Input bsSize="sm" 
                              className="mb-0 text-center" 
                              id="ip_f_dih" 
                              name="ip_f_dih" 
                              type="text" 
                              value={ip_f_dih}  
                              onChange={changeIp_f_dih}  
                              mask="9999-99-99"
                              maskChar="_"
                              tag={InputMask}
                            /> 
                          </RB.FormGroup>
                        </Box>
                      </Tooltip>
                    </RB.Col>
                    <RB.Col md={2}></RB.Col> 
                  </RB.Row>
                </RB.Col>
                <RB.Col lg={4}>
                  <RB.Col md={12}>
                    <Tooltip title="Seleccione el nivel de agrupación" placement="top">
                        <Box>
                          <RB.FormGroup>
                            <RB.Label >Seleccione el nivel de agrupación</RB.Label>
                            <Select  
                              isMulti 
                              options={options} 
                              id="sl_t_agr" 
                              name="sl_t_agr" 
                              placeholder="Seleccione el nivel de agrupación " 
                              className="from-control form-control-sm"  
                              value={sl_t_agr} 
                              onChange={setsl_t_agr}  
                            />
                          </RB.FormGroup>
                        </Box>
                      </Tooltip>
                  </RB.Col>   
                </RB.Col>
              </RB.Row>
              <RB.Row className="mb-3">
                <RB.Col md={10}></RB.Col>
                <RB.Col md={2}>
                  <Tooltip title="Consultar información" placement="top">
                    <Box>
                    <RB.Button size="sm" 
                        block color="success" 
                        id="btn_procesar_excel" 
                        onClick={onClickExcel}  >
                        <RB.Spinner id="btn_excel_spinner" className="me-1 d-none" size="sm">
                          Loading...
                        </RB.Spinner>
                        <FaIcons.FaCloudDownloadAlt id="btn_excel_icon" className="me-1" size={20} /> 
                        Procesar Excel
                    </RB.Button>
                    
                    </Box>
                  </Tooltip>
                </RB.Col>
                
                
              </RB.Row>
              <ToastContainer />
              
            </RB.CardBody>
          </RB.Card>
        </RB.Container>
      </div>
    )
    

}
export default Home;



/**
 * 
 * 

<RB.Col md={2}>
                  <Tooltip title="Consultar información" placement="top">
                    <Box>
                      <RB.Button size="sm" 
                        block color="primary" 
                        id="btn_procesar" 
                        onClick={onClick}  > <FaIcons.FaCloudDownloadAlt className="me-1" size={20} /> Procesar</RB.Button>
                    </Box>
                  </Tooltip>
                </RB.Col>

 <DataTableExtensions {...tableData} >
                <DataTable
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                  progressPending={pending}
                />
              </DataTableExtensions>


<DataTable
                  columns={columns}
                  data={users}
                  progressPending={pending}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="300px"
              />
 * 
 * 
 * 
 * 
<Tooltip title="Periodo Desde" placement="top">
                        <Box>
                          <RB.FormGroup>
                            <RB.Label >Periodo Desde</RB.Label>
                            <RB.Input bsSize="sm" className="mb-0 text-center" id="ip_f_did" name="ip_f_did"   type="text" placeholder="Fecha Desde" value={ip_f_did}  onChange={changeIp_f_did} />
                          </RB.FormGroup>
                        </Box>
                      </Tooltip> 


                            <RB.Input bsSize="sm" className="mb-0 text-center" id="ip_f_dih" name="ip_f_dih" type="text" placeholder="Fecha Hasta" value={ip_f_dih}  onChange={changeIp_f_dih} />












RB.Col md={3} id="inp_perd" >
                      <Tooltip title="Periodo Desde" placement="top">
                        <Box>
                          <RB.FormGroup>
                            <RB.Label >Periodo Desde</RB.Label>
                            <DatePicker className="from-control"
                                selected={ip_f_did} 
                                onChange={date => setip_f_did(date)}  
                                name="startDate"  
                                dateFormat="dd-MM-yyyy"  
                                customInput={
                                  <RB.Input bsSize="sm" className="mb-0 text-center" id="ip_f_did" name="ip_f_did"   type="text" placeholder="Fecha Desde" value={ip_f_dip}  onChange={changeIp_f_dip} />
                                }
                            /> 

                          </RB.FormGroup>
                        </Box>
                      </Tooltip>
                    </RB.Col>
                    <RB.Col md={3} id="inp_perh"  >
                      <Tooltip title="Periodo Hasta" placement="top">
                        <Box>
                          <RB.FormGroup>
                          <RB.Label >Periodo Hasta</RB.Label>
                          <DatePicker className="from-control"
                                selected={ip_f_dih} 
                                onChange={date => setip_f_dih(date)}  
                                name="startDate"  
                                dateFormat="dd-MM-yyyy"  
                                customInput={
                                  <RB.Input bsSize="sm" className="mb-0 text-center" id="ip_f_dih" name="ip_f_dih"   type="text" placeholder="Fecha Hasta"  />
                                }
                            /> 
                          </RB.FormGroup>
                        </Box>
                      </Tooltip>
                    </RB.Col>










                    <InputMask 
                            mask='99-99-9999'  value={ip_f_dih} onChange={setip_f_dih}
                            >
                              <RB.Input bsSize="sm" className="mb-0 text-center" id="ip_f_dih" name="ip_f_dih" type="text" placeholder="Fecha Hasta"  /> 
                          </InputMask>


const columns = [
    { name: 'IDSucursal', selector: row => row.IDSucursal, sortable: true },
    { name: 'Sucursal', selector: row => row.Sucursal, sortable: true },
    { name: 'Estado', selector: row => row.Estado, sortable: true },
    { name: 'Periodo', selector: row => row.Periodo, sortable: true },
    { name: 'FechaEmision', selector: row => row.FechaEmision, sortable: true },
    { name: 'CodCliente', selector: row => row.Cliente, sortable: true },
    { name: 'Cliente', selector: row => row.CodCliente, sortable: true },
  ];
  const tableData = {
    columns,
    users,
  };

 Func para obtener datos en la tabla 
  const onClick = () => {
    if (!sl_t_suc) { notify_warning("Selecionar Sucursal") }
    //if (!sl_t_agr.length) { notify_warning("Selecionar un nivel!") } && sl_t_agr.length

    if (sl_t_suc ) {
      const showData = async () => {
        const response = await fetch(URL,opPe);
        const data = await response.json();
        setUsers(data.recordsets[0])
        //console.log(data.recordsets[0]);
      }
      //const timeout = setTimeout(() => {
        showData()
        setPending(false)
      //},2000);
      //return () => clearTimeout(timeout)
    }
    
    
  }

<RB.Button size="sm" 
                        block color="success" 
                        id="btn_procesar_excel" 
                        onClick={onClickExcel}  > <FaIcons.FaCloudDownloadAlt className="me-1" size={20} /> Procesar Excel</RB.Button>
const fileType = "xlsx"
  const [productData, setProductData] = useState([]);

  const onClickExcel = async () => {
    if (!sl_t_suc) { notify_warning("Selecionar Sucursal") }

    if (sl_t_suc ) {
      const showData = async () => {
        const response = await fetch(URL,opPe);
        const datadtos = await response.json();
        setTimeout(()=> {
          const dataarr = [{
            category:"product",
            data:datadtos.recordsets[0]
          }]

          setProductData(dataarr)
        }, 1000)
        productData.map((item, index)=> {
           console.log("item", item)
           item['json'] = XLSX.utils.json_to_sheet(item.data);
        })
        const obj = {
          Sheets:{},
          SheetNames:[]
        }
        productData.map((item, key)=> {
          return( obj.Sheets[item.category] = item.json,
          obj.SheetNames.push(item.category)
          )
        })
        console.log("obj", obj)
        const test = {...obj}
        const excelBuffer = XLSX.write(test, {bookType:"xlsx", type:"array"});
        const nwdata = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(nwdata, "myfile"+".xlsx")
      }
      showData();        
    }
  }






  const response = await fetch(URL,opPe);
        const dataarr = await response.json();
        
        //setTimeout(()=> {
          setProductData(dataarr.recordsets[0])
        //}, 1000)

        //console.log("productData",productData);
        const ws = XLSX.utils.json_to_sheet(productData);
        const wb = {Sheets:{data:ws}, SheetNames:["lista de masivos de datos "]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"array"});
        const datanew = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(datanew, "myfile"+".xlsx")






const fileType = "xlsx"
  const [productData, setProductData] = useState([]);
  
  const onClickExcel = async () => {
    if (!sl_t_suc) { notify_warning("Selecionar Sucursal") }

    if (sl_t_suc ) {
      const showData = async () => {
        const response = await fetch(URL,opPe);
        const datadtos = await response.json();
        setTimeout(()=> {
          const dataarr = [{
            category:"product",
            data:datadtos.recordsets[0]
          }]
          setProductData(dataarr)
        }, 1000)
        productData.map((item, index)=> {
           console.log("item", item)
           item['json'] = XLSX.utils.json_to_sheet(item.data);
        })
        const obj = {
          Sheets:{},
          SheetNames:[]
        }
        productData.map((item, key)=> {
          return( obj.Sheets[item.category] = item.json,
          obj.SheetNames.push(item.category)
          )
        })
        console.log("obj", obj)
        const test = {...obj}
        const excelBuffer = XLSX.write(test, {bookType:"xlsx", type:"array"});
        const nwdata = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(nwdata, "myfile"+".xlsx")
      }
      showData();        
    }
  }









                        */