/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
//import React, {useEffect} from 'react'
import Cookies from 'universal-cookie';
import {BarChart1,BarChart2,BarChart3} from '../components/Charts/BarChart'
import {TablesCortes,TablesCortes2,TablesCortesHoras,TablesCortesHoras2} from '../components/Tables/TablesCortes'
import { Tooltip }from '@mui/material';
//import $ from 'jquery'
const ContSuper = () => {

  //Guardar cookie ultima vista
  useEffect(() => {
    savePathInCookie();
  }, []);
  function savePathInCookie() {
    const path = window.location.pathname.substr(1); // Obtiene la parte después de la barra diagonal

    // Guarda el valor en una cookie con nombre "ultimapag"
    document.cookie = `ultimapag=${path}`;
  }

  const cookies = new Cookies();
  const dtUser = cookies.get('dtUser');
  const dtUser_tipoUsu = cookies.get('dtUser_tipoUsu');
  const dtUser_nombre = cookies.get('dtUser_nombre');
  //const dtUser_sl_suc = dtUser.sl_dt_suc

  //  useEffect(() => {
  //    setTimeout(() => {
  //      document.getElementById("myTab1").click();
  //      document.getElementById("myTab2").click();
  //    }, 1000);
  //  },[])


  return (
    <div>
      <div className="row">
        <div className="col-lg-12 mb-4 order-0">
          <div className="card">
            <div className="d-flex align-items-end row">
              <div className="col-sm-7">
                <div className="card-body">
                  <h5 className="card-title text-primary">Bienvenid@ {dtUser_tipoUsu} {dtUser_nombre} 🎉</h5>
                  <p className="mb-3">Al nuevo Sistema Web de <span className="fw-bold text-danger">Alvarez Bohl</span>, actualmente se viene trabajando para optimizar procesos.</p>
                  <a href="javascript:;" className="btn btn-sm btn-label-primary d-none">View Badges</a>
                </div>
              </div>
              <div className="col-sm-5 text-center text-sm-left">
                <div className="card-body pb-0 px-0 px-md-4">
                  <img src="assets/img/illustrations/man-with-laptop-light.png" height={140} alt="View Badge User" data-app-dark-img="illustrations/man-with-laptop-dark.png" data-app-light-img="illustrations/man-with-laptop-light.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      { (dtUser.tpu_id === 5 || dtUser.tpu_id === 6) ?
        <div className="row">
          <div className="col-lg-4 mb-4 order-0">
            <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-primary">Proveedores</h5>
                  <BarChart1 />
                </div>
            </div>
          </div>
          <div className="col-lg-4 mb-4 order-0">
            <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-primary">Canales</h5>
                  <BarChart2 />
                </div>
            </div>
          </div>
          <div className="col-lg-4 mb-4 order-0">
            <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-primary">Sub Canales</h5>
                  <BarChart3 />
                </div>
            </div>
          </div>
        </div>
        : ''
      } 
      {/* { (dtUser.tpu_id === 5 || dtUser.tpu_id === 6) ?
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title text-primary">Avance Pedidos</h5>
                <TablesCortes />
              </div>
            </div>
          </div>
        </div>
        : ''
      }  */}
      { (dtUser.tpu_id === 5) ?
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card card-action mb-4">
              <div className="card-header">
                  <h5 className="card-action-title text-primary">Avance Pedidos</h5>
                  <div className="card-action-element">
                      <ul className="list-inline mb-0">
                          <li className="list-inline-item">
                            <a href="javascript:void(0);" id="myTab1" className="card-reload d-none"><i className="tf-icons bx bx-rotate-left scaleX-n1-rtl"></i></a>
                          </li>
                          <li className="list-inline-item">
                            <Tooltip title="Tomar en cuenta esta informacion es actualizada por cada corte: Corte1 => 09:05 am, Corte2 => 11:05 am, Corte3 => 01:05 pm y Cierre => 03:35 pm." placement="top">
                              <i className="tf-icons bx bx-help-circle"></i>
                            </Tooltip>
                          </li>
                      </ul>
                  </div>
              </div>
              <div className="card-body">
                <TablesCortes />
              </div>
              
            </div>
          </div>
          <div className="col-lg-12 mb-4 order-0">
            <div className="card card-action mb-4">
              <div className="card-header">
                  <h5 className="card-action-title text-primary">Pedidos - Bloque Horario</h5>
                  <div className="card-action-element">
                      <ul className="list-inline mb-0">
                          <li className="list-inline-item">
                            <a href="javascript:void(0);" id="myTab2" className="card-reload d-none"><i className="tf-icons bx bx-rotate-left scaleX-n1-rtl"></i></a>
                          </li>
                          <li className="list-inline-item">
                            <Tooltip title="Tomar en cuenta esta informacion es actualizada por hora" placement="top">
                              <i className="tf-icons bx bx-help-circle"></i>
                            </Tooltip>
                          </li>
                      </ul>
                  </div>
              </div>
              <div className="card-body">
                <TablesCortesHoras />
              </div>
            </div>
          </div>
        </div>
        
        : ''
      }
      { (dtUser.tpu_id === 6) ?
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card card-action mb-4">
              <div className="card-header">
                  <h5 className="card-action-title text-primary">Avance Pedidos Todos Los vendedores</h5>
                  <div className="card-action-element">
                      <ul className="list-inline mb-0">
                          <li className="list-inline-item">
                            <Tooltip title="Tomar en cuenta esta informacion es actualizada por cada corte: Corte1 => 09:05 am, Corte2 => 11:05 am, Corte3 => 01:05 pm y Cierre => 03:35 pm." placement="top">
                              <i className="tf-icons bx bx-help-circle"></i>
                            </Tooltip>
                          </li>
                      </ul>
                  </div>
              </div>
              <div className="card-body">
                {
                  dtUser.sl_dt_suc?.map((item,index) => {
                    var Sucursalllegada = item?.suc_nombre;
                    return (<TablesCortes2 key={index} dt_llegada={Sucursalllegada} />)
                  })
                }                
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-4 order-0">
            <div className="card card-action mb-4">
              <div className="card-header">
                  <h5 className="card-action-title text-primary">Pedidos por bloque Horario Todos Los Vendedores</h5>
                  <div className="card-action-element">
                      <ul className="list-inline mb-0">
                          <li className="list-inline-item">
                            <Tooltip title="Tomar en cuenta esta informacion es actualizada por cada corte: Corte1 => 09:05 am, Corte2 => 11:05 am, Corte3 => 01:05 pm y Cierre => 03:35 pm." placement="top">
                              <i className="tf-icons bx bx-help-circle"></i>
                            </Tooltip>
                          </li>
                      </ul>
                  </div>
              </div>
              <div className="card-body">
                {
                  dtUser.sl_dt_suc?.map((item,index) => {
                    var Sucursalllegada = item?.suc_nombre;
                    return (<TablesCortesHoras2 key={index} dt_llegada={Sucursalllegada} />)
                  })
                }                
              </div>
            </div>
          </div>
        </div>
        : ''
      } 
    </div>

  )
}

export default ContSuper