import React from 'react'
//import "../../assets/vendor/libs/bs-stepper/bs-stepper.css";
//import icon1 from '../../assets/svg/icons/form-wizard-account.svg';
//import icon1 from './../assets/svg/icons/form-wizard-account.svg#wizardAccount';

 const Trabajadores = () => {
  return (
    <div>
        <h4 className="fw-bold py-3 mb-4"> Trabajdores </h4>
        <div className="col-xl-12">
            <div className="nav-align-top mb-4">
                <ul className="nav nav-pills mb-3 nav-fill" role="tablist">
                    <li className="nav-item">
                        <button type="button" className="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-justified-home" aria-controls="navs-pills-justified-home" aria-selected="true"><i className="tf-icons bx bx-user" /> Vendedores </button>
                    </li>
                    <li className="nav-item">
                        <button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-justified-profile" aria-controls="navs-pills-justified-profile" aria-selected="false"><i className="tf-icons bx bx-user" /> Supervisores</button>
                    </li>
                    <li className="nav-item">
                        <button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-justified-messages" aria-controls="navs-pills-justified-messages" aria-selected="false"><i className="tf-icons bx bx-user" /> Jefes</button>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane fade show active" id="navs-pills-justified-home" role="tabpanel">
                        <p>
                        Icing pastry pudding oat cake. Lemon drops cotton candy caramels cake caramels sesame snaps powder. Bear
                        claw
                        candy topping.
                        </p>
                        <p className="mb-0">
                        Tootsie roll fruitcake cookie. Dessert topping pie. Jujubes wafer carrot cake jelly. Bonbon jelly-o
                        jelly-o ice
                        cream jelly beans candy canes cake bonbon. Cookie jelly beans marshmallow jujubes sweet.
                        </p>
                    </div>
                    <div className="tab-pane fade" id="navs-pills-justified-profile" role="tabpanel">
                        <p>
                        Donut dragée jelly pie halvah. Danish gingerbread bonbon cookie wafer candy oat cake ice cream. Gummies
                        halvah
                        tootsie roll muffin biscuit icing dessert gingerbread. Pastry ice cream cheesecake fruitcake.
                        </p>
                        <p className="mb-0">
                        Jelly-o jelly beans icing pastry cake cake lemon drops. Muffin muffin pie tiramisu halvah cotton candy
                        liquorice caramels.
                        </p>
                    </div>
                    <div className="tab-pane fade" id="navs-pills-justified-messages" role="tabpanel">
                        <p>
                        Oat cake chupa chups dragée donut toffee. Sweet cotton candy jelly beans macaroon gummies cupcake gummi
                        bears
                        cake chocolate.
                        </p>
                        <p className="mb-0">
                        Cake chocolate bar cotton candy apple pie tootsie roll ice cream apple pie brownie cake. Sweet roll icing
                        sesame snaps caramels danish toffee. Brownie biscuit dessert dessert. Pudding jelly jelly-o tart brownie
                        jelly.
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </div>


  )
}

export default Trabajadores
