/* eslint-disable react/style-prop-object */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import { SelectSucursal,SelectTipo } from '../../components/Selects/SelectsPrincipales'
import Cookies from 'universal-cookie';
import { ToastContainer } from 'react-toastify';
import { notify_warning, notify_valido, selectTabla } from '../../components/utils';
import $ from 'jquery'
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css';
import '../../App.css';
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';
import { ModalsSubPartidas } from '../../components/Modals/ModalsDetVen';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const moment = require('moment')
const cookies = new Cookies();
const styles = {
  control: (provided, state) => ({
      ...provided,
      background: `#fff`,
      borderColor: `#D2D5DC`,
      minHeight: `29px`,
      height: `29px`,
      boxShadow: state.isFocused ? null : null,
      border: '1px solid #D2D5DC',

    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: `29px`,
      padding: `0 6px`,
      color: `#697a8d`,
    }),

    input: (provided, state) => ({
      ...provided,
      color: `#697a8d`,
      fontSize: `0.75rem`,
      
    }),
    indicatorSeparator: state => ({
      display: `none`,
      
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: `29px`,
      
    }),
};
export const MGRMantenimientoVehiculos = () => {

  const veh_url_lstchofer ='/post-data-choferes';
  const [veh_lst, setVeh_lst] = useState([]);
  const [veh_pending, setVeh_pending] = useState(false);
  const [veh_disabled, setVeh_disabled] = useState(false);

  const [veh_sl_t_suc, setVehSl_t_suc] = useState('');
  const [veh_sl_t_cho, setVehSl_t_cho] = useState([""]);
  const [veh_txt_nplaca, setVeh_txt_nplaca] = useState('');
  const [veh_txt_detalle, setVeh_txt_detalle] = useState('');

  const veh_changeSl_t_suc = (event) => { setVehSl_t_suc(event.target.value); }
  const changeVeh_txt_nplaca = (event) => { setVeh_txt_nplaca(event.target.value); }
  const changeVeh_txt_detalle = (event) => { setVeh_txt_detalle(event.target.value); }
 
  const veh_Select2_Chofer = async (inputValue) => {
    //const url_ven = '/post-data-choferes'
    let body = { veh_sl_t_suc: veh_sl_t_suc, inp_text: inputValue };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(veh_url_lstchofer,opPe);
    const data = await response.json();
    console.log('select ',data);
    return data;
  }

  const ins_veh_url = '/gm-veh-insert'
  const lst_veh_url = '/gm-veh-lst'
  const upd_veh_url = '/gm-veh-update'
  const del_veh_url = '/gm-veh-delete'
  const veh_body = { veh_sl_t_suc: veh_sl_t_suc, veh_sl_t_cho: veh_sl_t_cho, veh_txt_nplaca: veh_txt_nplaca, veh_txt_detalle:veh_txt_detalle, usu_id: cookies.get('dtUser_id') };
  const veh_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(veh_body) }

  const lst_veh_body = { sl_t_suc: veh_sl_t_suc };
  const lst_veh_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(lst_veh_body) }
  const lst_veh_columns = [
    { cell:(row) =>
      <div className="align-items-center">
          <button type="button" className="btn btn-sm btn-icon btn-label-danger" onClick={onClickDeleteInputsVehiculos}  id={JSON.stringify(row)}><i id={JSON.stringify(row)} className='bx bx-x-circle bx-ms'></i></button>
      </div>
      ,ignoreRowClick: true, allowOverflow: true, button: true, name: 'OPCIÓN' },
    { name: 'suc_cod',  selector: row => row.suc_cod,           cellExport: row => row.suc_cod,           sortable: true, omit: true },
    { name: 'SUCURSAL', selector: row => row.suc_nombre,        cellExport: row => row.suc_nombre,        sortable: true },
    { name: 'PLACA',    selector: row => row.mun_placa,         cellExport: row => row.mun_placa,         sortable: true },
    { name: 'CODIGO',   selector: row => row.mun_cod_conductor, cellExport: row => row.mun_cod_conductor, sortable: true,omit: true },
    { name: 'CHOFER',   selector: row => row.mun_cod_conductor+' '+row.emp_detalle, cellExport: row => row.mun_cod_conductor+' '+row.emp_detalle, sortable: true, width: "20rem" },
    { name: 'DETALLE',  selector: row => row.mun_detalle,       cellExport: row => row.mun_detalle,       sortable: true, width: "20rem"},
  ];

  const onClickGuardarVehiculos = async() =>{
    $("#veh_btn_guardar").attr("disabled",true)
    $("#icon_veh_btn_guardar").addClass("d-none")
    $("#spinner_veh_btn_guardar").removeClass("d-none")
    if( veh_sl_t_suc === "" || veh_sl_t_cho === "" || veh_txt_nplaca === "" || veh_txt_detalle === ""){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      const response = await fetch(ins_veh_url,veh_opPe);
      const resdatos = await response.json();
      if (resdatos.vl_existe === 1){
        notify_warning("CODIGO YA EXISTE!!!")
      }
      if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
        notify_valido("CODIGO SE REGISTRO CORRECTAMENTE!!!")
        onClickClearInputsVehiculos();
        if($(".rdt_Table").hasClass("table")){
          onClickListarVehiculos();
        }
      }      
    }
    $("#veh_btn_guardar").attr("disabled",false)
    $("#icon_veh_btn_guardar").removeClass("d-none")
    $("#spinner_veh_btn_guardar").addClass("d-none")
  }
  const onClickListarVehiculos = async() =>{
    const columns = lst_veh_columns
    setVeh_pending(true)
    const response = await fetch(lst_veh_url,lst_veh_opPe);
    const data = await response.json();
    //console.log(data);
    const tableData = { columns, data };
    setVeh_lst(tableData)
    setVeh_pending(false)
    $(".veh_tbl_lista .rdt_Table").addClass("table");
  }
  // const selectTablaVeh = () => {
  //   $('.rdt_TableBody').on('click', '.rdt_TableCell', function () {
  //     $('.rdt_TableRow').removeClass("table-danger");
  //     $($(this).parents(".rdt_TableRow")).addClass("table-danger");       
  //   })
  // };
  const handleRowClicked = (row) => {
    setVehSl_t_suc(row.suc_cod)
    setVehSl_t_cho({emp_cod: row.mun_cod_conductor, emp_detalle: row.mun_cod_conductor+' '+row.emp_detalle})
    setVeh_txt_nplaca(row.mun_placa)
    setVeh_txt_detalle(row.mun_detalle)
    selectTabla("veh_tbl_lista")
    $("#veh_btn_guardar").addClass('d-none')
    $("#veh_btn_editar").removeClass('d-none')
    setVeh_disabled(true)
    $("#veh_btn_gmodificado").addClass('d-none')
    $("#veh_btn_mlista").addClass('d-none')


  };
  const onClickClearInputsVehiculos = (row) => {
   
    //setVehSl_t_suc("")
    setVehSl_t_cho("")
    setVeh_txt_nplaca("")
    setVeh_txt_detalle("")
    $('.rdt_TableRow').removeClass("table-danger");
    $("#veh_btn_guardar").removeClass('d-none')
    $("#veh_btn_editar").addClass('d-none')
    $("#veh_btn_gmodificado").addClass('d-none')
    setVeh_disabled(false)
    $("#veh_txt_cod").attr('disabled',false)
    $("#veh_btn_mlista").removeClass('d-none');
    $("#veh_txt_nplaca").attr('disabled',false) 
    $("#veh_slc_suc").attr('disabled',false) 

  };
  const onClickEditInputsVehiculos = (row) => {
    setVeh_disabled(false)
    $("#veh_btn_editar").addClass('d-none')
    $("#veh_btn_gmodificado").removeClass('d-none')
    setTimeout(()=>{ 
      $("#veh_txt_nplaca").attr('disabled',true) 
      $("#veh_slc_suc").attr('disabled',true) 
  },500)
  };
  const onClickGModifcadoVehiculos = async() =>{
    $("#veh_btn_gmodificado").attr("disabled",true)
    $("#icon_veh_btn_gmodificado").addClass("d-none")
    $("#spinner_veh_btn_gmodificado").removeClass("d-none")
    if( veh_sl_t_suc === "" || veh_sl_t_cho === "" || veh_txt_nplaca === "" || veh_txt_detalle === ""){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      console.log(veh_body);
      const response = await fetch(upd_veh_url,veh_opPe);
      const resdatos = await response.json();
      console.log("modifi", resdatos);
      if (resdatos.vl_insert === 0){
        notify_warning("ERROR AL MODIFCAR, VERIFICAR DATOS!!!")
      }else if (resdatos.vl_insert === 1 ){
        onClickClearInputsVehiculos()
        onClickListarVehiculos()
        notify_valido("SE MODIFICO CORRECTAMENTE!!!")
      }
      
    }
    $("#veh_btn_gmodificado").attr("disabled",false)
    $("#icon_veh_btn_gmodificado").removeClass("d-none")
    $("#spinner_veh_btn_gmodificado").addClass("d-none")
  }
  const onClickDeleteInputsVehiculos = async(state) => {
    
    try {
      const dtLlegada = await JSON.parse(state.target.id);
      console.log(dtLlegada);
      const codigo = dtLlegada.mun_placa;
      const del_veh_body = { txt_cod: codigo, usu_id: cookies.get('dtUser_id') };
      const del_veh_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(del_veh_body) }
  
      Swal.fire({
        title: 'Advertencia',
        text: '¿Esta seguro de eliminar este registro?',
        icon: 'error',
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si",
        confirmButtonColor: "#696cff",
        
      }).then(async response =>  {
        if (response.isConfirmed) {
          const respondt = await fetch(del_veh_url,del_veh_opPe);
          const resdatos = await respondt.json();
          console.log("resdatos",resdatos);
          if (resdatos.vl_insert === 0){
            notify_warning("ERROR AL ELIMINAR!!!")
          }else if (resdatos.vl_insert === 1 ){
            notify_valido("USUARIO SE ELIMINO CORRECTAMENTE!!!")
            Swal.fire({title:'EXITO', text:'USUARIO SE ELIMINO CORRECTAMENTE!!!', icon:'success',showConfirmButton:false, timer: 1000})
            onClickListarVehiculos()
            onClickClearInputsVehiculos()
          }
        }else if(response.isDenied){
          Swal.close();
          //Swal.fire('Información', 'No paso nada', 'error')
        }
      })
      
  
  
  
  
    } catch (error) {
      console.log(error);;
    }
  }

  return(
    <>
      <div className="row">
        <div className="col-lg-1">Sucursal:</div>
        <div className="col-lg-2">
          <SelectSucursal id="veh_slc_suc" value={veh_sl_t_suc} onChange={veh_changeSl_t_suc} disabled={veh_disabled} />
        </div>
        <div className="col-lg-1">N.Placa:</div>
        <div className="col-lg-2">
          <input type="text" id="veh_txt_nplaca" name="veh_txt_nplaca" className="form-control form-control-sm"  autoComplete='off' value={veh_txt_nplaca} onChange={changeVeh_txt_nplaca} disabled={veh_disabled} />
        </div>
        <div className="col-lg-1">Chofer:</div>
        <div className="col-lg-5">
          <AsyncSelect
            id="veh_sl2_chofer"
            styles={styles}
            placeholder="Busque cliente por codigo o descripción"
            name="form-field-name"
            className="sele_pru from-control form-control-sm"  
            value={veh_sl_t_cho}
            cacheOptions
            defaultOptions={false}
            getOptionLabel={e => e.emp_detalle}
            getOptionValue={e => e.emp_cod}
            loadOptions={veh_Select2_Chofer}
            onChange={setVehSl_t_cho}
            isClearable={true}
            openMenuOnClick={false}
            isDisabled={veh_disabled}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-lg-1">Detalle:</div>
        <div className="col-lg-11">
          <input type="text" id="veh_txt_detalle" name="veh_txt_detalle" className="form-control form-control-sm"  autoComplete='off' value={veh_txt_detalle} onChange={changeVeh_txt_detalle} disabled={veh_disabled} />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-4 "></div>
        <div className="col-md-4 text-center ">
          <button className="btn btn-primary btn-sm" id="veh_btn_guardar" onClick={onClickGuardarVehiculos} >
            <i className='bx bx-save' id="icon_veh_btn_guardar" ></i>
            <span className="spinner-border d-none" id="spinner_veh_btn_guardar" role="status" aria-hidden="true"></span>
            &nbsp;Guardar
          </button>
          <button className="btn btn-warning btn-sm d-none" id="veh_btn_editar" onClick={onClickEditInputsVehiculos}>
            <i className='bx bx-edit' ></i>&nbsp;Editar
          </button>
          <button className="btn btn-success btn-sm d-none" id="veh_btn_gmodificado" onClick={onClickGModifcadoVehiculos} >
            <i className='bx bx-send' id="icon_veh_btn_gmodificado" ></i>
            <span className="spinner-border d-none" id="spinner_veh_btn_gmodificado" role="status" aria-hidden="true"></span>
            &nbsp;GModificado
          </button>
          <button className="btn btn-dark btn-sm" id="veh_btn_cancelar" onClick={onClickClearInputsVehiculos}>
            <i className='bx bx-history' ></i>&nbsp;Cancelar
          </button>
          <button className="btn btn-info btn-sm" id="veh_btn_mlista" onClick={onClickListarVehiculos} >
            <i className='bx bx-list-ul' ></i>&nbsp;MLista
          </button>
        </div>
      </div>
      <div className="card-datatable table-responsive ">
        <DataTableExtensions  {...veh_lst} >
          <DataTable className="veh_tbl_lista"
              Header
              defaultSortField="id"
              defaultSortAsc={true}
              pagination
              responsive
              highlightOnHover
              progressPending={veh_pending}
              noContextMenu="No hay registros para mostrar"
              onRowClicked={handleRowClicked}
              //conditionalRowStyles={conditionalRowStyles}
          />
        </DataTableExtensions>
      </div>
    </>
  )
}
export const MGRMantenimientoEmpleados = () => {
  /** INICIO - EMPLEADOS */
  const emp_url_temp ='/get-data-cotablas?txt_tipo=TE';
  const emp_url_tdoc ='/get-data-cotablas?txt_tipo=TD';

  const [emp_lst, setEmp_lst] = useState([]);
  const [emp_pending, setEmp_pending] = useState(false);
  const [emp_disabled, setEmp_disabled] = useState(false);
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState('');
  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }
  const [emp_sl_t_emp, emp_setSl_t_emp] = useState('');
  const emp_changeSl_t_emp = (event) => { 
    emp_setSl_t_emp(event.target.value);
    $("#emp_slc_tdo").focus();
  }
  const [emp_sl_t_doc, emp_setSl_t_doc] = useState('');
  const emp_changeSl_t_doc = (event) => { 
    emp_setSl_t_doc(event.target.value);
    $("#emp_txt_cod").select();
  }
  const [emp_txt_cod, emp_setEmp_txt_codt] = useState('');
  const emp_changeEmp_txt_codt = (event) => { 
    emp_setEmp_txt_codt(event.target.value);
  }
  const [emp_txt_detalle, emp_setEmp_txt_detalle] = useState('');
  const emp_changeEmp_txt_detalle = (event) => { emp_setEmp_txt_detalle(event.target.value); }
  const [emp_txt_observacion, emp_setEmp_txt_observacion] = useState('');
  const emp_changeEmp_txt_observacion = (event) => { emp_setEmp_txt_observacion(event.target.value); }

  const emp_url = '/gm-emp-insert'
  const lst_emp_url = '/gm-emp-lst'
  const upd_emp_url = '/gm-emp-update'
  const del_emp_url = '/gm-emp-delete'
  const emp_body = { emp_sl_t_suc: emp_sl_t_suc, emp_sl_t_emp: emp_sl_t_emp, emp_sl_t_doc: emp_sl_t_doc, emp_txt_cod:emp_txt_cod, emp_txt_detalle: emp_txt_detalle, emp_txt_observacion: emp_txt_observacion, usu_id: cookies.get('dtUser_id') };
  const emp_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(emp_body) }

  const lst_emp_body = { sl_t_suc: emp_sl_t_suc };
  const lst_emp_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(lst_emp_body) }
  const lst_emp_columns = [
    { cell:(row) =>
      <div className="align-items-center">
          <button type="button" className="btn btn-sm btn-icon btn-label-danger" onClick={onClickDeleteInputsEmpleados}  id={JSON.stringify(row)}><i id={JSON.stringify(row)} className='bx bx-x-circle bx-ms'></i></button>
      </div>
      ,ignoreRowClick: true, allowOverflow: true, button: true, name: 'OPCIÓN' },
    { name: 'suc_cod',     selector: row => row.suc_cod,       cellExport: row => row.suc_cod,       sortable: true, omit: true },
    { name: 'SUCURSAL',     selector: row => row.suc_nombre,       cellExport: row => row.suc_nombre,       sortable: true },
    { name: 'cot_cod_tem',     selector: row => row.cot_cod_tem,       cellExport: row => row.cot_cod_tem,       sortable: true, omit: true },
    { name: 'T.EMPLEADO',     selector: row => row.cot_descri1,       cellExport: row => row.cot_descri1,       sortable: true },
    { name: 'cot_cod_tdo',     selector: row => row.cot_cod_tdo,       cellExport: row => row.cot_cod_tdo,       sortable: true, omit: true },
    { name: 'T.DOCUMENTO',     selector: row => row.cot_descri2,       cellExport: row => row.cot_descri2,       sortable: true },
    { name: 'N.DOCUMENTO',         selector: row => row.emp_cod,           cellExport: row => row.emp_cod,       sortable: true },
    { name: 'DETALLE',     selector: row => row.emp_detalle,       cellExport: row => row.emp_detalle,       sortable: true, width: "20rem"},
    { name: 'OBSERVACIÓN', selector: row => row.emp_observacion,   cellExport: row => row.emp_observacion,       sortable: true, width: "20rem" },
  ];

  const onClickGuardarEmpleados = async() =>{
    $("#emp_btn_guardar").attr("disabled",true)
    $("#icon_emp_btn_guardar").addClass("d-none")
    $("#spinner_emp_btn_guardar").removeClass("d-none")
    if( emp_sl_t_suc === "" || emp_sl_t_emp === "" || emp_sl_t_doc === "" || emp_txt_cod === "" || emp_txt_detalle === ""){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      const response = await fetch(emp_url,emp_opPe);
      const resdatos = await response.json();
      if (resdatos.vl_existe === 1){
        notify_warning("CODIGO DE USUARIO YA EXISTE!!!")
      }
      if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
        notify_valido("CODIGO DE USUARIO SE REGISTRO CORRECTAMENTE!!!")
        onClickClearInputsEmpleados();
        if($(".rdt_Table").hasClass("table")){
          onClickListarEmpleados();
        }
      }
      //console.log("resdatos",resdatos);
      
    }
    $("#emp_btn_guardar").attr("disabled",false)
    $("#icon_emp_btn_guardar").removeClass("d-none")
    $("#spinner_emp_btn_guardar").addClass("d-none")
  }
  const onClickListarEmpleados = async() =>{
    const columns = lst_emp_columns
    setEmp_pending(true)
    const response = await fetch(lst_emp_url,lst_emp_opPe);
    const data = await response.json();
    //console.log(data);
    const tableData = { columns, data };
    setEmp_lst(tableData)
    setEmp_pending(false)
    $(".emp_tbl_lista .rdt_Table").addClass("table");

  }
  // const selectTablaEmp = () => {
  //   $('.rdt_TableBody').on('click', '.rdt_TableCell', function () {
  //     $('.rdt_TableRow').removeClass("table-danger");
  //     $($(this).parents(".rdt_TableRow")).addClass("table-danger");       
  //   })
  // };
  
  const handleRowClicked = (row) => {
    emp_setSl_t_suc(row.suc_cod)
    emp_setSl_t_emp(row.cot_cod_tem)
    emp_setSl_t_doc(row.cot_cod_tdo)
    emp_setEmp_txt_codt(row.emp_cod)
    emp_setEmp_txt_detalle(row.emp_detalle)
    emp_setEmp_txt_observacion(row.emp_observacion)
    selectTabla("emp_tbl_lista")
    $("#emp_btn_guardar").addClass('d-none')
    $("#emp_btn_editar").removeClass('d-none')
    setEmp_disabled(true)
    $("#emp_btn_gmodificado").addClass('d-none')
    $("#emp_btn_mlista").addClass('d-none')
  };
  const onClickClearInputsEmpleados = (row) => {
    //emp_setSl_t_suc("")
    emp_setSl_t_emp("")
    emp_setSl_t_doc("")
    emp_setEmp_txt_codt("")
    emp_setEmp_txt_detalle("")
    emp_setEmp_txt_observacion("")
    $('.rdt_TableRow').removeClass("table-danger");
    $("#emp_btn_guardar").removeClass('d-none')
    $("#emp_btn_editar").addClass('d-none')
    $("#emp_btn_gmodificado").addClass('d-none')
    setEmp_disabled(false)
    $("#emp_txt_cod").attr('disabled',false)
    $("#emp_btn_mlista").removeClass('d-none')

  };
  const onClickEditInputsEmpleados = (row) => {
    setEmp_disabled(false)
    $("#emp_btn_editar").addClass('d-none')
    $("#emp_btn_gmodificado").removeClass('d-none')
    setTimeout(()=>{ $("#emp_txt_cod").attr('disabled',true) },500)
  };
  const onClickGModifcadoEmpleados = async() =>{
    $("#emp_btn_gmodificado").attr("disabled",true)
    $("#icon_emp_btn_gmodificado").addClass("d-none")
    $("#spinner_emp_btn_gmodificado").removeClass("d-none")
    if( emp_sl_t_suc === "" || emp_sl_t_emp === "" || emp_sl_t_doc === "" || emp_txt_cod === "" || emp_txt_detalle === ""){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      const response = await fetch(upd_emp_url,emp_opPe);
      const resdatos = await response.json();
      //console.log("modifi", resdatos);
      if (resdatos.vl_insert === 0){
        notify_warning("ERROR AL MODIFCAR, VERIFICAR DATOS!!!")
      }else if (resdatos.vl_insert === 1 ){
        onClickClearInputsEmpleados()
        onClickListarEmpleados()
        notify_valido("SE MODIFICO CORRECTAMENTE!!!")
      }
      //console.log("resdatos",resdatos);
      
    }
    $("#emp_btn_gmodificado").attr("disabled",false)
    $("#icon_emp_btn_gmodificado").removeClass("d-none")
    $("#spinner_emp_btn_gmodificado").addClass("d-none")
  }
  const onClickDeleteInputsEmpleados = async(state) => {
    
    try {
      const dtLlegada = await JSON.parse(state.target.id);
      const cod_emple = dtLlegada.emp_cod
      const del_emp_body = { emp_txt_cod: cod_emple, usu_id: cookies.get('dtUser_id') };
      const del_emp_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(del_emp_body) }

      Swal.fire({
        title: 'Advertencia',
        text: '¿Esta seguro de eliminar este registro?',
        icon: 'error',
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si",
        confirmButtonColor: "#696cff",
        
      }).then(async response =>  {
        if (response.isConfirmed) {
          const respondt = await fetch(del_emp_url,del_emp_opPe);
          const resdatos = await respondt.json();
          console.log("resdatos",resdatos);
          if (resdatos.vl_insert === 0){
            notify_warning("ERROR AL ELIMINAR!!!")
          }else if (resdatos.vl_insert === 1 ){
            notify_valido("USUARIO SE ELIMINO CORRECTAMENTE!!!")
            Swal.fire({title:'EXITO', text:'USUARIO SE ELIMINO CORRECTAMENTE!!!', icon:'success',showConfirmButton:false, timer: 1000})
            onClickListarEmpleados()
            onClickClearInputsEmpleados()
          }
        }else if(response.isDenied){
          Swal.close();
          //Swal.fire('Información', 'No paso nada', 'error')
        }
      })
      
    } catch (error) {
      console.log(error);;
    }
  }
  const onFocusEmpleados = async() => {
    $("#emp_txt_cod").on("keypress",function (e) {
      var code = (e.keyCode ? e.keyCode : e.which); 
      if(code === 13){
        $("#emp_txt_detalle").select()
      }
    })
    $("#emp_txt_detalle").on("keypress",function (e) {
      var code = (e.keyCode ? e.keyCode : e.which); 
      if(code === 13){
        $("#emp_txt_observacion").select()
      }
    })
  }

  

  useEffect(() => {
    onFocusEmpleados()
  },[])
  
  /** FIN - EMPLEADOS */

  return (
    <>
    <div className="row">
      <div className="col-lg-2">Sucursal:</div>
      <div className="col-lg-2">
        <SelectSucursal id="emp_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} disabled={emp_disabled} />
      </div>
      <div className="col-lg-2">T.Empleado:</div>
      <div className="col-lg-2">
        <SelectTipo id="emp_slc_tem" url={emp_url_temp} value={emp_sl_t_emp} onChange={emp_changeSl_t_emp} disabled={emp_disabled} />
      </div>
      <div className="col-lg-2">T.Documento:</div>
      <div className="col-lg-2">
        <SelectTipo id="emp_slc_tdo" url={emp_url_tdoc} value={emp_sl_t_doc} onChange={emp_changeSl_t_doc} disabled={emp_disabled} />
      </div>
    </div>
    <div className="row">
      <div className="col-lg-2">Codigo:</div>
      <div className="col-lg-2">
        <input type="number" id="emp_txt_cod" name="emp_txt_cod" className="form-control form-control-sm" autoComplete='off' value={emp_txt_cod} onChange={emp_changeEmp_txt_codt} disabled={emp_disabled} autoFocus />
      </div>
      <div className="col-lg-2">Detalle:</div>
      <div className="col-lg-6">
        <input type="text" id="emp_txt_detalle" name="emp_txt_detalle" className="form-control form-control-sm" autoComplete='off' value={emp_txt_detalle} onChange={emp_changeEmp_txt_detalle} disabled={emp_disabled} />
      </div>
    </div>
    <div className="row mb-2">
      <div className="col-lg-2">Observación:</div>
      <div className="col-lg-10">
        <input type="text" id="emp_txt_observacion" name="emp_txt_observacion" className="form-control form-control-sm" autoComplete='off' value={emp_txt_observacion} onChange={emp_changeEmp_txt_observacion} disabled={emp_disabled}/>
      </div>
    </div>
    <div className="row ">
      <div className="col-md-4 "></div>
      <div className="col-md-4 text-center ">
        <button className="btn btn-primary btn-sm" id="emp_btn_guardar" onClick={onClickGuardarEmpleados} >
          <i className='bx bx-save' id="icon_emp_btn_guardar" ></i>
          <span className="spinner-border d-none" id="spinner_emp_btn_guardar" role="status" aria-hidden="true"></span>
          &nbsp;Guardar
        </button>
        <button className="btn btn-warning btn-sm d-none" id="emp_btn_editar" onClick={onClickEditInputsEmpleados}>
          <i className='bx bx-edit' ></i>&nbsp;Editar
        </button>
        <button className="btn btn-success btn-sm d-none" id="emp_btn_gmodificado" onClick={onClickGModifcadoEmpleados} >
          <i className='bx bx-send' id="icon_emp_btn_gmodificado" ></i>
          <span className="spinner-border d-none" id="spinner_emp_btn_gmodificado" role="status" aria-hidden="true"></span>
          &nbsp;GModificado
        </button>
        <button className="btn btn-dark btn-sm" id="emp_btn_cancelar" onClick={onClickClearInputsEmpleados}>
          <i className='bx bx-history' ></i>&nbsp;Cancelar
        </button>
        <button className="btn btn-info btn-sm" id="emp_btn_mlista" onClick={onClickListarEmpleados} >
          <i className='bx bx-list-ul' ></i>&nbsp;MLista
        </button>
      </div>
    </div>
    <div className="card-datatable table-responsive ">
      <DataTableExtensions  {...emp_lst} >
        <DataTable className="emp_tbl_lista"
            Header
            defaultSortField="id"
            defaultSortAsc={true}
            pagination
            responsive
            highlightOnHover
            progressPending={emp_pending}
            noContextMenu="No hay registros para mostrar"
            onRowClicked={handleRowClicked}
            //conditionalRowStyles={conditionalRowStyles}
        />
      </DataTableExtensions>
    </div>
    </>
  )

}
export const MGRMantenimientoConceptosPartidas = () => {

  const [lst, setlst] = useState([]);
  const [pending, setpending] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const [txt_codigo, setTxt_codigo] = useState('');
  const [txt_detalle, setTxt_detalle] = useState('');

  const changetxt_codigo = (event) => { setTxt_codigo(event.target.value); }
  const changetxt_detalle = (event) => { setTxt_detalle(event.target.value); }
  const ins_url = '/gm-cts-p-insert'
  const lst_url = '/gm-cts-p-lst'
  const upd_url = '/gm-cts-p-update'
  const del_url = '/gm-cts-p-delete'
  const body = { txt_codigo: txt_codigo, txt_detalle: txt_detalle, txt_usuid: cookies.get('dtUser_id') };
  const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  const lst_columns = [
    { cell:(row) =>
      <div className="align-items-center">
          <button type="button" className="btn btn-sm btn-icon btn-label-danger" onClick={onClickDelete}  id={JSON.stringify(row)}><i id={JSON.stringify(row)} className='bx bx-x-circle bx-ms'></i></button>
      </div>
      ,ignoreRowClick: true, allowOverflow: true, button: true, name: 'OPCIÓN' },
    { name: 'CODIGO',  selector: row => row.par_cod,           cellExport: row => row.par_cod,           sortable: true },
    { name: 'DETALLE', selector: row => row.par_descripcion,   cellExport: row => row.par_descripcion,        sortable: true },
    { cell:(row) =>
      <div className="align-items-center">
          <button type="button" className="btn btn-sm btn-icon btn-label-info" onClick={onClicModalSubPartidas} id={JSON.stringify(row)} data-bs-toggle="modal" data-bs-target="#modalCenter" ><i id={JSON.stringify(row)} className='bx bx-plus-medical bx-ms'></i></button>
      </div>
      ,ignoreRowClick: true, allowOverflow: true, button: true, name: 'OPCIÓN' },
    
  ];
  const onClickGuardar = async() => {
    $("#btn_guardar").attr("disabled",true)
    $("#icon_btn_guardar").addClass("d-none")
    $("#spinner_btn_guardar").removeClass("d-none")
    if( txt_codigo === "" || txt_detalle === "" ){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      const response = await fetch(ins_url,opPe);
      const resdatos = await response.json();
      if (resdatos.vl_existe === 1){
        notify_warning("CODIGO YA EXISTE!!!")
      }
      if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
        notify_valido("CODIGO SE REGISTRO CORRECTAMENTE!!!")
        onClickClearInputs();
        if($(".rdt_Table").hasClass("table")){
          onClickListar();
        }
      }      
    }
    $("#btn_guardar").attr("disabled",false)
    $("#icon_btn_guardar").removeClass("d-none")
    $("#spinner_btn_guardar").addClass("d-none")
  }
  const onClickListar = async() => {
    const columns = lst_columns
    setpending(true)
    const response = await fetch(lst_url,opPe);
    const data = await response.json();
    //console.log(data);
    const tableData = { columns, data };
    setlst(tableData)
    setpending(false)
    $(".cpa_tbl_lista .rdt_Table").addClass("table");
  }
  const onClickEditInputs = async() => {
    setdisabled(false)
    $("#btn_editar").addClass('d-none')
    $("#btn_gmodificado").removeClass('d-none')
    setTimeout(()=>{ $("#txt_codigo").attr('disabled',true) },500)
  }
  const onClickGModifcado = async() => {
    $("#btn_gmodificado").attr("disabled",true)
    $("#icon_btn_gmodificado").addClass("d-none")
    $("#spinner_btn_gmodificado").removeClass("d-none")
    if( txt_codigo === "" || txt_detalle === "" ){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      const response = await fetch(upd_url,opPe);
      const resdatos = await response.json();
      //console.log("modifi", resdatos);
      if (resdatos.vl_insert === 0){
        notify_warning("ERROR AL MODIFCAR, VERIFICAR DATOS!!!")
      }else if (resdatos.vl_insert === 1 ){
        onClickClearInputs()
        onClickListar()
        notify_valido("SE MODIFICO CORRECTAMENTE!!!")
      }
    }
    $("#btn_gmodificado").attr("disabled",false)
    $("#icon_btn_gmodificado").removeClass("d-none")
    $("#spinner_btn_gmodificado").addClass("d-none")
  }
  const onClickClearInputs = async() => {
    setTxt_codigo("")
    setTxt_detalle("")
    $('.rdt_TableRow').removeClass("table-danger");
    $("#btn_guardar").removeClass('d-none')
    $("#btn_editar").addClass('d-none')
    $("#btn_gmodificado").addClass('d-none')
    setdisabled(false)
    $("#txt_codigo").attr('disabled',false)
    $("#btn_mlista").removeClass('d-none')
  }
  const onClickDelete = async(state) => {
    try {
      const dtLlegada = await JSON.parse(state.target.id);
      const codigo = dtLlegada.par_cod
      const del_body = { txt_codigo: codigo, txt_usuid: cookies.get('dtUser_id') };
      const del_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(del_body) }
    
      Swal.fire({
        title: 'Advertencia',
        text: '¿Esta seguro de eliminar este registro?',
        icon: 'error',
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si",
        confirmButtonColor: "#696cff",
        
      }).then(async response =>  {
        if (response.isConfirmed) {
          const respondt = await fetch(del_url,del_opPe);
          const resdatos = await respondt.json();
          console.log("resdatos",resdatos);
          if (resdatos.vl_insert === 0){
            notify_warning("ERROR AL ELIMINAR!!!")
          }else if (resdatos.vl_insert === 1 ){
            notify_valido("USUARIO SE ELIMINO CORRECTAMENTE!!!")
            Swal.fire({title:'EXITO', text:'USUARIO SE ELIMINO CORRECTAMENTE!!!', icon:'success',showConfirmButton:false, timer: 1000})
            onClickListar()
            onClickClearInputs()
          }
        }else if(response.isDenied){
          Swal.close();
        }
      })
      
    } catch (error) {
      console.log(error);
    }
  }
  const onClicModalSubPartidas = async(state) => {
      const dtLlegada = await JSON.parse(state.target.id);
      $(".modal_cod_partida").text(dtLlegada.par_cod) 
      $(".modal_des_partida").text(dtLlegada.par_descripcion) 
  }
   
  const handleRowClicked = (row) => {
    setTxt_codigo(row.par_cod)
    setTxt_detalle(row.par_descripcion)
    selectTabla("cpa_tbl_lista")
    $("#btn_guardar").addClass('d-none')
    $("#btn_editar").removeClass('d-none')
    setdisabled(true)
    $("#btn_gmodificado").addClass('d-none')
    $("#btn_mlista").addClass('d-none')
  }
  // const selectTabla = () => {
  //   $('.rdt_TableBody').on('click', '.rdt_TableCell', function () {
  //     $('.rdt_TableRow').removeClass("table-danger");
  //     $($(this).parents(".rdt_TableRow")).addClass("table-danger");       
  //   })
  // };

  return(
    <>
    <div className="row mb-2">
      <div className="col-lg-2">Codigo:</div>
      <div className="col-lg-2">
        <input type="text" id="txt_codigo" name="txt_codigo" className="form-control form-control-sm"  autoComplete='off' value={txt_codigo} onChange={changetxt_codigo} disabled={disabled}/>
      </div>
      <div className="col-lg-2">Descripción:</div>
      <div className="col-lg-6">
        <input type="text" id="txt_detalle" name="txt_detalle" className="form-control form-control-sm"  autoComplete='off' value={txt_detalle} onChange={changetxt_detalle} disabled={disabled}/>
      </div>
    </div>
    <div className="row ">
      <div className="col-md-4 "></div>
      <div className="col-md-4 text-center ">
        <button className="btn btn-primary btn-sm" id="btn_guardar" onClick={onClickGuardar} >
          <i className='bx bx-save' id="icon_btn_guardar" ></i>
          <span className="spinner-border d-none" id="spinner_btn_guardar" role="status" aria-hidden="true"></span>
          &nbsp;Guardar
        </button>
        <button className="btn btn-warning btn-sm d-none" id="btn_editar" onClick={onClickEditInputs}>
          <i className='bx bx-edit' ></i>&nbsp;Editar
        </button>
        <button className="btn btn-success btn-sm d-none" id="btn_gmodificado" onClick={onClickGModifcado} >
          <i className='bx bx-send' id="icon_btn_gmodificado" ></i>
          <span className="spinner-border d-none" id="spinner_btn_gmodificado" role="status" aria-hidden="true"></span>
          &nbsp;GModificado
        </button>
        <button className="btn btn-dark btn-sm" id="btn_cancelar" onClick={onClickClearInputs}>
          <i className='bx bx-history' ></i>&nbsp;Cancelar
        </button>
        <button className="btn btn-info btn-sm" id="btn_mlista" onClick={onClickListar} >
          <i className='bx bx-list-ul' ></i>&nbsp;MLista
        </button>
      </div>
    </div>
    <div className="card-datatable table-responsive ">
      <DataTableExtensions  {...lst} >
        <DataTable className="cpa_tbl_lista"
          Header
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          responsive
          highlightOnHover
          progressPending={pending}
          noContextMenu="No hay registros para mostrar"
          onRowClicked={handleRowClicked}
          //conditionalRowStyles={conditionalRowStyles}
        />
      </DataTableExtensions>
    </div>
    
    </>
  )
}
export const MGRMantenimientoConceptosJornadas = () => {

  const [lst, setlst] = useState([]);
  const [pending, setpending] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const [txt_codigo, setTxt_codigo] = useState('');
  const [txt_detalle, setTxt_detalle] = useState('');

  const changetxt_codigo = (event) => { setTxt_codigo(event.target.value); }
  const changetxt_detalle = (event) => { setTxt_detalle(event.target.value); }
  const ins_url = '/gm-cts-j-insert'
  const lst_url = '/gm-cts-j-lst'
  const upd_url = '/gm-cts-j-update'
  const del_url = '/gm-cts-j-delete'
  const body = { txt_codigo: txt_codigo, txt_detalle: txt_detalle, txt_usuid: cookies.get('dtUser_id') };
  const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  const lst_columns = [
    { cell:(row) =>
      <div className="align-items-center">
          <button type="button" className="btn btn-sm btn-icon btn-label-danger" onClick={onClickDelete}  id={JSON.stringify(row)}><i id={JSON.stringify(row)} className='bx bx-x-circle bx-ms'></i></button>
      </div>
      ,ignoreRowClick: true, allowOverflow: true, button: true, name: 'OPCIÓN' },
    { name: 'CODIGO',  selector: row => row.tjo_cod,           cellExport: row => row.tjo_cod,           sortable: true },
    { name: 'DETALLE', selector: row => row.tjo_descripcion,   cellExport: row => row.tjo_descripcion,        sortable: true },
  ];
  const onClickGuardar = async() => {
    $("#btn_j_guardar").attr("disabled",true)
    $("#icon_btn_j_guardar").addClass("d-none")
    $("#spinner_btn_j_guardar").removeClass("d-none")
    if( txt_codigo === "" || txt_detalle === "" ){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      const response = await fetch(ins_url,opPe);
      const resdatos = await response.json();
      if (resdatos.vl_existe === 1){
        notify_warning("CODIGO YA EXISTE!!!")
      }
      if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
        notify_valido("CODIGO SE REGISTRO CORRECTAMENTE!!!")
        onClickClearInputs();
        if($(".rdt_Table").hasClass("table")){
          onClickListar();
        }
      }      
    }
    $("#btn_j_guardar").attr("disabled",false)
    $("#icon_btn_j_guardar").removeClass("d-none")
    $("#spinner_btn_j_guardar").addClass("d-none")
  }
  const onClickListar = async() => {
    const columns = lst_columns
    setpending(true)
    const response = await fetch(lst_url,opPe);
    const data = await response.json();
    //console.log(data);
    const tableData = { columns, data };
    setlst(tableData)
    setpending(false)
    $(".cjo_tbl_lista .rdt_Table").addClass("table");
  }
  const onClickEditInputs = async() => {
    setdisabled(false)
    $("#btn_j_editar").addClass('d-none')
    $("#btn_j_gmodificado").removeClass('d-none')
    setTimeout(()=>{ $("#txt_j_codigo").attr('disabled',true) },500)
  }
  const onClickGModifcado = async() => {
    $("#btn_j_gmodificado").attr("disabled",true)
    $("#iicon_btn_j_gmodificado").addClass("d-none")
    $("#spinner_btn_j_gmodificado").removeClass("d-none")
    if( txt_codigo === "" || txt_detalle === "" ){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      const response = await fetch(upd_url,opPe);
      const resdatos = await response.json();
      //console.log("modifi", resdatos);
      if (resdatos.vl_insert === 0){
        notify_warning("ERROR AL MODIFCAR, VERIFICAR DATOS!!!")
      }else if (resdatos.vl_insert === 1 ){
        onClickClearInputs()
        onClickListar()
        notify_valido("SE MODIFICO CORRECTAMENTE!!!")
      }
    }
    $("#btn_j_gmodificado").attr("disabled",false)
    $("#iicon_btn_j_gmodificado").removeClass("d-none")
    $("#spinner_btn_j_gmodificado").addClass("d-none")
  }
  const onClickClearInputs = async() => {
    setTxt_codigo("")
    setTxt_detalle("")
    $('.rdt_TableRow').removeClass("table-danger");
    $("#btn_j_guardar").removeClass('d-none')
    $("#btn_j_editar").addClass('d-none')
    $("#btn_j_gmodificado").addClass('d-none')
    setdisabled(false)
    $("#txt_j_codigo").attr('disabled',false)
    $("#btn_j_mlista").removeClass('d-none')
  }
  const onClickDelete = async(state) => {
    try {
      const dtLlegada = await JSON.parse(state.target.id);
      const codigo = dtLlegada.tjo_cod
      const del_body = { txt_codigo: codigo, txt_usuid: cookies.get('dtUser_id') };
      const del_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(del_body) }
    
      Swal.fire({
        title: 'Advertencia',
        text: '¿Esta seguro de eliminar este registro?',
        icon: 'error',
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si",
        confirmButtonColor: "#696cff",
        
      }).then(async response =>  {
        if (response.isConfirmed) {
          const respondt = await fetch(del_url,del_opPe);
          const resdatos = await respondt.json();
          console.log("resdatos",resdatos);
          if (resdatos.vl_insert === 0){
            notify_warning("ERROR AL ELIMINAR!!!")
          }else if (resdatos.vl_insert === 1 ){
            notify_valido("USUARIO SE ELIMINO CORRECTAMENTE!!!")
            Swal.fire({title:'EXITO', text:'USUARIO SE ELIMINO CORRECTAMENTE!!!', icon:'success',showConfirmButton:false, timer: 1000})
            onClickListar()
            onClickClearInputs()
          }
        }else if(response.isDenied){
          Swal.close();
        }
      })
      
    } catch (error) {
      console.log(error);
    }
  }

  const handleRowClicked = (row) => {
    setTxt_codigo(row.tjo_cod)
    setTxt_detalle(row.tjo_descripcion)
    selectTabla("cjo_tbl_lista")
    $("#btn_j_guardar").addClass('d-none')
    $("#btn_j_editar").removeClass('d-none')
    setdisabled(true)
    $("#btn_j_gmodificado").addClass('d-none')
    $("#btn_j_mlista").addClass('d-none')
  }
  // const selectTabla = () => {
  //   $('.rdt_TableBody').on('click', '.rdt_TableCell', function () {
  //     $('.rdt_TableRow').removeClass("table-danger");
  //     $($(this).parents(".rdt_TableRow")).addClass("table-danger");       
  //   })
  // };

  return(
    <>
    <div className="row mb-2">
      <div className="col-lg-2">Codigo:</div>
      <div className="col-lg-2">
        <input type="text" id="txt_j_codigo" name="txt_j_codigo" className="form-control form-control-sm"  autoComplete='off' value={txt_codigo} onChange={changetxt_codigo} disabled={disabled}/>
      </div>
      <div className="col-lg-2">Descripción:</div>
      <div className="col-lg-6">
        <input type="text" id="txt_detalle" name="txt_detalle" className="form-control form-control-sm"  autoComplete='off' value={txt_detalle} onChange={changetxt_detalle} disabled={disabled}/>
      </div>
    </div>
    <div className="row ">
      <div className="col-md-4 "></div>
      <div className="col-md-4 text-center ">
        <button className="btn btn-primary btn-sm" id="btn_j_guardar" onClick={onClickGuardar} >
          <i className='bx bx-save' id="icon_btn_j_guardar" ></i>
          <span className="spinner-border d-none" id="spinner_btn_j_guardar" role="status" aria-hidden="true"></span>
          &nbsp;Guardar
        </button>
        <button className="btn btn-warning btn-sm d-none" id="btn_j_editar" onClick={onClickEditInputs}>
          <i className='bx bx-edit' ></i>&nbsp;Editar
        </button>
        <button className="btn btn-success btn-sm d-none" id="btn_j_gmodificado" onClick={onClickGModifcado} >
          <i className='bx bx-send' id="iicon_btn_j_gmodificado" ></i>
          <span className="spinner-border d-none" id="spinner_btn_j_gmodificado" role="status" aria-hidden="true"></span>
          &nbsp;GModificado
        </button>
        <button className="btn btn-dark btn-sm" id="btn_j_cancelar" onClick={onClickClearInputs}>
          <i className='bx bx-history' ></i>&nbsp;Cancelar
        </button>
        <button className="btn btn-info btn-sm" id="btn_j_mlista" onClick={onClickListar} >
          <i className='bx bx-list-ul' ></i>&nbsp;MLista
        </button>
      </div>
    </div>
    <div className="card-datatable table-responsive ">
      <DataTableExtensions  {...lst} >
        <DataTable className="cjo_tbl_lista"
          Header
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          responsive
          highlightOnHover
          progressPending={pending}
          noContextMenu="No hay registros para mostrar"
          onRowClicked={handleRowClicked}
          //conditionalRowStyles={conditionalRowStyles}
        />
      </DataTableExtensions>
    </div>
    </>
  )
}
export const MGRMantenimientoConceptosEstados = () => {

  const [lst, setlst] = useState([]);
  const [pending, setpending] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const [txt_codigo, setTxt_codigo] = useState('');
  const [txt_detalle, setTxt_detalle] = useState('');

  const changetxt_codigo = (event) => { setTxt_codigo(event.target.value); }
  const changetxt_detalle = (event) => { setTxt_detalle(event.target.value); }
  const ins_url = '/gm-cts-e-insert'
  const lst_url = '/gm-cts-e-lst'
  const upd_url = '/gm-cts-e-update'
  const del_url = '/gm-cts-e-delete'
  const body = { txt_codigo: txt_codigo, txt_detalle: txt_detalle, txt_usuid: cookies.get('dtUser_id') };
  const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  const lst_columns = [
    { cell:(row) =>
      <div className="align-items-center">
          <button type="button" className="btn btn-sm btn-icon btn-label-danger" onClick={onClickDelete}  id={JSON.stringify(row)}><i id={JSON.stringify(row)} className='bx bx-x-circle bx-ms'></i></button>
      </div>
      ,ignoreRowClick: true, allowOverflow: true, button: true, name: 'OPCIÓN' },
    { name: 'CODIGO',  selector: row => row.est_cod,           cellExport: row => row.est_cod,           sortable: true },
    { name: 'DETALLE', selector: row => row.est_descripcion,   cellExport: row => row.est_descripcion,        sortable: true },
  ];
  const onClickGuardar = async() => {
    $("#btn_e_guardar").attr("disabled",true)
    $("#icon_btn_e_guardar").addClass("d-none")
    $("#spinner_btn_e_guardar").removeClass("d-none")
    if( txt_codigo === "" || txt_detalle === "" ){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      const response = await fetch(ins_url,opPe);
      const resdatos = await response.json();
      if (resdatos.vl_existe === 1){
        notify_warning("CODIGO YA EXISTE!!!")
      }
      if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
        notify_valido("CODIGO REGISTRO CORRECTAMENTE!!!")
        onClickClearInputs();
        if($(".rdt_Table").hasClass("table")){
          onClickListar();
        }
      }      
    }
    $("#btn_e_guardar").attr("disabled",false)
    $("#icon_btn_e_guardar").removeClass("d-none")
    $("#spinner_btn_e_guardar").addClass("d-none")
  }
  const onClickListar = async() => {
    const columns = lst_columns
    setpending(true)
    const response = await fetch(lst_url,opPe);
    const data = await response.json();
    //console.log(data);
    const tableData = { columns, data };
    setlst(tableData)
    setpending(false)
    $(".ces_tbl_lista .rdt_Table").addClass("table");
  }
  const onClickEditInputs = async() => {
    setdisabled(false)
    $("#btn_e_editar").addClass('d-none')
    $("#btn_e_gmodificado").removeClass('d-none')
    setTimeout(()=>{ $("#txt_e_codigo").attr('disabled',true) },500)
  }
  const onClickGModifcado = async() => {
    $("#btn_e_gmodificado").attr("disabled",true)
    $("#icon_btn_e_gmodificado").addClass("d-none")
    $("#spinner_btn_e_gmodificado").removeClass("d-none")
    if( txt_codigo === "" || txt_detalle === "" ){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      const response = await fetch(upd_url,opPe);
      const resdatos = await response.json();
      //console.log("modifi", resdatos);
      if (resdatos.vl_insert === 0){
        notify_warning("ERROR AL MODIFCAR, VERIFICAR DATOS!!!")
      }else if (resdatos.vl_insert === 1 ){
        onClickClearInputs()
        onClickListar()
        notify_valido("SE MODIFICO CORRECTAMENTE!!!")
      }
    }
    $("#btn_e_gmodificado").attr("disabled",false)
    $("#icon_btn_e_gmodificado").removeClass("d-none")
    $("#spinner_btn_e_gmodificado").addClass("d-none")
  }
  const onClickClearInputs = async() => {
    setTxt_codigo("")
    setTxt_detalle("")
    $('.rdt_TableRow').removeClass("table-danger");
    $("#btn_e_guardar").removeClass('d-none')
    $("#btn_e_editar").addClass('d-none')
    $("#btn_e_gmodificado").addClass('d-none')
    setdisabled(false)
    $("#txt_e_codigo").attr('disabled',false)
    $("#btn_e_mlista").removeClass('d-none')
  }
  const onClickDelete = async(state) => {
    try {
      const dtLlegada = await JSON.parse(state.target.id);
      const codigo = dtLlegada.est_cod
      const del_body = { txt_codigo: codigo, txt_usuid: cookies.get('dtUser_id') };
      const del_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(del_body) }
    
      Swal.fire({
        title: 'Advertencia',
        text: '¿Esta seguro de eliminar este registro?',
        icon: 'error',
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si",
        confirmButtonColor: "#696cff",
        
      }).then(async response =>  {
        if (response.isConfirmed) {
          const respondt = await fetch(del_url,del_opPe);
          const resdatos = await respondt.json();
          console.log("resdatos",resdatos);
          if (resdatos.vl_insert === 0){
            notify_warning("ERROR AL ELIMINAR!!!")
          }else if (resdatos.vl_insert === 1 ){
            notify_valido("USUARIO SE ELIMINO CORRECTAMENTE!!!")
            Swal.fire({title:'EXITO', text:'USUARIO SE ELIMINO CORRECTAMENTE!!!', icon:'success',showConfirmButton:false, timer: 1000})
            onClickListar()
            onClickClearInputs()
          }
        }else if(response.isDenied){
          Swal.close();
        }
      })
      
    } catch (error) {
      console.log(error);
    }
  }

  const handleRowClicked = (row) => {
    setTxt_codigo(row.est_cod)
    setTxt_detalle(row.est_descripcion)
    selectTabla("ces_tbl_lista")
    $("#btn_e_guardar").addClass('d-none')
    $("#btn_e_editar").removeClass('d-none')
    setdisabled(true)
    $("#btn_e_gmodificado").addClass('d-none')
    $("#btn_e_mlista").addClass('d-none')
  }
  // const selectTabla = () => {
  //   $('.rdt_TableBody').on('click', '.rdt_TableCell', function () {
  //     $('.rdt_TableRow').removeClass("table-danger");
  //     $($(this).parents(".rdt_TableRow")).addClass("table-danger");       
  //   })
  // };

  return(
    <>
    <div className="row mb-2">
      <div className="col-lg-2">Codigo:</div>
      <div className="col-lg-2">
        <input type="text" id="txt_e_codigo" name="txt_e_codigo" className="form-control form-control-sm"  autoComplete='off' value={txt_codigo} onChange={changetxt_codigo} disabled={disabled}/>
      </div>
      <div className="col-lg-2">Descripción:</div>
      <div className="col-lg-6">
        <input type="text" id="txt_e_detalle" name="txt_e_detalle" className="form-control form-control-sm"  autoComplete='off' value={txt_detalle} onChange={changetxt_detalle} disabled={disabled}/>
      </div>
    </div>
    <div className="row ">
      <div className="col-md-4 "></div>
      <div className="col-md-4 text-center ">
        <button className="btn btn-primary btn-sm" id="btn_e_guardar" onClick={onClickGuardar} >
          <i className='bx bx-save' id="icon_btn_e_guardar" ></i>
          <span className="spinner-border d-none" id="spinner_btn_e_guardar" role="status" aria-hidden="true"></span>
          &nbsp;Guardar
        </button>
        <button className="btn btn-warning btn-sm d-none" id="btn_e_editar" onClick={onClickEditInputs}>
          <i className='bx bx-edit' ></i>&nbsp;Editar
        </button>
        <button className="btn btn-success btn-sm d-none" id="btn_e_gmodificado" onClick={onClickGModifcado} >
          <i className='bx bx-send' id="icon_btn_e_gmodificado" ></i>
          <span className="spinner-border d-none" id="spinner_btn_e_gmodificado" role="status" aria-hidden="true"></span>
          &nbsp;GModificado
        </button>
        <button className="btn btn-dark btn-sm" id="btn_e_cancelar" onClick={onClickClearInputs}>
          <i className='bx bx-history' ></i>&nbsp;Cancelar
        </button>
        <button className="btn btn-info btn-sm" id="btn_e_mlista" onClick={onClickListar} >
          <i className='bx bx-list-ul' ></i>&nbsp;MLista
        </button>
      </div>
    </div>
    <div className="card-datatable table-responsive ">
      <DataTableExtensions  {...lst} >
        <DataTable className="ces_tbl_lista"
          Header
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          responsive
          highlightOnHover
          progressPending={pending}
          noContextMenu="No hay registros para mostrar"
          onRowClicked={handleRowClicked}
          //conditionalRowStyles={conditionalRowStyles}
        />
      </DataTableExtensions>
    </div>
    </>
  )
}
export const MGRMantenimientoOperaciones = () => {
  const url_est ='/get-data-estados';
  const [lst, setlst] = useState([]);
  const [pending, setpending] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const [disabledUni, setdisabledUni] = useState('');
  const [txt_periodo, setTxt_periodo] = useState(new Date());
  const [txt_codigo, setTxt_codigo] = useState('');
  const [txt_detalle, setTxt_detalle] = useState('');
  const [txt_fechaini, setTxt_fechaini] = useState(new Date());
  const [txt_fechafin, setTxt_fechafin] = useState(new Date());
  const [sl_t_est, setSl_t_est] = useState('');
  
  const changetxt_codigo = (event) => { setTxt_codigo(event.target.value); }
  const changetxt_detalle = (event) => { setTxt_detalle(event.target.value); }
  const changeSl_t_est = (event) => { setSl_t_est(event.target.value);}


  const ins_url = '/gm-sop-insert'
  const lst_url = '/gm-sop-lst'
  const upd_url = '/gm-sop-update'
  const del_url = '/gm-sop-delete'

  const body = { txt_periodo:txt_periodo, txt_codigo: txt_codigo, txt_detalle: txt_detalle, txt_fechaini:txt_fechaini, txt_fechafin:txt_fechafin, sl_t_est:sl_t_est, txt_usuid: cookies.get('dtUser_id') };
  const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  const lst_columns = [
    { cell:(row) =>
      <div className="align-items-center">
          <button type="button" className="btn btn-sm btn-icon btn-label-danger" onClick={onClickDelete}  id={JSON.stringify(row)}><i id={JSON.stringify(row)} className='bx bx-x-circle bx-ms'></i></button>
      </div>
      ,ignoreRowClick: true, allowOverflow: true, button: true, name: 'OPCIÓN' },
    { name: 'PERIODO',  selector: row => row.sop_periodo,     cellExport: row => row.sop_periodo,     sortable: true },
    { name: 'CODIGO',   selector: row => row.sop_cod,         cellExport: row => row.sop_cod,         sortable: true },
    { name: 'DETALLE',  selector: row => row.sop_detalle,     cellExport: row => row.sop_detalle,     sortable: true },
    { name: 'FEC.INI',  selector: row => row.sop_diainicio,   cellExport: row => row.sop_diainicio,   sortable: true },
    { name: 'FEC.FRIM', selector: row => row.sop_diafin,      cellExport: row => row.sop_diafin,      sortable: true },
    { name: 'CODIGO',   selector: row => row.est_cod,         cellExport: row => row.est_cod,         sortable: true, omit: true },
    { name: 'Estado',   selector: row => row.est_descripcion, cellExport: row => row.est_descripcion, sortable: true, },
  ];
  const onClickGuardar = async() => {
    $("#btn_sop_guardar").attr("disabled",true)
    $("#icon_btn_sop_guardar").addClass("d-none")
    $("#spinner_btn_sop_guardar").removeClass("d-none")
    if( txt_periodo === "" || txt_codigo === "" || txt_detalle === "" || txt_fechaini === "" || txt_fechafin === "" || sl_t_est  === "" ){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      const response = await fetch(ins_url,opPe);
      const resdatos = await response.json();
      if (resdatos.vl_existe === 1){
        notify_warning("CODIGO YA EXISTE!!!")
      }
      if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
        notify_valido("CODIGO REGISTRO CORRECTAMENTE!!!")
        onClickClearInputs();
        if($(".rdt_Table").hasClass("table")){
          onClickListar();
        }
      }      
    }
    $("#btn_sop_guardar").attr("disabled",false)
    $("#icon_btn_sop_guardar").removeClass("d-none")
    $("#spinner_btn_sop_guardar").addClass("d-none")
  }
  const onClickListar = async() => {
    const columns = lst_columns
    setpending(true)
    const response = await fetch(lst_url,opPe);
    const data = await response.json();
    //console.log(data);
    const tableData = { columns, data };
    setlst(tableData)
    setpending(false)
    $(".ope_tbl_lista .rdt_Table").addClass("table");
  }
  const onClickEditInputs = async() => {
    setdisabled(false)
    setdisabledUni(true)
    $("#btn_sop_editar").addClass('d-none')
    $("#btn_sop_gmodificado").removeClass('d-none')
    // setTimeout(()=>{ 
    //   $("#txt_sop_codigo").attr('disabled',true) 
    // },500)
  }
  const onClickGModifcado = async() => {
    $("#btn_sop_gmodificado").attr("disabled",true)
    $("#icon_btn_sop_gmodificado").addClass("d-none")
    $("#spinner_btn_sop_gmodificado").removeClass("d-none")
    if( txt_periodo === "" || txt_codigo === "" || txt_detalle === "" || txt_fechaini === "" || txt_fechafin === "" || sl_t_est  === "" ){
      
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      const response = await fetch(upd_url,opPe);
      const resdatos = await response.json();
      //console.log("modifi", resdatos);
      if (resdatos.vl_insert === 0){
        console.log(txt_periodo, txt_codigo, txt_detalle, txt_fechaini, txt_fechafin, sl_t_est)
        notify_warning("ERROR AL MODIFCAR, VERIFICAR DATOS!!!")
      }else if (resdatos.vl_insert === 1 ){
        onClickClearInputs()
        onClickListar()
        notify_valido("SE MODIFICO CORRECTAMENTE!!!")
      }
    }
    $("#btn_sop_gmodificado").attr("disabled",false)
    $("#icon_btn_sop_gmodificado").removeClass("d-none")
    $("#spinner_btn_sop_gmodificado").addClass("d-none")
  }
  const onClickClearInputs = async() => {
    setTxt_periodo(new Date())
    setTxt_codigo("")
    setTxt_detalle("")
    setTxt_fechaini(new Date())
    setTxt_fechafin(new Date())
    setSl_t_est("")
    $('.rdt_TableRow').removeClass("table-danger");
    $("#btn_sop_guardar").removeClass('d-none')
    $("#btn_sop_editar").addClass('d-none')
    $("#btn_sop_gmodificado").addClass('d-none')
    setdisabled(false)
    setdisabledUni('')
    $("#txt_sop_codigo").attr('disabled',false)
    $("#btn_sop_mlista").removeClass('d-none')
  }
  const onClickDelete = async(state) => {
    try {
      const dtLlegada = await JSON.parse(state.target.id);
      const period = dtLlegada.sop_periodo
      const codigo = dtLlegada.sop_cod
      const del_body = {txt_periodo:period,  txt_codigo: codigo, txt_usuid: cookies.get('dtUser_id') };
      const del_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(del_body) }
    
      Swal.fire({
        title: 'Advertencia',
        text: '¿Esta seguro de eliminar este registro?',
        icon: 'error',
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si",
        confirmButtonColor: "#696cff",
        
      }).then(async response =>  {
        if (response.isConfirmed) {
          const respondt = await fetch(del_url,del_opPe);
          const resdatos = await respondt.json();
          console.log("resdatos",resdatos);
          if (resdatos.vl_insert === 0){
            notify_warning("ERROR AL ELIMINAR!!!")
          }else if (resdatos.vl_insert === 1 ){
            notify_valido("USUARIO SE ELIMINO CORRECTAMENTE!!!")
            Swal.fire({title:'EXITO', text:'USUARIO SE ELIMINO CORRECTAMENTE!!!', icon:'success',showConfirmButton:false, timer: 1000})
            onClickListar()
            onClickClearInputs()
          }
        }else if(response.isDenied){
          Swal.close();
        }
      })
      
    } catch (error) {
      console.log(error);
    }
  }

  const handleRowClicked = (row) => {
    
    //setTxt_periodo(new Date('2023-02'))
    setTxt_periodo(new Date(moment(row.sop_periodo, 'Y-M').format('YYYY-MM-DD HH:mm:ss')))
    setTxt_codigo(row.sop_cod)
    setTxt_detalle(row.sop_detalle)
    setTxt_fechaini(new Date(moment(row.sop_diainicio, 'Y-M-D').format('YYYY-MM-DD HH:mm:ss')))
    setTxt_fechafin(new Date(moment(row.sop_diafin, 'Y-M-D').format('YYYY-MM-DD HH:mm:ss')))
    setSl_t_est(row.est_cod)
    selectTabla("ope_tbl_lista")
    $("#btn_sop_guardar").addClass('d-none')
    $("#btn_sop_editar").removeClass('d-none')
    setdisabled(true)
    $("#btn_sop_gmodificado").addClass('d-none')
    $("#btn_sop_mlista").addClass('d-none')
  }
  // const selectTabla = () => {
  //   $('.rdt_TableBody').on('click', '.rdt_TableCell', function () {
  //     $('.rdt_TableRow').removeClass("table-danger");
  //     $($(this).parents(".rdt_TableRow")).addClass("table-danger");       
  //   })
  // };

  return(
    <>
      <div className="row ">
        <div className="col-lg-2">Periodo:</div>
        <div className="col-lg-2">
          <DatePicker 
            locale="es"
            id="txt_sop_periodo"
            className="form-control form-control-sm text-center" 
            selected={txt_periodo} 
            onChange={date => setTxt_periodo(date)} 
            dateFormat="yyyy-MM"
            showMonthYearPicker
            disabled={(disabledUni === '' )? disabled : disabledUni}
          />
          {/**<input type="text" id="txt_sop_periodo" name="txt_sop_periodo" className="form-control form-control-sm"  autoComplete='off' value={txt_periodo} onChange={changetxt_periodo} disabled={disabled}  />**/}
        </div>
        <div className="col-lg-2">F.Inicio:</div>
        <div className="col-lg-2">
          <DatePicker 
            locale="es"
            className="form-control form-control-sm text-center" 
            selected={txt_fechaini} 
            onChange={date => setTxt_fechaini(date)} 
            dateFormat="yyyy-MM-dd"
            disabled={disabled}
          />
          {/**<input type="text" id="txt_sop_fechaini" name="txt_sop_fechaini" className="form-control form-control-sm"  autoComplete='off' value={txt_fechaini} onChange={changetxt_fechaini} disabled={disabled}  />**/}
        </div>
        <div className="col-lg-2">F.Fin:</div>
        <div className="col-lg-2">
          <DatePicker 
            locale="es"
            className="form-control form-control-sm text-center" 
            selected={txt_fechafin} 
            onChange={date => setTxt_fechafin(date)} 
            dateFormat="yyyy-MM-dd"
            disabled={disabled}
          />
          {/**<input type="text" id="txt_sop_fechafin" name="txt_sop_fechafin" className="form-control form-control-sm"  autoComplete='off' value={txt_fechafin} onChange={changetxt_fechafin} disabled={disabled}  />**/}
        </div>
        
      </div>
      <div className="row mb-2">
        <div className="col-lg-2">Codigo:</div>
        <div className="col-lg-2">
          {/* <input type="text" id="txt_sop_codigo" name="txt_sop_codigo" className="form-control form-control-sm"  autoComplete='off' value={txt_codigo} onChange={changetxt_codigo} disabled={(disabledUni === '' )? disabled : disabledUni}  /> */}
          {/* <SelectTipo id="slc_estado" url={url_est} value={txt_codigo} onChange={changetxt_codigo} disabled={(disabledUni === '' )? disabled : disabledUni} /> */}
          <select className="form-select form-select-sm"  value={txt_codigo} onChange={changetxt_codigo} disabled={(disabledUni === '' )? disabled : disabledUni}>
            <option value={"SP01"} >SEMANA 1</option>
            <option value={"SP02"} >SEMANA 2</option>
            <option value={"SP03"} >SEMANA 3</option>
            <option value={"SP04"} >SEMANA 4</option>
            <option value={"SP05"} >SEMANA 5</option>
          </select>
        </div>
        <div className="col-lg-2">Detalle:</div>
        <div className="col-lg-2">
          <input type="text" id="txt_sop_detalle" name="txt_sop_detalle" className="form-control form-control-sm"  autoComplete='off' value={txt_detalle} onChange={changetxt_detalle} disabled={disabled}  />
        </div>
        <div className="col-lg-2">Estado:</div>
        <div className="col-lg-2">
          <SelectTipo id="slc_estado" url={url_est} value={sl_t_est} onChange={changeSl_t_est} disabled={disabled} />
        </div>
      </div>
      <div className="row ">
            <div className='col-lg-4' ></div>
            <div className='col-lg-4' >
              <button className="btn btn-primary btn-sm" id="btn_sop_guardar" onClick={onClickGuardar} >
                <i className='bx bx-save' id="icon_btn_sop_guardar" ></i>
                <span className="spinner-border d-none" id="spinner_btn_sop_guardar" role="status" aria-hidden="true"></span>
                &nbsp;Guardar
              </button>
              <button className="btn btn-warning btn-sm d-none" id="btn_sop_editar" onClick={onClickEditInputs}>
                <i className='bx bx-edit' ></i>&nbsp;Editar
              </button>
              <button className="btn btn-success btn-sm d-none" id="btn_sop_gmodificado" onClick={onClickGModifcado} >
                <i className='bx bx-send' id="icon_btn_sop_gmodificado" ></i>
                <span className="spinner-border d-none" id="spinner_btn_sop_gmodificado" role="status" aria-hidden="true"></span>
                &nbsp;GModificado
              </button>
              <button className="btn btn-dark btn-sm" id="btn_sop_cancelar" onClick={onClickClearInputs}>
                <i className='bx bx-history' ></i>&nbsp;Cancelar
              </button>
              <button className="btn btn-info btn-sm" id="btn_sop_mlista" onClick={onClickListar} >
                <i className='bx bx-list-ul' ></i>&nbsp;MLista
              </button>
            </div>
      </div>
      <div className="card-datatable table-responsive ">
        <DataTableExtensions  {...lst} >
          <DataTable className="ope_tbl_lista"
            Header
            defaultSortField="id"
            defaultSortAsc={true}
            pagination
            responsive
            highlightOnHover
            progressPending={pending}
            noContextMenu="No hay registros para mostrar"
            onRowClicked={handleRowClicked}
            //conditionalRowStyles={conditionalRowStyles}
          />
        </DataTableExtensions>
      </div>          
    </>
  )
}


const MGR_Mantenimiento = () => {

  return (
    <div>
        <h4 className="fw-bold py-1"> Mantenimiento </h4>
        <div className="col-xl-12">
          <div className="nav-align-top">
              <ul className="nav nav-pills mb-3 nav-fill" role="tablist">
                  <li className="nav-item">
                      <button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-justified-Vehiculos" aria-controls="navs-pills-justified-Vehiculos" aria-selected="true"><i className="tf-icons bx bx-car" /> Vehículos </button>
                  </li>
                  <li className="nav-item">
                      <button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-justified-Conceptos" aria-controls="navs-pills-justified-Conceptos" aria-selected="false"><i className="tf-icons bx bxs-objects-vertical-center" /> Conceptos </button>
                  </li>
                  <li className="nav-item">
                      <button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-justified-Empleados" aria-controls="navs-pills-justified-Empleados" aria-selected="false"><i className="tf-icons bx bx-user" /> Empleados</button>
                  </li>
                  <li className="nav-item">
                      <button type="button" className="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-justified-SOperacion" aria-controls="navs-pills-justified-SOperacion" aria-selected="false"><i className="tf-icons bx bx-user" /> S.Operación</button>
                  </li>
              </ul>
              <div className="tab-content">

                  {/** --------- VEHICULOS ----- */}
                  <div className="tab-pane fade" id="navs-pills-justified-Vehiculos" role="tabpanel">
                    <MGRMantenimientoVehiculos />
                  </div>

                  {/** --------- CONCEPTOS ----- */}
                  <div className="tab-pane fade" id="navs-pills-justified-Conceptos" role="tabpanel">
                    <div className="nav-align-top">
                      <ul className="nav nav-tabs nav-fill" role="tablist">
                        <li className="nav-item">
                          <button type="button" className="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-Partidas" aria-controls="navs-justified-Partidas" aria-selected="true"> Partidas </button>
                        </li>
                        <li className="nav-item">
                          <button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-Jornada" aria-controls="navs-justified-Jornada" aria-selected="false"> Jornada</button>
                        </li>
                        <li className="nav-item">
                          <button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-Estados" aria-controls="navs-justified-Estados" aria-selected="false"> Estados</button>
                        </li>
                        <li className="nav-item">
                          <button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-Extras" aria-controls="navs-justified-Extras" aria-selected="false"> Extras</button>
                        </li>
                      </ul>
                      <div className="tab-content">

                        {/** --------- PARTIDAS ----- */}
                        <div className="tab-pane fade show active" id="navs-justified-Partidas" role="tabpanel">
                          <MGRMantenimientoConceptosPartidas />
                        </div>

                        {/** --------- JORNADAS ----- */}
                        <div className="tab-pane fade" id="navs-justified-Jornada" role="tabpanel">
                          <MGRMantenimientoConceptosJornadas />
                        </div>

                        {/** --------- ESTADOS ----- */}
                        <div className="tab-pane fade" id="navs-justified-Estados" role="tabpanel">
                          <MGRMantenimientoConceptosEstados />
                        </div>

                        {/** --------- EXTRAS ----- */}
                        <div className="tab-pane fade" id="navs-justified-Extras" role="tabpanel">
                          <p>OPCIONAL</p>
                        </div>

                      </div>
                    </div>
                  </div>

                  {/** --------- EMPLEADOS ----- */}
                  <div className="tab-pane fade  " id="navs-pills-justified-Empleados" role="tabpanel">
                    <MGRMantenimientoEmpleados />
                  </div>

                  {/** --------- SOPERACION ----- */}
                  <div className="tab-pane fade show active" id="navs-pills-justified-SOperacion" role="tabpanel">
                      <MGRMantenimientoOperaciones />
                  </div>

              </div>
          </div>
        </div>
        <ModalsSubPartidas  />
        <ToastContainer />
    </div>
  )
}

export default MGR_Mantenimiento