/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

function Contenido() {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12 mb-4 order-0">
          <div className="card">
            <div className="d-flex align-items-end row">
              <div className="col-sm-7">
                <div className="card-body">
                  <h5 className="card-title text-primary">Bienvenid@ 🎉</h5>
                  <p className="mb-3">Al nuevo Sistema Web de <span className="fw-bold text-danger">Alvarez Bohl</span>, actualmente se viene trabajando para optimizar procesos.</p>
                  <a href="javascript:;" className="btn btn-sm btn-label-primary d-none">View Badges</a>
                </div>
              </div>
              <div className="col-sm-5 text-center text-sm-left">
                <div className="card-body pb-0 px-0 px-md-4">
                  <img src="assets/img/illustrations/man-with-laptop-light.png" height={140} alt="View Badge User" data-app-dark-img="illustrations/man-with-laptop-dark.png" data-app-light-img="illustrations/man-with-laptop-light.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      

    </div>

  )
}

export default Contenido