import * as React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom"
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
//import { useIsAuthenticated } from "@azure/msal-react";
import Cookies from 'universal-cookie';
/** Vistas publicas - blancas */
import Login from "./views/Login"
import Catalogo from "./views/Public/Catalogo";

import Dashboard from "./views/Dashboard";
import Home from "./views/Home";
import Salas from "./views/Salas";
import Clientes from "./views/Clientes";
import Contenido from "./views/Contenido";
import Contenido2 from "./views/Contenido2";
import Cuentas from "./views/Cuentas";
import Trabajadores from "./views/Mantenimiento/Trabajadores";
import TablasGenerales from "./views/Mantenimiento/TablasGenerales";
import Usuarios from "./views/Mantenimiento/Usuarios";
import Error404 from "./views/error/error_404";
import Error503 from "./views/error/error_503";

/** super */
import ContSuper from "./views/ContSuper";
import MasterClientes from "./views/MasterClientes";
import MasterCVentas from "./views/MasterCVentas";
import MasterListaPrecios from "./views/MasterListaPrecios";
import MasterCuboVentas from "./views/MasterCuboVentas";
import MasterVStock from "./views/MasterVStock";
import MasterComisiones from "./views/MasterComisiones";
/** MGR */
import MGRContenido from "./views/ModuleGastosReparto/MGR_Contenido";
import MGRMantenimiento from "./views/ModuleGastosReparto/MGR_Mantenimiento";
import MGRResumen from "./views/ModuleGastosReparto/MGR_Resumen";
import MGRRportes from "./views/ModuleGastosReparto/MGR_Reportes";


/** MGI */
import MGIContenido from "./views/ModuloGestionInfraestructura/MGI_Contenido";
import MgiInventarios from "./views/ModuloGestionInfraestructura/Mgi_Inventarios";

/** MPROVEEDORES */
import MPdashboards from './views/ModuleProveedores/MP_dashboards';
import MPventas from './views/ModuleProveedores/MP_ventas';
import MPstock from './views/ModuleProveedores/MP_stock';

/** MCOMERCIAL */
import DDuplicados from "./views/MComercial/DDuplicados";

registerLocale('es', es)

function App() {

 
  const cookies = new Cookies();
  let status = cookies.get("CookieSession")
  var ultimaPagina = cookies.get("ultimapag")
    
  var urlActual = window.location.href.split("/");
  //var url_dxl = fc_unsubscribe(urlActual[3]).split("?");/** 3 - 4 */
  //console.log("url_dxl",url_dxl);
  var url_nam = urlActual[3];



  //status === 'False' || !status ?
    return (               
        <Router>
          <Routes>
            {
              status === 'False' || !status?
              //<Route path="/" element={<Login />} >
              <Route path="/" >
                <Route index element={<Login />} />
                <Route path="/login" element={<Login />} />
                {/* <Route path={`/${btoa("catalogo")}`} element={<Catalogo />} /> */}
                {/* <Route path={"/"+url_nam} element={<Catalogo />} /> */}
                {
                  url_nam.length > 20?
                    <Route path={"/"+urlActual[3]} element={<Catalogo />} />
                  :
                  <Route path="*" element={<Login />} />

                }
                
                <Route path="*" element={<Login />} />
              </Route>
              :
              <Route element={ <Dashboard />}>
                <Route path="/contenido" exec={true} element={<Contenido />} />
                <Route path="/contenido2"         element={<Contenido2 />} /> 
                <Route path="/home"               element={<Home />} /> 
                <Route path="/salas"              element={<Salas />} />
                <Route path="/clientes"           element={<Clientes />} />
                <Route path="/cuentas"            element={<Cuentas />} />
                <Route path="/trabajadores"       element={<Trabajadores />} />
                <Route path="/tablasgenerales"    element={<TablasGenerales />} />
                <Route path="/usuarios"           element={<Usuarios />} />
                <Route path="/contsuper"          element={<ContSuper />} />
                <Route path="/masterclientes"     element={<MasterClientes />} />
                <Route path="/mastercventas"      element={<MasterCVentas />} />
                <Route path="/masterlistaprecios" element={<MasterListaPrecios />} />
                <Route path="/mastercuboventas"   element={<MasterCuboVentas />} />
                <Route path="/mastervstock"       element={<MasterVStock />} />
                <Route path="/mastercomisiones"   element={<MasterComisiones />} />
                <Route path="/mgr_contenido"      element={<MGRContenido />} />
                <Route path="/mgr_mantenimiento"  element={<MGRMantenimiento />} />
                <Route path="/mgr_resumen"        element={<MGRResumen />} />
                <Route path="/mgr_reportes"       element={<MGRRportes />} />
                <Route path="/mgi_contenido"      element={<MGIContenido />} />
                <Route path="/mgi_inventarios"    element={<MgiInventarios />} />

                <Route path="/ikdashboards"    element={<MPdashboards />} />
                <Route path="/ikventas"    element={<MPventas />} />
                <Route path="/ikstock"    element={<MPstock />} />
                <Route path="/pldashboards"    element={<MPdashboards />} />
                <Route path="/plventas"    element={<MPventas />} />
                <Route path="/plstock"    element={<MPstock />} />
                <Route path="/dduplicados"    element={<DDuplicados />} />

                <Route path="/*" element={ultimaPagina!=="" ? <Navigate to={"/"+ultimaPagina} /> : <Error503 />} />     
              </Route>
            }
            <Route path="*" element={<Error404 />} ></Route>
          </Routes> 
        </Router>
    )
}
export default App