/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
//, useEffect , Box
//notify_warning
import React, { useState, useEffect } from "react";
import Cookies from 'universal-cookie';
import { ToastContainer } from 'react-toastify';
import { notify_warning } from '../../components/utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip, Box } from '@mui/material';
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css';
import { SelectTipo } from '../../components/Selects/SelectsPrincipales';
import '../../App.css';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const moment = require('moment')


const MPventas = () => {
    const cookies = new Cookies();
    const dtUser = cookies.get('dtUser');
    const dtarrL = cookies.get('dtUserProv_LnsProv')
    const [pending, setPending] = useState(false);
    // const [users, setUsers] = useState([])
    const [users, setUsers] =  useState({ columns: [], data2: [] });
    
    const [sl_t_lineas, setsl_lineas] = useState([]);
    const changeSl_lineas = (event) => { setsl_lineas(event.target.value); }
    const [sl_t_ven, setsl_t_ven] = useState('9999');
    //const [sl_vendedores, setsl_vendedores] = useState([]);


    const changeSl_t_ven = (event) => { setsl_t_ven(event.target.value); }
    const [sl_t_suc, setsl_t_suc] = useState('9999');
    //const dtSucu = dtUser.sl_dt_suc[0].suc_nombre;
    //const [sl_t_suc2, setsl_t_suc2] = useState(dtSucu);
    
    const changeSl_t_suc = (event) => {
        //setsl_t_suc2(event.target.value)
        setsl_t_suc(event.target.value);
    }
   
    
    const [inp_text_peri_mes, setInp_text_peri_mes] = useState(new Date());

    useEffect(() => {
        savePathInCookie();
    }, []);
    function savePathInCookie() {
        const path = window.location.pathname.substr(1); // Obtiene la parte después de la barra diagonal

        // Guarda el valor en una cookie con nombre "ultimapag"
        document.cookie = `ultimapag=${path}`;
    }
    
    const url0 = '/pos-ventas';
    const url1 = '/getProvVende';

    const columns = [
        { name: 'Sucursal', selector: row => row.Sucursal, cellExport: row => row.Sucursal, sortable: true, width: "8rem" },
        { name: 'Periodo', selector: row => row.Periodo, cellExport: row => row.Periodo, sortable: true, width: "7rem" },
        { name: 'Codigo', selector: row => row.CodProducto, cellExport: row => row.CodProducto, sortable: true },
        { name: 'Producto', selector: row => row.Producto, cellExport: row => row.Producto, sortable: true, width: "20rem" },
        { name: 'Unidad', selector: row => row.Unidad, cellExport: row => row.Unidad, sortable: true },
        { name: 'Bodegas', selector: row => row.Bod, cellExport: row => row.Bod, sortable: true },
        { name: 'Mercados', selector: row => row.Mer, cellExport: row => row.Mer, sortable: true },
        { name: 'Mayoristas', selector: row => row.May, cellExport: row => row.May, sortable: true },
        { name: 'Stock', selector: row => row.Stock, cellExport: row => row.Stock, sortable: true },
        { name: 'UnidadNegocio', selector: row => row.UnidadNegocio, cellExport: row => row.UnidadNegocio, sortable: true },
        { name: 'Mesa', selector: row => row.Mesa, cellExport: row => row.Mesa, sortable: true },

    ];


    // **************** Funciones Clicks Botones ********************++
    // const onClickConsultar = async () => {
    //     // boton
    //     setPending(true)
    //     document.getElementById('btn_procesar').disabled = "true";
    //     document.getElementById('btn_procesar_icon').setAttribute('class', 'd-none');
    //     document.getElementById('btn_procesar_spinner').className = 'spinner-border';
    //     let body = {
    //         txt_periodo: inp_text_peri_mes,
    //         txt_sucursal: sl_t_suc,
    //         txt_vendedor: sl_t_ven,
            
    //     };
    //     const config = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    //     const showData = async () => {
    //         const response = await fetch('/api-ventas', config);
    //         const data = await response.json();
            
    //         let data2= data.data
    //         console.log("data:: ", data2);
    //         const tableData = { columns, data2 };
    //         setUsers(tableData)
    //         document.getElementById('btn_procesar').disabled = "";
    //         document.getElementById('btn_procesar_icon').setAttribute('class', 'bx bx-cloud-download bx-ms');
    //         document.getElementById('btn_procesar_spinner').className = 'spinner-border d-none';
    //         setPending(false)
    //     }
    //     showData()
    //     document.getElementById('btn_procesar').disabled = "False";
    // }

    const onClickExcel = async () => {
        const fileType = "xlsx"
        const nameFileType = "AVANCE_DE_VENTAS_" + moment(inp_text_peri_mes).format('YYYY-MM-DD')
        document.getElementById('btn_procesar_excel').disabled = "true";
        document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'd-none');
        document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border';

        let body_t = {
            txt_periodo: inp_text_peri_mes,
            txt_sucursal: sl_t_suc,
            txt_linea: sl_t_lineas,
            txt_vendedor: sl_t_ven,
            arr_lineas: dtarrL
        };
        const opti_t = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body_t) }

        const showData = async () => {
            const response = await fetch('/api-ventas', opti_t);
            const datadtos = await response.json();

            if (datadtos.length) {

                for (var i = 0; i < datadtos.length; i++) { 
                let fecha = new Date(moment(datadtos[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
                fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
                datadtos[i]["FechaEmision"] = fecha;

                let fgia = new Date(moment(datadtos[i]["GUIA"]).format('YYYY-MM-DD HH:mm:ss'))
                fgia.setMinutes(fgia.getMinutes() + fgia.getTimezoneOffset())
                datadtos[i]["GUIA"] = fgia;
                };

                const ws = XLSX.utils.json_to_sheet(datadtos);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, `${nameFileType}.xlsx`);
                document.getElementById('btn_procesar_excel').disabled = "";
                document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
                document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';
            } else {
                document.getElementById('btn_procesar_excel').disabled = "";
                document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
                document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';
                notify_warning("No existen datos, verificar campos");
            }
        }
        showData();
    }
    const onClickCSV = async () => {
        const fileType = "csv"
        const nameFileType = "AVANCE_DE_VENTAS_" + moment(inp_text_peri_mes).format('YYYY-MM-DD')
        document.getElementById('btn_procesar_excel').disabled = "true";
        document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'd-none');
        document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border';

        let body_t = {
            txt_periodo: inp_text_peri_mes,
            txt_sucursal: sl_t_suc,
            txt_linea: sl_t_lineas,
            txt_vendedor: sl_t_ven,
            arr_lineas: dtarrL
        };
        const opti_t = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body_t) }

        const showData = async () => {
            const response = await fetch('/api-ventas', opti_t);
            const datadtos = await response.json();
            //console.log("--saas",datadtos.length);
            if (datadtos.length) {

                for (var i = 0; i < datadtos.length; i++) { 
                let fecha = new Date(moment(datadtos[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
                fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
                datadtos[i]["FechaEmision"] = fecha;

                let fgia = new Date(moment(datadtos[i]["GUIA"]).format('YYYY-MM-DD HH:mm:ss'))
                fgia.setMinutes(fgia.getMinutes() + fgia.getTimezoneOffset())
                datadtos[i]["GUIA"] = fgia;
                };

                const ws = XLSX.utils.json_to_sheet(datadtos);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "buffer" });//array
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, `${nameFileType}.csv`);
                document.getElementById('btn_procesar_excel').disabled = "";
                document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
                document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';
            } else {
                document.getElementById('btn_procesar_excel').disabled = "";
                document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
                document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';
                notify_warning("No existen datos, verificar campos");
            }
        }
        showData();
    }

    

 


    return (
        <div>
            <div className="card-action-title h4">Ventas</div>
            <div className="card card-action mb-4">
                <div className="card-header">
                     <div className="card-action-title">Filtrar Por:</div>
                   
                </div>
                <div className="card-body">
                    <div className="row ">
                        <div className="col-md-2">Periodo:</div>
                        <div className="col-md-2">
                            <DatePicker
                                locale="es"
                                className="form-control form-control-sm text-center"
                                selected={inp_text_peri_mes}
                                onChange={date => setInp_text_peri_mes(date)}
                                dateFormat="yyyy-MM"
                                showMonthYearPicker
                            />
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-2">Sucursal:</div>
                        <div className="col-md-2">
                            <select className="form-select form-select-sm" value={sl_t_suc} onChange={changeSl_t_suc}  >
                                {
                                    dtUser.sl_dt_suc?.map((item, index) => {
                                        return <option value={item?.suc_cod} key={index} >{item?.suc_nombre}</option>
                                    })
                                }
                            </select>
                        </div>
                        
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-2">Lineas:</div>
                        <div className="col-md-4">
                            <select className="form-select form-select-sm" value={sl_t_lineas} onChange={changeSl_lineas}  >
                                {
                                    dtUser.li_prov?.map((item, index) => {
                                        return <option value={item?.Code} key={index} >{item?.Code+'-'+item?.Name}</option>
                                    })
                                }
                            </select>
                        </div>

                      

                        <div className="col-md-2">C.Vendedor:</div>
                        <div className="col-md-2">
                            <Tooltip title="Vendedores" placement="top">
                                <Box>
                                    <SelectTipo id="slc_vendedor" url={url1+'?txt_per='+inp_text_peri_mes+'&txt_suc='+sl_t_suc+'&txt_lin='+sl_t_lineas} value={sl_t_ven} onChange={changeSl_t_ven} />
                                </Box>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-4 "></div>
                        <div className="col-md-4 text-center ">
                            {/* <Tooltip title="Consultar información" placement="top">
                                <button className="btn btn-primary btn-sm" id="btn_procesar" onClick={onClickConsultar} >
                                    <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                                    <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                                    &nbsp;Consultar
                                </button>
                            </Tooltip> */}
                            {/* <Tooltip title="Exportar Excel" placement="top">
                                <button className="btn btn-success btn-sm" id="btn_procesar_excel" onClick={onClickExcel} >
                                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                                    &nbsp;Exportar Excel
                                </button>
                            </Tooltip> */}
                            {/* <Tooltip title="Resetar Campos" placement="top">
                                <button className="btn btn-dark btn-sm" id="btn_reset" onClick={onClickReset} >
                                    <i className='bx bx-reset bx-ms' id="btn_procesar_icon"></i>
                                </button>
                            </Tooltip> */}
                            <div className="btn-group" role="group">
                            <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                            <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                            &nbsp;Exportar
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                            <a className="dropdown-item bx-ms" onClick={onClickCSV} href="javascript:void(0);">CSV</a>
                            <a className="dropdown-item bx-ms" onClick={onClickExcel} href="javascript:void(0);">EXCEL</a>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card card-action mb-4 d-none">
                <div className="card-header">
                    <div className="card-action-title">Lista Clientes</div>
                    <div className="card-action-element">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                                <a href="javascript:void(0);" className="card-expand d-none" ><i className="tf-icons bx bx-fullscreen"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card-body">
                    <div className="card-datatable table-responsive">
                        <DataTableExtensions columns={users.columns} data={users.data2} >
                            <DataTable
                                Header
                                defaultSortField="Sucursal"
                                defaultSortAsc={true}
                                pagination
                                responsive
                                highlightOnHover
                                progressPending={pending}
                            />
                        </DataTableExtensions>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default MPventas