import React, {useState, useEffect} from 'react'
import {BarChart4, BarChart5} from '../Charts/BarChart'
import Cookies from 'universal-cookie';
import { notify_warning, notify_valido, selectTabla } from '../../components/utils';
import $ from 'jquery'
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions'
import Swal from 'sweetalert2';
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SelectTipo } from '../../components/Selects/SelectsPrincipales'
import img_pdf from '../../assets/img/icons/misc/pdf.png';
import AsyncSelect from 'react-select/async';
const moment = require('moment')
const cookies = new Cookies();
const dtUser = cookies.get('dtUser');

const styles = {
  control: (provided, state) => ({
      ...provided,
      background: `#fff`,
      borderColor: `#D2D5DC`,
      minHeight: `29px`,
      height: `29px`,
      boxShadow: state.isFocused ? null : null,
      border: '1px solid #D2D5DC',

    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: `29px`,
      padding: `0 6px`,
      color: `#697a8d`,
    }),

    input: (provided, state) => ({
      ...provided,
      color: `#697a8d`,
      fontSize: `0.75rem`,
      
    }),
    indicatorSeparator: state => ({
      display: `none`,
      
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: `29px`,
      
    }),
};
export const ModalsDetVen = ({dtPorLineaVe}) => {
  var  periodo = moment(new Date()).subtract(1, 'days').format('DD-MM-YYYY');
  return (
    <div className="modal fade" id="exLargeModal" data-bs-backdrop="static" tabIndex="-1" aria-hidden="true" >
          <div className="modal-dialog modal-xl " role="document">
              <div className="modal-content" >
                  <div className="modal-header ">
                      <h5 className="modal-title " id="exampleModalLabel1">Avance Ventas del Vendedor {dtPorLineaVe.CodigoVendedor} al {periodo}  </h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <div className='row'>
                      <div className='col-lg-6'>
                        <BarChart5 dtPorLineaVe={dtPorLineaVe} />
                      </div>
                      <div className='col-lg-6'>
                        <BarChart4 dtPorLineaVe={dtPorLineaVe} />
                      </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  )
}

export const ModalsSubPartidas = () => {
  
  const [lst, setlst] = useState([]);
  const [pending, setpending] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const [txt_codigo, setTxt_codigo] = useState('');
  const [txt_detalle, setTxt_detalle] = useState('');
  
  const changetxt_codigo = (event) => { setTxt_codigo(event.target.value); }
  const changetxt_detalle = (event) => { setTxt_detalle(event.target.value); }

  const ins_url = '/gm-cts-sp-insert'
  const lst_url = '/gm-cts-sp-lst'
  const upd_url = '/gm-cts-sp-update'
  const del_url = '/gm-cts-sp-delete'
  
  const lst_columns = [
    { cell:(row) =>
      <div className="align-items-center">
          <button type="button" className="btn btn-sm btn-icon btn-label-danger" onClick={onClickDelete}  id={JSON.stringify(row)}><i id={JSON.stringify(row)} className='bx bx-x-circle bx-ms'></i></button>
      </div>
      ,ignoreRowClick: true, allowOverflow: true, button: true, name: 'OPCIÓN' },
    { name: 'CODIGO',  selector: row => row.spa_cod,         cellExport: row => row.spa_cod,         sortable: true, width: "7rem" },
    { name: 'DETALLE', selector: row => row.spa_descripcion, cellExport: row => row.spa_descripcion, sortable: true },
  ];

  const onClickGuardar = async() => {
    var txt_cod = $(".modal_cod_partida").text();
    const body = { txt_cod: txt_cod, txt_codigo: txt_codigo, txt_detalle: txt_detalle, txt_usuid: cookies.get('dtUser_id') };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    console.log('txt_cod -> ',txt_cod)
    $("#btn_sp_guardar").attr("disabled",true)
    $("#icon_btn_sp_guardar").addClass("d-none")
    $("#spinner_btn_sp_guardar").removeClass("d-none")
    if( txt_codigo === "" || txt_detalle === "" ){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      const response = await fetch(ins_url,opPe);
      const resdatos = await response.json();
      if (resdatos.vl_existe === 1){
        notify_warning("CODIGO YA EXISTE!!!")
      }
      if (resdatos.vl_existe === 0 && resdatos.vl_insert === 1 ){
        notify_valido("CODIGO REGISTRO CORRECTAMENTE!!!")
        onClickClearInputs();
        if($(".rdt_Table").hasClass("table")){
          onClickListar();
        }
      }      
    }
    $("#btn_sp_guardar").attr("disabled",false)
    $("#icon_btn_sp_guardar").removeClass("d-none")
    $("#spinner_btn_sp_guardar").addClass("d-none")
  }
  const onClickListar = async() => {
    var txt_cod = $(".modal_cod_partida").text();
    const body = { txt_cod: txt_cod };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const columns = lst_columns
    setpending(true)
    const response = await fetch(lst_url,opPe);
    const data = await response.json();
    //console.log(data);
    const tableData = { columns, data };
    setlst(tableData)
    setpending(false)
    $(".tabla-subpartida .rdt_Table").addClass("table");
  }
  const onClickEditInputs = async() => {
    setdisabled(false)
    $("#btn_sp_editar").addClass('d-none')
    $("#btn_sp_gmodificado").removeClass('d-none')
    setTimeout(()=>{ $("#txt_sp_codigo").attr('disabled',true) },500)
  }
  const onClickGModifcado = async() => {
    const body = { txt_codigo: txt_codigo, txt_detalle: txt_detalle, txt_usuid: cookies.get('dtUser_id') };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    $("#btn_sp_gmodificado").attr("disabled",true)
    $("#icon_btn_sp_gmodificado").addClass("d-none")
    $("#spinner_btn_sp_gmodificado").removeClass("d-none")
    if( txt_codigo === "" || txt_detalle === "" ){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      const response = await fetch(upd_url,opPe);
      const resdatos = await response.json();
      //console.log("modifi", resdatos);
      if (resdatos.vl_insert === 0){
        notify_warning("ERROR AL MODIFCAR, VERIFICAR DATOS!!!")
      }else if (resdatos.vl_insert === 1 ){
        onClickClearInputs()
        onClickListar()
        notify_valido("SE MODIFICO CORRECTAMENTE!!!")
      }
    }
    $("#btn_sp_gmodificado").attr("disabled",false)
    $("#icon_btn_sp_gmodificado").removeClass("d-none")
    $("#spinner_btn_sp_gmodificado").addClass("d-none")
  }
  const onClickClearInputs = async() => {
    setTxt_codigo("")
    setTxt_detalle("")
    $('.rdt_TableRow').removeClass("table-danger");
    $("#btn_sp_guardar").removeClass('d-none')
    $("#btn_sp_editar").addClass('d-none')
    $("#btn_sp_gmodificado").addClass('d-none')
    setdisabled(false)
    $("#txt_sp_codigo").attr('disabled',false)
    $("#btn_sp_mlista").removeClass('d-none')
  }
  const onClickDelete = async(state) => {
    try {
      const dtLlegada = await JSON.parse(state.target.id);
      const codigo = dtLlegada.spa_cod
      const del_body = { txt_codigo: codigo, txt_usuid: cookies.get('dtUser_id') };
      const del_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(del_body) }
    
      Swal.fire({
        title: 'Advertencia',
        text: '¿Esta seguro de eliminar este registro?',
        icon: 'error',
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si",
        confirmButtonColor: "#696cff",
        customClass: {
          container: 'my-swal'
        }
        
      }).then(async response =>  {
        if (response.isConfirmed) {
          const respondt = await fetch(del_url,del_opPe);
          const resdatos = await respondt.json();
          console.log("resdatos",resdatos);
          if (resdatos.vl_insert === 0){
            notify_warning("ERROR AL ELIMINAR!!!")
          }else if (resdatos.vl_insert === 1 ){
            notify_valido("USUARIO SE ELIMINO CORRECTAMENTE!!!")
            Swal.fire({title:'EXITO', text:'USUARIO SE ELIMINO CORRECTAMENTE!!!', icon:'success',showConfirmButton:false, timer: 1000})
            onClickListar()
            onClickClearInputs()
          }
        }else if(response.isDenied){
          Swal.close();
        }
      })
      
    } catch (error) {
      console.log(error);
    }
  }
  
  const handleRowClicked = (row) => {
    setTxt_codigo(row.spa_cod)
    setTxt_detalle(row.spa_descripcion)
    selectTabla("tabla-subpartida")
    $("#btn_sp_guardar").addClass('d-none')
    $("#btn_sp_editar").removeClass('d-none')
    setdisabled(true)
    $("#btn_sp_gmodificado").addClass('d-none')
    $("#btn_sp_mlista").addClass('d-none')
  }
  // const selectTabla = () => {
  //   $('.rdt_TableBody').on('click', '.rdt_TableCell', function () {
  //     $('.rdt_TableRow').removeClass("table-danger");
  //     $($(this).parents(".rdt_TableRow")).addClass("table-danger");       
  //   })
  // };

  return(
  <div className="modal fade" id="modalCenter" data-bs-backdrop="static" tabIndex="-1" aria-hidden="true" >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="modalCenterTitle">Partida <strong className="modal_cod_partida"></strong> - <strong className="modal_des_partida"></strong></h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={e => {setlst([])}}></button>
        </div>
        <div className="modal-body">
          <div className="row mb-2">
            <div className="col-md-4">
              <label>Codigo:</label>
              <input type="text" id="txt_sp_codigo" name="txt_sp_codigo" className="form-control form-control-sm"  autoComplete='off' value={txt_codigo} onChange={changetxt_codigo} disabled={disabled}/>
            </div>
            <div className="col-md-8">
              <label>Descripción:</label>
              <input type="text" id="txt_sp_detalle" name="txt_sp_detalle" className="form-control form-control-sm"  autoComplete='off' value={txt_detalle} onChange={changetxt_detalle} disabled={disabled}/>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12 text-center ">
              <button className="btn btn-primary btn-sm" id="btn_sp_guardar" onClick={onClickGuardar} >
                <i className='bx bx-save' id="icon_btn_sp_guardar" ></i>
                <span className="spinner-border d-none" id="spinner_btn_sp_guardar" role="status" aria-hidden="true"></span>
                &nbsp;Guardar
              </button>
              <button className="btn btn-warning btn-sm d-none" id="btn_sp_editar" onClick={onClickEditInputs}>
                <i className='bx bx-edit' ></i>&nbsp;Editar
              </button>
              <button className="btn btn-success btn-sm d-none" id="btn_sp_gmodificado" onClick={onClickGModifcado} >
                <i className='bx bx-send' id="icon_btn_sp_gmodificado" ></i>
                <span className="spinner-border d-none" id="spinner_btn_sp_gmodificado" role="status" aria-hidden="true"></span>
                &nbsp;GModificado
              </button>
              <button className="btn btn-dark btn-sm" id="btn_sp_cancelar" onClick={onClickClearInputs}>
                <i className='bx bx-history' ></i>&nbsp;Cancelar
              </button>
              <button className="btn btn-info btn-sm" id="btn_sp_mlista" onClick={onClickListar} >
                <i className='bx bx-list-ul' ></i>&nbsp;MLista
              </button>
            </div>
          </div>
          <div className="card-datatable table-responsive ">
            <DataTableExtensions  {...lst} >
              <DataTable className="tabla-subpartida"
                  Header
                  defaultSortField="id"
                  defaultSortAsc={true}
                  pagination
                  responsive
                  highlightOnHover
                  progressPending={pending}
                  noContextMenu="No hay registros para mostrar"
                  onRowClicked={handleRowClicked}
                  //conditionalRowStyles={conditionalRowStyles}
              />
            </DataTableExtensions>
          </div>
        </div>
        <div className="modal-footer">
        </div>
      </div>
    </div>
  </div>
  )
}

var vtoday = new Date();
export const  ModalDetallado = ({dataLlegada,dataldt,pending2}) => {
  
  //console.log(pending2);
  //console.log('dataldt', dataldt);url_dtigdt-update
  const url_tga = '/get-data-paridas'
  const url_con = '/post-data-subpartidas'
  const url_est = '/get-data-estados'
  const url_jor = '/get-data-jornal'
  const url_emp = '/post-data-empleados'


  const url_dti = '/gdt-insert'
  const url_dtu = '/gdt-update'
  const url_lst = '/gdt-lst'
  const url_dta = '/gdt-delete'
 // let sssss = dataldt
 // const redsd = pending2
  //console.log(redsd);
  //var fecha_actual = new Date()
  //const [txt_vfactual, seTfecha_actual] = useState(new Date());
  const [lst, setlst] = useState([]);
  const [pending, setpending] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const [moEstado, setMoEstado] = useState(false);
  const [disabledUni, setdisabledUni] = useState('');
  const [txt_perfec, setTxt_perfec] = useState(new Date());
  const [slc_t_tga, setSlc_t_tga] = useState('');
  const [slc_t_con, setSlc_t_con] = useState('');
  const [slc_t_jor, setSlc_t_jor] = useState('');
  const [slc_t_emp, setslc_t_emp] = useState([""]);
  const [slc_conceptos, setSlc_conceptos] = useState([]);
  const [txt_monto, setTxt_monto] = useState('');
  const [txt_nrefe, setTxt_nrefe] = useState('');
  const [txt_obs, setTxt_obs] = useState('');
  const [slc_t_est, setSlc_t_est] = useState('E004');
  const [selectedFile, setSelectedFile] = useState([]);

  const changeSlc_t_tga = (event) => { 
    setSlc_t_tga(event.target.value);
    fc_lstConceptos(event.target.value)

    if(event.target.value === 'P007'){
      $(".div-resto").addClass("d-none")
      $(".div-panilla").removeClass("d-none")
    }else{
      $(".div-panilla").addClass("d-none")
      $(".div-resto").removeClass("d-none")
    }

    //alert(event.target.value)

  }
  const changeSlc_t_con = (event) => { setSlc_t_con(event.target.value); }
  const changeSlc_t_jor = (event) => { setSlc_t_jor(event.target.value); }
  const fc_lstConceptos= async (r_cod) => {
      //const url_ven = '/post-data-subpartidas'
      let body = { txt_cod: r_cod };
      const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
      const response = await fetch(url_con,opPe);
      const data = await response.json();
      setSlc_conceptos(data)
  }
  const changeTxt_monto = (event) => { setTxt_monto(event.target.value); }
  const changeTxt_nrefe = (event) => { setTxt_nrefe(event.target.value); }
  const changeTxt_obs = (event) => { setTxt_obs(event.target.value); }
  const changeSlc_t_est = (event) => { setSlc_t_est(event.target.value); }

  const changeFile = (event) => { setSelectedFile(event.target.files[0]); }

  const body = { slc_t_suc: dataLlegada.slc_t_suc, txt_periodo: dataLlegada.txt_periodo, slc_t_sem: dataLlegada.slc_t_sem,
    txt_perfec: txt_perfec,
    slc_t_tga: slc_t_tga,
    slc_t_con: slc_t_con,
    slc_t_jor: slc_t_jor,
    slc_t_emp: slc_t_emp,
    txt_monto: txt_monto,
    txt_nrefe: txt_nrefe,
    txt_obs: txt_obs,
    slc_t_est: slc_t_est, txt_usuid: cookies.get('dtUser_id'), 
  };

  //const opPe = { method: "POST", headers: {'Content-Type': 'multipart/form-data'}, body: JSON.stringify(body) };
  const lst_columns2 = [
    { name: 'ID',         selector: row => row.gpa_id,           cellExport: row => row.gpa_id,          sortable: true, omit: true },
    { name: 'SubPartidad',selector: row => row.spa_cod,          cellExport: row => row.spa_cod,         sortable: true, omit: true },
    { name: 'Concepto',   selector: row => row.spa_descripcion,  cellExport: row => row.spa_descripcion, sortable: true },
    { name: 'Monto',      selector: row => row.gpa_monto,        cellExport: row => row.gpa_monto,       sortable: true },
    { name: 'est_cod',    selector: row => row.est_cod,          cellExport: row => row.est_cod,         sortable: true, omit: true },
    { name: 'Estado',     selector: row => row.est_descripcion,  cellExport: row => row.est_descripcion, sortable: true },
    { cell:(row) =>
      <div className="align-items-center">
        { row.gpa_marchivo === ""? 
          <p className='d-none'>nimg</p>
          :
          <a href={"/archivos/"+row.gpa_marchivo} target="_blank" rel="noreferrer">
            {row.gpa_marchivo.split('.')[1] === 'PDF' ?
              <img src={img_pdf} alt="PDF imagess" width="18" className="me-2"></img>
              :
              <i className='bx bxs-file-image bx-ms me-2'></i>
            }
          </a>
        }
        <button type="button" className="btn btn-sm btn-icon btn-label-danger" onClick={onClickDelete}  id={JSON.stringify(row)}><i id={JSON.stringify(row)} className='bx bx-x-circle bx-ms'></i></button>
      </div>
      ,ignoreRowClick: true, allowOverflow: true, button: true, name: 'OPCIÓN' },
    { name: 'est_des',    selector: row => row.gpa_fecha,       cellExport: row => row.gpa_fecha, sortable: true, omit: true },
    { name: 'est_des',    selector: row => row.par_cod,         cellExport: row => row.par_cod, sortable: true, omit: true },
    { name: 'est_des',    selector: row => row.gpa_mreferencia, cellExport: row => row.gpa_mreferencia, sortable: true, omit: true },
    { name: 'est_des',    selector: row => row.gpa_observacion, cellExport: row => row.gpa_observacion, sortable: true, omit: true },
    { name: 'est_des',    selector: row => row.tjo_cod, cellExport: row => row.tjo_cod, sortable: true, omit: true },
    { name: 'est_des',    selector: row => row.emp_cod, cellExport: row => row.emp_cod, sortable: true, omit: true },
    { name: 'est_des',    selector: row => row.emp_detalle, cellExport: row => row.emp_detalle, sortable: true, omit: true },

  ]

  const onClickGuardar = async() => {
    $(".tabla-detalle .rdt_Table").addClass("table");
    $("#btn_guardar").attr("disabled",true)
    $("#icon_btn_guardar").addClass("d-none")
    $("#spinner_btn_guardar").removeClass("d-none")

    const txt_diaini = $(".modal_txt_diaini").text()
    const txt_diafin = $(".modal_txt_diafin").text()
    const vlidarfecha = moment(txt_perfec).format('YYYY-MM-DD')
    const nplaca = $(".modal_cod_placa").text()
    switch (slc_t_tga) {
      case 'P007':
        if (txt_perfec === "" || slc_t_tga === "" || txt_monto === "" ){
          notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
        }else{
          if (txt_diaini <= vlidarfecha && vlidarfecha <= txt_diafin) {

            const formData = new FormData();
            formData.append("imagen", selectedFile);
            formData.append("data", JSON.stringify(body));
            formData.append("nplaca", nplaca);
    
            //console.log("f",f);
            const opPe22 = { method: "POST", headers: {}, body: formData}
            const response = await fetch(url_dti,opPe22);
            const resdatos = await response.json();
            if ( resdatos.vl_insert === 1 ){
              notify_valido("SE REGISTRO CORRECTAMENTE!!!")
              onClickClear();
              onClickListar();
              if ( resdatos.vl_insert2 === 1 ){
                PintarTablaSelect(5,"ABIERTO")
                PintarTablaSelect(7,"ABIERTO")
              }
            }else{ notify_warning("ERROR AL GUARDAR, VERIFICAR!!!") }
            PintarTablaSelect(2,resdatos.vl_smonto)
          }else{
            notify_warning("ESTA FUERA DE RANGO, VERIFICAR!!!")
          }
        }
        break;
    
      default:
        var fecha_actual = moment(vtoday).format('YYYY-MM-DD')
        if (txt_perfec === "" || slc_t_tga === "" || txt_monto === ""  || slc_t_est === "" || selectedFile === undefined || selectedFile.length === 0) {
          notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
        }else{
          if (txt_diaini <= vlidarfecha && vlidarfecha <= txt_diafin) {
            //if ( fecha_actual <= vlidarfecha && vlidarfecha <= fecha_actual ) {
              const formData = new FormData();
              formData.append("imagen", selectedFile);
              formData.append("data", JSON.stringify(body));
              formData.append("nplaca", nplaca);
      
              //console.log("f",f);
              const opPe22 = { method: "POST", headers: {}, body: formData}
              const response = await fetch(url_dti,opPe22);
              const resdatos = await response.json();
              if ( resdatos.vl_insert === 1 ){
                notify_valido("SE REGISTRO CORRECTAMENTE!!!")
                onClickClear();
                onClickListar();
                if ( resdatos.vl_insert2 === 1 ){
                  PintarTablaSelect(5,"ABIERTO")
                  PintarTablaSelect(7,"ABIERTO")
                }
              }else{ notify_warning("ERROR AL GUARDAR, VERIFICAR!!!") }
              PintarTablaSelect(2,resdatos.vl_smonto)
            //} else { notify_warning("NO PUEDES REGISTRAR, YA ESTAMOS "+fecha_actual)}
           }else{ notify_warning("ESTA FUERA DE RANGO, VERIFICAR!!!") }
        }


        break;
    }
    $("#btn_guardar").attr("disabled",false)
    $("#icon_btn_guardar").removeClass("d-none")
    $("#spinner_btn_guardar").addClass("d-none")
  }
  const onClickClear = () => {
    //PintarTablaSelect()
    $("#txt_id").val("")
    setSlc_t_tga("")
    setSlc_t_con("")
    setSlc_t_jor("")
    setslc_t_emp([""])
    setTxt_monto("")
    setTxt_nrefe("")
    setTxt_obs("")
    setSlc_t_est("E004")
    setSelectedFile("")
    document.getElementById('formFile').value = null
    setdisabled(false)
    $('.tabla-detalle .table .rdt_TableRow').removeClass("table-danger");
    $("#btn_guardar").removeClass('d-none')
    $("#btn_editar").addClass('d-none')
    $("#btn_gmodificado").addClass('d-none')
    $("#formFile").removeClass("d-none")
    $("#formFile2").addClass("d-none")
    $("#formFile2").val("")
    fc_lstConceptos("")
    setdisabledUni('')
   
    $(".div-panilla").addClass("d-none")
    $(".div-resto").removeClass("d-none")
    
  };
  const onClickListar = async() => {
    
    const mun_placa = $(".modal_cod_placa").text()
    body['txt_nplaca'] = mun_placa
    setpending(true)
    let columns = lst_columns2
    const opPe2 = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response2 = await fetch(url_lst,opPe2);
    const data = await response2.json();
    const tableData = { columns, data };
    //console.log('data2', data2);
    setlst(tableData)
    setpending(false)
    //onClickClear()
  }
  const onClickDelete = async(state) => {
    try {
      const mun_placa = $(".modal_cod_placa").text()
      const dtLlegada = await JSON.parse(state.target.id);
      const codigo = dtLlegada.gpa_id
      const del_body = { txt_periodo:dataLlegada.txt_periodo, slc_t_suc:dataLlegada.slc_t_suc, slc_t_sem:dataLlegada.slc_t_sem, txt_nplaca:mun_placa, txt_codigo: codigo, txt_usuid: cookies.get('dtUser_id') };
      const del_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(del_body) }
    
      Swal.fire({
        title: 'Advertencia',
        text: '¿Esta seguro de eliminar este registro?',
        icon: 'error',
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si",
        confirmButtonColor: "#696cff",
        customClass: {
          container: 'my-swal'
        }
        
      }).then(async response =>  {
        if (response.isConfirmed) {
          const respondt = await fetch(url_dta,del_opPe);
          const resdatos = await respondt.json();
          console.log("resdatos",resdatos);
          if (resdatos.vl_insert === 0){
            notify_warning("ERROR AL ELIMINAR!!!")
          }else if (resdatos.vl_insert === 1 ){
            notify_valido("USUARIO SE ELIMINO CORRECTAMENTE!!!")
            Swal.fire({title:'EXITO', text:'USUARIO SE ELIMINO CORRECTAMENTE!!!', icon:'success',showConfirmButton:false, timer: 1000})
            onClickListar()
            onClickClear()
          }
          PintarTablaSelect(2,resdatos.vl_smonto)
        }else if(response.isDenied){
          Swal.close();
        }
      })
    } catch (error) {
      console.log(error);
    }
  }
  const handleRowClicked = (row) => {

    selectTabla("tabla-detalle")
    setdisabled(true)
    $("#txt_id").val(row.gpa_id);
    setTxt_perfec(new Date(moment(row.gpa_fecha, 'Y-M-D').format('YYYY-MM-DD HH:mm:ss')))
    setSlc_t_tga(row.par_cod)
    fc_lstConceptos(row.par_cod)
    setSlc_t_con(row.spa_cod)
    setSlc_t_jor(row.tjo_cod)
    setslc_t_emp({'codigo':row.emp_cod,'detalle': row.emp_cod+'-'+row.emp_detalle})
    setTxt_monto(row.gpa_monto)
    setTxt_nrefe(row.gpa_mreferencia)
    setTxt_obs(row.gpa_observacion)
    setSlc_t_est(row.est_cod)
    $("#formFile").addClass("d-none")
    $("#formFile2").removeClass("d-none")
    $("#formFile2").val(row.gpa_marchivo)
    $("#btn_guardar").addClass("d-none")
    $("#btn_gmodificado").addClass("d-none")
    $("#btn_editar").removeClass("d-none")

    if (row.est_descripcion === 'ABIERTO') {
      setMoEstado(false)
    }else{
      setMoEstado(true)
    }

    if(row.par_cod === 'P007'){
      $(".div-resto").addClass("d-none")
      $(".div-panilla").removeClass("d-none")
    }else{
      $(".div-panilla").addClass("d-none")
      $(".div-resto").removeClass("d-none")
    }
  }

  const PintarTablaSelect = (num,text) => {
    var iddato =  $('.tabla-resumen .table .table-danger #cell-'+num+'-undefined')
    iddato.find('div').text(text)
  };
  const onClickEditInputs = async() => {
    //setdisabled(false)
    
    $("#btn_editar").addClass('d-none')
    $("#btn_gmodificado").removeClass('d-none')
    
    if (moEstado === true) {
      setdisabled(true)
      $("#formFile").addClass("d-none")
      $("#formFile2").removeClass("d-none")
    }else{
      setdisabled(false)
      $("#formFile").removeClass("d-none")
      $("#formFile2").addClass("d-none")
    }
    setdisabledUni(true)

    if (dtUser.tpu_id !== 9) {
      $("#slc_t_est").attr("disabled", false)
      $("#btn_gmodificado").removeAttr("disabled")
      setMoEstado(false)
    }

  }
  const onClickGModifcado = async() => {
    //alert("hola modi")
    $(".tabla-detalle .rdt_Table").addClass("table");
    $("#btn_gmodificado").attr("disabled",true)
    $("#icon_btn_gmodificado").addClass("d-none")
    $("#spinner_btn_gmodificado").removeClass("d-none")
    if( txt_perfec === "" || slc_t_tga === "" || txt_monto === "" || txt_nrefe === "" || slc_t_est === ""){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      const txt_diaini = $(".modal_txt_diaini").text()
      const txt_diafin = $(".modal_txt_diafin").text()
      const vlidarfecha = moment(txt_perfec).format('YYYY-MM-DD')

      if (txt_diaini <= vlidarfecha && vlidarfecha <= txt_diafin) {
        //$("#txt_id").val(row.gpa_id);
        var txt_id = $("#txt_id").val()
        var nplaca = $(".modal_cod_placa").text()
        body["txt_id"] = txt_id;

        const formData = new FormData();
        formData.append("imagen", selectedFile);
        formData.append("data", JSON.stringify(body));
        formData.append("nplaca", nplaca);
        
        //console.log("f",f);
        const opPe22 = { method: "POST", headers: {}, body: formData}
        const response = await fetch(url_dtu,opPe22);
        const resdatos = await response.json();
        if ( resdatos.vl_insert === 1 ){
          notify_valido("SE REGISTRO CORRECTAMENTE!!!")
          onClickClear();
          onClickListar();
        }else{ notify_warning("ERROR AL GUARDAR, VERIFICAR!!!") }
        PintarTablaSelect(2,resdatos.vl_smonto)
      }else{
        notify_warning("ESTA FUERA DE RANGO, VERIFICAR!!!")
      }

    }

    $("#btn_gmodificado").attr("disabled",false)
    $("#icon_btn_gmodificado").removeClass("d-none")
    $("#spinner_btn_gmodificado").addClass("d-none")
  }


  const fc_getOptionsSelect = async (inputValue) => {
    
    let body = { slc_t_suc: dataLlegada.slc_t_suc, inp_text: inputValue };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(url_emp,opPe);
    const data = await response.json();
    return data;
  }

  

  useEffect(() => {
    
    setpending(pending2)
    setlst(dataldt)
    if (dtUser.tpu_id === 9) {
      $("#slc_t_est option[value='E001']").addClass("d-none");
      $("#slc_t_est option[value='E002']").addClass("d-none");
      $("#slc_t_est option[value='E003']").addClass("d-none");
    }
    // else{
    //   setSlc_t_est("")
    //   $("#slc_t_est option[value='E004']").addClass("d-none");
    // }
    
  },[pending2, dataldt])

  // useEffect(() => {
  //   onClickListar()
  // },[])
  
  //
  return(
    <div className="modal fade" id="modalCenter" data-bs-backdrop="static" tabIndex="-1" aria-hidden="true" >
      <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalCenterTitle">Detalle Rendición de Gastos - PLACA: <strong className="modal_cod_placa text-danger"></strong> desde <strong className="modal_des_semana text-danger"></strong> </h5>
            <strong className="modal_txt_diaini d-none"></strong>
            <strong className="modal_txt_diafin d-none"></strong>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClickClear} ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-2">Fecha:</div>
              <div className="col-md-2">
                <input type="number" id="txt_id" name="txt_id" className="form-control form-control-sm text-center d-none" autoComplete='off' disabled="disabled"/>
                <DatePicker
                  locale="es"
                  className="form-control form-control-sm text-center txt_perfec"
                  selected={txt_perfec} 
                  onChange={date => setTxt_perfec(date)} 
                  dateFormat="yyyy-MM-dd"
                  disabled={(disabledUni === '' )? disabled : disabledUni}
                />
              </div>
              <div className="col-md-2">Tipo de Gasto:</div>
              <div className="col-md-2">
                <SelectTipo id="slc_t_tga" url={url_tga} value={slc_t_tga} onChange={changeSlc_t_tga} disabled={(disabledUni === '' )? disabled : disabledUni}/>
              </div>
              <div className="col-md-2">Concepto:</div>
              <div className="col-md-2">
                {/* <SelectTipo id="slc_tgaanas" url={url_con+'?txt_cod='+slc_t_tga} value={slc_t_con} onChange={changeSlc_t_con} /> */}
                <select id="slc_t_con" className="form-select form-select-sm"  value={slc_t_con} onChange={changeSlc_t_con} disabled={disabled}>
                  <option value={"9999"} >--Seleccione--</option>
                  {   
                    slc_conceptos?.map((item,index) => {
                      return <option value={item?.cot_cod} key={index} >{item?.cot_descri1}</option>
                    })
                  }
                </select>
              </div>
            </div>
            <div className="row div-panilla d-none">
              <div className="col-md-2">Tipo de Jornal:</div>
              <div className="col-md-2">
                <SelectTipo id="slc_t_tga" url={url_jor} value={slc_t_jor} onChange={changeSlc_t_jor} disabled={disabled}/>
              </div>
              <div className="col-md-2">Empleado:</div>
              <div className="col-md-6 ">
                {/** from-control form-control-sm select2 form-select form-select-lg */}
                <AsyncSelect
                  styles={styles}
                  placeholder="Busque cliente por codigo o descripción"
                  name="form-field-name"
                  className="sele_pru from-control form-control-sm"  
                  value={slc_t_emp}
                  cacheOptions
                  defaultOptions={false}
                  getOptionLabel={e => e.detalle}
                  getOptionValue={e => e.codigo}
                  loadOptions={fc_getOptionsSelect}
                  onChange={setslc_t_emp}
                  isClearable={true}
                  openMenuOnClick={false}
                  isDisabled={disabled}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-2 div-resto">NReferencia:</div>
              <div className="col-lg-2 div-resto">
                <input type="text" id="txt_nrefe" name="txt_nrefe" className="form-control form-control-sm text-center" autoComplete='off' value={txt_nrefe} onChange={changeTxt_nrefe} disabled={disabled}/>
              </div>
              <div className="col-lg-4 div-panilla d-none"></div>
              <div className="col-lg-2">Monto:</div>
              <div className="col-lg-2">
                <input type="number" id="txt_monto" name="txt_monto" className="form-control form-control-sm text-center" autoComplete='off' value={txt_monto} onChange={changeTxt_monto} disabled={disabled}/>
              </div>
              
              <div className="col-lg-4 div-resto">
                <input type="file" id="formFile" name="formFile" accept='.jpg,.jpeg,.png,.pdf,.xlsx'  className="form-control form-control-sm"  onChange={changeFile} />
                <input type="text" id="formFile2" name="formFile"  className="form-control form-control-sm d-none" disabled="disabled" />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-lg-2">Observación:</div>
              <div className="col-lg-6">
                <input type="text" id="txt_obs" name="txt_obs" className="form-control form-control-sm" autoComplete='off' value={txt_obs} onChange={changeTxt_obs} disabled={disabled}/>
              </div>
              <div className="col-md-2">Estado:</div>
              <div className="col-md-2">
                <SelectTipo id="slc_t_est" url={url_est} value={slc_t_est} onChange={changeSlc_t_est} disabled={disabled}/>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8"></div>
              <div className="col-lg-4 text-end">
                <button className="btn btn-primary btn-sm" id="btn_guardar" onClick={onClickGuardar} >
                  <i className='bx bx-save' id="icon_btn_guardar" ></i>
                  <span className="spinner-border d-none" id="spinner_btn_guardar" role="status" aria-hidden="true"></span>
                  &nbsp;Guardar
                </button>
                <button className="btn btn-warning btn-sm d-none" id="btn_editar" onClick={onClickEditInputs}>
                  <i className='bx bx-edit' ></i>&nbsp;Editar
                </button>
                <button className="btn btn-success btn-sm d-none" id="btn_gmodificado" onClick={onClickGModifcado} disabled={moEstado}>
                  <i className='bx bx-send' id="icon_btn_gmodificado" ></i>
                  <span className="spinner-border d-none" id="spinner_btn_gmodificado" role="status" aria-hidden="true"></span>
                  &nbsp;GModificado
                </button>
                <button className="btn btn-dark btn-sm" id="btn_cancelar" onClick={onClickClear}>
                  <i className='bx bx-history' ></i>&nbsp;Cancelar
                </button>
                <button className="btn btn-info btn-sm" id="btn_mlista" onClick={onClickListar} >
                  <i className='bx bx-list-ul' ></i>&nbsp;MLista
                </button>
              </div>
            </div>
            <div className="card-datatable table-responsive ">
              <DataTableExtensions  {...lst} >
                <DataTable className="tabla-detalle"
                    Header
                    defaultSortField="id"
                    defaultSortAsc={true}
                    pagination
                    responsive
                    highlightOnHover
                    progressPending={pending}
                    noContextMenu="No hay registros para mostrar"
                    onRowClicked={handleRowClicked}
                    //conditionalRowStyles={conditionalRowStyles}
                />
              </DataTableExtensions>
            </div>
          </div>
          <div className="modal-footer">
          </div>
        </div>
      </div>
    </div>
    )
}

//export default ModalsDetVen