/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react'
import Cookies from 'universal-cookie';
import { ToastContainer } from 'react-toastify';
import { notify_warning } from '../components/utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip }from '@mui/material';
import 'react-data-table-component-extensions/dist/index.css';
import AsyncSelect from 'react-select/async';
//import '../App.css';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import $ from 'jquery'
import { SelectTipo } from '../components/Selects/SelectsPrincipales';
const moment = require('moment')
const MasterCuboVentas = () => {

  useEffect(() => {
    savePathInCookie();
  }, []);
  function savePathInCookie() {
    const path = window.location.pathname.substr(1); // Obtiene la parte después de la barra diagonal

    // Guarda el valor en una cookie con nombre "ultimapag"
    document.cookie = `ultimapag=${path}`;
  }

  const cookies = new Cookies();
  const dtUser = cookies.get('dtUser');
  const dtgrve = dtUser.ls_gr_ven
  const dtSucu = dtUser.sl_dt_suc[0].suc_nombre
  
  const [sl_superv, setsl_superv] = useState([]);
  const [sl_t_unid, setsl_t_unid] = useState([]);
  const [sl_proveedores, setsl_proveedores] = useState([]);
  /** Filtros inputs */
  const [sl_t_suc, setsl_t_suc] = useState(dtSucu);
  //const [sl_t_sup, setsl_t_sup] = useState(dtUser.idsupervisor);
  const [sl_t_sup, setsl_t_sup] = useState(dtUser.CodigoSupervisor);
  const [sl_t_pro, setsl_t_pro] = useState('');
  const [sl_t_mesas, setsl_mesas] = useState('');
  //const [sl_t_dah, setsl_t_dah] = useState('1-7');

  const changeSl_t_suc = (event) => { 
    setsl_t_suc(event.target.value); 
    fc_lstSuperv(event.target.value);
    fc_lstProv(event.target.value);
  }
  const changeSl_t_sup = (event) => { setsl_t_sup(event.target.value); }
  const changesl_t_pro = (event) => { setsl_t_pro(event.target.value); }
  const changeSetsl_mesas = (event) => { setsl_mesas(event.target.value); }
  //const changeSl_t_dah = (event) => { setsl_t_dah(event.target.value); }
  const [inp_text_fe_desde, setInp_text_fe_desde] = useState(new Date());

  const fc_lstSuperv = useCallback(async (r_suc) => {
    const url_prov = '/post-data-supervisores'
    let body = { sl_t_suc: r_suc, periodo: inp_text_fe_desde, };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(url_prov,opPe);
    const data = await response.json();
    setsl_superv(data)
  },[inp_text_fe_desde])
  
  const fc_lstProv = useCallback(async (r_suc) => {
    const url_prov = '/post-sl-proveedores'
    let body = { sl_t_suc: r_suc, periodo: inp_text_fe_desde, lst_graven: dtgrve, };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(url_prov,opPe);
    const data = await response.json();
    setsl_proveedores(data)
  },[inp_text_fe_desde, dtgrve])

  const fc_getOptionsSelect = async (inputValue) => {
    const url_ven = '/post-sl-unidadnegocio'
    let body = { sl_t_suc: sl_t_suc, inp_text: inputValue, lst_graven: dtgrve, };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(url_ven,opPe);
    const data = await response.json();
    return data;
  }

  const dtcodu = dtUser.usu_dni
  const [sl_t_grupoventa, setsl_t_grupoventa] = useState('9999');
  const changeSl_t_grupoventa = (event) => { setsl_t_grupoventa(event.target.value); }
  const [sl_grupoventa, setSl_grupoventa] = useState([]);
  const fc_lstGrupoVenta = useCallback(async (r_suc) => {
      const url_ven = '/post-data-grupoventas'
      let body = { usu_tipo: dtUser.tpu_id, sl_t_suc: r_suc, usu_dni: dtcodu };
      const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
      const response = await fetch(url_ven,opPe);
      const data = await response.json();
      setSl_grupoventa(data)
  },[dtcodu,dtUser.tpu_id])
  useEffect(() => {
      fc_lstGrupoVenta(sl_t_suc)
  },[fc_lstGrupoVenta,sl_t_suc])

  /** Inicio |> urls apis */
  const url0 = '/post-data-cubo';
  const url1 = '/api-sl-mesas';
  /** Fin |> urls apis */

  let body_t = { 
    idt_text: dtUser.tpu_id,
    fe_desde: inp_text_fe_desde,
    sl_t_suc: sl_t_suc,
    sl_t_sup: sl_t_sup,
    sl_t_pro: sl_t_pro,
    sl_t_uni: sl_t_unid,
    lst_graven: dtgrve,
    sl_t_grupoventa: sl_t_grupoventa,
    sl_t_mesas: sl_t_mesas
  };
  const opti_t = { method: "POST", headers: {'Content-Type': 'application/json'},compress: true, body: JSON.stringify(body_t) }

  




  //const [datares, setData] = useState([]);
  const onClickCSV = async () => {
    //console.log(sl_t_unid.desLinea);moment(inp_text_fe_desde).format('YYYY-MM')
    const fileType = "txt"
    const nameFileType = "CUBOFLEXDATA_"+moment(inp_text_fe_desde).format('YYYYMM')
    document.getElementById('btn_procesar_excel').disabled = "true";
    document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'd-none');
    document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border';
    const showData = async () => {
      const response = await fetch(url0,opti_t);
      const datadtos = await response.json();
      //console.log("--saas",datadtos);
      //setData(datadtos)
      if (datadtos !== '0' ) {
        for (var i = 0; i < datadtos.length; i++) { 
          let fecha = new Date(moment(datadtos[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          datadtos[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(datadtos);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.txt`);

        document.getElementById('btn_procesar_excel').disabled = "";
        document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
        document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';
      }else{
        notify_warning("No existen datos, verificar campos")
        document.getElementById('btn_procesar_excel').disabled = "";
        document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
        document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';
      }

      document.getElementById('btn_procesar_excel').disabled = "";
      document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
      document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';



    
    }
    showData();
  }
  const onClickExcel = async () => {
    //console.log(sl_t_unid.desLinea);
    const fileType = "xlsx"
    const nameFileType = "CUBOFLEXDATA_"+moment(inp_text_fe_desde).format('YYYYMM')
    document.getElementById('btn_procesar_excel').disabled = "true";
    document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'd-none');
    document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border';
    const showData = async () => {
      const response = await fetch(url0,opti_t);      
      const datadtos = await response.json();
      if (datadtos !== '0' ) {
        for (var i = 0; i < datadtos.length; i++) { 
            let fecha = new Date(moment(datadtos[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
            fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
            datadtos[i]["FechaEmision"] = fecha;
        };

        const ws = XLSX.utils.json_to_sheet(datadtos);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
        document.getElementById('btn_procesar_excel').disabled = "";
        document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
        document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';
      }else{
        notify_warning("No existen datos, verificar campos")
        document.getElementById('btn_procesar_excel').disabled = "";
        document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
        document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';
      }

      document.getElementById('btn_procesar_excel').disabled = "";
      document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
      document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';



    
    }
    showData();
  }
  const onClickCSVlNE = async () => {
    body_t['txt_idtipo'] = '1'
    const opti_t = { method: "POST", headers: {'Content-Type': 'application/json'},compress: true, body: JSON.stringify(body_t) }
    //console.log(sl_t_unid.desLinea);
    const fileType = "xlsx"
    const nameFileType = "CUBOFLEXDATA_"+moment(inp_text_fe_desde).format('YYYYMM')
    document.getElementById('btn_procesar2_excel').disabled = "true";
    document.getElementById('btn_procesar2_excel_icon').setAttribute('class', 'd-none');
    document.getElementById('btn_procesar2_excel_spinner').className = 'spinner-border';
    const showData = async () => {
      const response = await fetch(url0,opti_t);
      const datadtos = await response.json();
      //console.log("--saas",datadtos);
      //setData(datadtos)
      if (datadtos !== '0' ) {
        for (var i = 0; i < datadtos.length; i++) { 
          let fecha = new Date(moment(datadtos[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          datadtos[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(datadtos);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.csv`);

        document.getElementById('btn_procesar2_excel').disabled = "";
        document.getElementById('btn_procesar2_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
        document.getElementById('btn_procesar2_excel_spinner').className = 'spinner-border d-none';
      }else{
        notify_warning("No existen datos, verificar campos")
        document.getElementById('btn_procesar2_excel').disabled = "";
        document.getElementById('btn_procesar2_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
        document.getElementById('btn_procesar2_excel_spinner').className = 'spinner-border d-none';
      }

      document.getElementById('btn_procesar2_excel').disabled = "";
      document.getElementById('btn_procesar2_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
      document.getElementById('btn_procesar2_excel_spinner').className = 'spinner-border d-none';



    
    }
    showData();
  }
  const onClickExcelNE = async () => {
    body_t['txt_idtipo'] = '1'
    const opti_t = { method: "POST", headers: {'Content-Type': 'application/json'},compress: true, body: JSON.stringify(body_t) }
    //console.log(sl_t_unid.desLinea);
    const fileType = "xlsx"
    const nameFileType = "CUBOFLEXDATA_"+moment(inp_text_fe_desde).format('YYYYMM')
    document.getElementById('btn_procesar2_excel').disabled = "true";
    document.getElementById('btn_procesar2_excel_icon').setAttribute('class', 'd-none');
    document.getElementById('btn_procesar2_excel_spinner').className = 'spinner-border';
    const showData = async () => {
      const response = await fetch(url0,opti_t);
      const datadtos = await response.json();
      //console.log("--saas",datadtos);
      //setData(datadtos)
      if (datadtos !== '0' ) {
        for (var i = 0; i < datadtos.length; i++) { 
          let fecha = new Date(moment(datadtos[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          datadtos[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(datadtos);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);

        document.getElementById('btn_procesar2_excel').disabled = "";
        document.getElementById('btn_procesar2_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
        document.getElementById('btn_procesar2_excel_spinner').className = 'spinner-border d-none';
      }else{
        notify_warning("No existen datos, verificar campos")
        document.getElementById('btn_procesar2_excel').disabled = "";
        document.getElementById('btn_procesar2_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
        document.getElementById('btn_procesar2_excel_spinner').className = 'spinner-border d-none';
      }

      document.getElementById('btn_procesar2_excel').disabled = "";
      document.getElementById('btn_procesar2_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
      document.getElementById('btn_procesar2_excel_spinner').className = 'spinner-border d-none';



    
    }
    showData();
  }

  const onClickReset = () => {

    setInp_text_fe_desde(new Date())
    //setInp_text_fe_hasta(getDateD("-"))
    //setTxt_year(getDateA())
    setsl_t_suc("9999")
    setsl_t_sup("9999")
    setsl_t_pro("9999")
    setsl_t_unid("")
  }

  useEffect(() => {
    //fc_lstSuc(dtUser.tpu_id,dtUser.usu_dni)
    fc_lstSuperv(dtSucu)
    fc_lstProv(dtSucu)
    if (dtUser.tpu_id === 5) {
      $('#slc_super').attr('disabled',true)
    }
  },[fc_lstSuperv,fc_lstProv,dtSucu,dtUser.tpu_id])



  return (
    
    <div>
        <h4 className="fw-bold py-3 mb-4 d-none"> Filtrar </h4>
        <div className="card card-action mb-4">
            <div className="card-header">
                <div className="card-action-title">Filtrar</div>
                <div className="card-action-element">
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                            <a href="javascript:void(0);" className="card-expand d-none"><i className="tf-icons bx bx-fullscreen"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body">
                
                
                <div className="row ">
                  <div className="col-md-2">Periodo:</div>
                  <div className="col-md-2">
                    <DatePicker 
                      locale="es"
                      className="form-control form-control-sm text-center" 
                      selected={inp_text_fe_desde} 
                      onChange={date => setInp_text_fe_desde(date)} 
                      dateFormat="yyyy-MM"
                      showMonthYearPicker
                    />
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-2">Sucursal:</div>
                  <div className="col-md-2">
                    <select id='slc_sucur' className="form-select form-select-sm" value={sl_t_suc} onChange={changeSl_t_suc}  >
                      {
                        dtUser.sl_dt_suc?.map((item,index) => {
                          return <option value={item?.suc_nombre} key={index} >{item?.suc_nombre}</option>
                        })
                      }
                    </select>
                  </div>
                  <div className="col-md-2">Mesa:</div>
                  <div className="col-md-2">
                    <SelectTipo id="slc_mesas" url={url1+'?idt_text='+dtUser.tpu_id+'&sl_t_suc='+sl_t_suc+'&inp_text='+dtcodu} value={sl_t_mesas} onChange={changeSetsl_mesas} />
                  </div>
                  <div className="col-md-2">GrupoVenta:</div>
                    <div className="col-md-2">
                        <select className="form-select form-select-sm"  value={sl_t_grupoventa} onChange={changeSl_t_grupoventa} >
                            <option value={"9999"} >TODOS</option>
                            {   
                                sl_grupoventa?.map((item,index) => {
                                    return <option value={item?.GrupoVenta} key={index} >{item?.DescripcionGrupoVentas}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="row ">
                  
                  <div className="col-md-2">Supervisor:</div>
                  <div className="col-md-4">

                    <select id='slc_super' className="form-select form-select-sm" value={sl_t_sup}  onChange={changeSl_t_sup}>
                      <option value={"9999"} >TODOS</option>
                      {
                        sl_superv?.map((item,index) => {
                            return <option value={item?.codigo} key={index} >{item?.detalle}</option>
                        })
                      }

                    </select>
                  </div>
                  <div className="col-md-2">Proveedor:</div>
                  <div className="col-md-4">
                        <select className="form-select form-select-sm" value={sl_t_pro}  onChange={changesl_t_pro}>
                          <option value={"9999"} >TODOS</option>
                          {
                            sl_proveedores?.map((item,index) => {
                                return <option value={item?.CodProveedor} key={index} >{item?.NomProveedor}</option>
                            })
                          }

                        </select>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-2">UnidadNegocio:</div>
                  <div className="col-md-10">
                    <AsyncSelect
                      isMulti
                      placeholder="Busque codigo o descripción"
                      name="form-field-name"
                      className="sele_pru from-control form-control-sm"  
                      value={sl_t_unid}
                      cacheOptions
                      defaultOptions={false}
                      getOptionLabel={e => e.desLinea}
                      getOptionValue={e => e.desLinea}
                      loadOptions={fc_getOptionsSelect}
                      onChange={setsl_t_unid}
                      isClearable={true}
                      openMenuOnClick={false}
                    />
                  </div>
                </div>

                <div className="row ">
                    <div className="col-md-4 "></div>
                    <div className="col-md-4 text-center ">
                      <div className="btn-group" role="group">
                        <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                          <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                          &nbsp;Exportar
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                          <a className="dropdown-item bx-ms" onClick={onClickCSV} href="javascript:void(0);">CSV</a>
                          <a className="dropdown-item bx-ms" onClick={onClickExcel} href="javascript:void(0);">EXCEL</a>
                        </ul>
                      </div>
                        {/* <Tooltip title="Exportar CSV" placement="top">
                            <button className="btn btn-success btn-sm" id="btn_procesar_excel" onClick={onClickExcel} >
                                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                                <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                                &nbsp;Exportar CSV
                            </button>
                        </Tooltip>
                        */}
                        <Tooltip title="Resetar Campos" placement="top">
                            <button className="btn btn-dark btn-sm" id="btn_reset" onClick={onClickReset} >
                                <i className='bx bx-reset bx-ms' id="btn_procesar_icon"></i>
                            </button>
                        </Tooltip>
                    </div>
                    <div className="col-md-4 text-end">
                      <div className="btn-group" role="group">
                        <button id="btn_procesar2_excel" type="button" className="btn btn-sm btn-danger dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className='bx bxs-file-export bx-ms' id="btn_procesar2_excel_icon"></i>
                          <span className="spinner-border d-none" id="btn_procesar2_excel_spinner" role="status" aria-hidden="true"></span>
                          &nbsp;Rechazos
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="btn_procesar2_excel">
                          <a className="dropdown-item bx-ms" onClick={onClickCSVlNE} href="javascript:void(0);">CSV</a>
                          <a className="dropdown-item bx-ms" onClick={onClickExcelNE} href="javascript:void(0);">EXCEL</a>
                        </ul>
                      </div>
                      {/* <Tooltip title="Exportar CSV" placement="top">
                            <button className="btn btn-danger btn-sm" id="btn_procesar2_excel" onClick={onClickExcelNE} >
                                <i className='bx bxs-file-export bx-ms' id="btn_procesar2_excel_icon"></i>
                                <span className="spinner-border d-none" id="btn_procesar2_excel_spinner" role="status" aria-hidden="true"></span>
                                &nbsp;Rechazos
                            </button>
                      </Tooltip> */}
                    </div>

                </div>
            </div>
        </div>
        <ToastContainer />
    </div>
  )
}

export default MasterCuboVentas