/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import Cookies from 'universal-cookie';
import { SelectSucursal, SelectTipo } from '../../components/Selects/SelectsPrincipales'
import { ToastContainer } from 'react-toastify';
import { notify_warning, notify_valido, selectTabla } from '../../components/utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css';
import $ from 'jquery'
import { ModalDetallado } from '../../components/Modals/ModalsDetVen';
import Swal from 'sweetalert2';
import '../../App.css';
import img_pdf from '../../assets/img/icons/misc/pdf.png';
const moment = require('moment')
const cookies = new Cookies();

const MGR_Resumen = () => {
  const url_sem ='/get-data-soperacion';
  const [lst, setlst] = useState([]);
  const [disabled, setdisabled] = useState(false);
  const [pending, setpending] = useState(false);
  const [pending2, setpending2] = useState(false);
  //const [moEstado, setMoEstado] = useState(false);
  const [txt_periodo, setTxt_periodo] = useState(new Date());
  const [slc_t_suc, setSlc_t_suc] = useState('');
  const [slc_t_sem, setSlc_t_sem] = useState('');
  const changeSlc_t_suc = (event) => { setSlc_t_suc(event.target.value);}
  const changeSlc_t_sem = (event) => { setSlc_t_sem(event.target.value);}
  
  const lre_url = '/gr-lst-resumen'
  const ltd_url = '/gdt-lst'
  // const ins_url = '/gm-sop-insert'
  // const lst_url = '/gm-sop-lst'
  // const upd_url = '/gm-sop-update'
  const dta_url = '/gdt-delete'


  const [data_ldt, setData_ldt] = useState([]);

  const body = { slc_t_suc: slc_t_suc, txt_periodo:txt_periodo, slc_t_sem: slc_t_sem, txt_usuid: cookies.get('dtUser_id') };
  const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  const lst_columns = [
  
    { name: 'PLACA',     selector: row => row.mun_placa,       cellExport: row => row.mun_placa,       sortable: true, width: "10rem" },
    { name: '%',     selector: row => row.res_porcentaje,       cellExport: row => row.res_porcentaje,       sortable: true },
    { cell:(row) =>
      <div className="align-items-center">
          <button type="button" className="btn btn-sm btn-icon btn-label-info" onClick={onClicModalDetallado} id={JSON.stringify(row)} data-bs-toggle="modal" data-bs-target="#modalCenter" ><i id={JSON.stringify(row)} className='bx bx-edit bx-ms'></i></button>
      </div>
      ,ignoreRowClick: true,  allowOverflow: true, button: true, name: 'OPCIÓN' },
    { name: 'COD SV',         selector: row => row.est_cod_sv,    cellExport: row => row.est_cod_sv,    sortable: true, omit: true },
    { name: 'Estado SV',      selector: row => row.Desc1,         cellExport: row => row.Desc1,         sortable: true, },
    { name: 'COD AB',         selector: row => row.est_cod_ab,    cellExport: row => row.est_cod_ab,    sortable: true, omit: true },
    { name: 'Estado AB',      selector: row => row.Desc2,         cellExport: row => row.Desc2,         sortable: true },
    { name: 'inicio',      selector: row => row.sop_diainicio, cellExport: row => row.sop_diainicio, sortable: true, omit: true },
    { name: 'fin',      selector: row => row.sop_diafin,    cellExport: row => row.sop_diafin,    sortable: true, omit: true },
    
  ];
  const onClickListarResumen = async() => {
    
    if( txt_periodo === "" || slc_t_suc === "9999" || slc_t_sem === "9999" || slc_t_sem === ""){
      notify_warning("CAMPOS VACIOS, VERIFICAR!!!")
    }else{
      setdisabled(true)
      $("#btn_procesar").addClass("d-none")
      $("#btn_nprocesar").removeClass("d-none")
      const columns = lst_columns
      setpending(true)
      const response = await fetch(lre_url,opPe);
      const data = await response.json();
      //console.log(data);
      const tableData = { columns, data };
      setlst(tableData)
      setpending(false)
      $(".tabla-resumen .rdt_Table").addClass("table");
    }
  }
  const onClickListarNewConsult = async() => {
    //alert("hola mundo")
    $("#btn_procesar").removeClass("d-none")
    $("#btn_nprocesar").addClass("d-none")
    setdisabled(false)
    setlst([])
  }

  const lst_columns2 = [
    { name: 'ID',         selector: row => row.gpa_id,            cellExport: row => row.gpa_id,          sortable: true, omit: true },
    { name: 'PLACA',      selector: row => row.spa_cod,           cellExport: row => row.spa_cod,         sortable: true, omit: true },
    { name: 'Concepto',   selector: row => row.spa_descripcion,   cellExport: row => row.spa_descripcion, sortable: true },
    { name: 'Monto',      selector: row => row.gpa_monto,         cellExport: row => row.gpa_monto,       sortable: true },
    { name: 'Estado SV',  selector: row => row.est_cod,           cellExport: row => row.est_cod,         sortable: true, omit: true },
    { name: 'Estado',     selector: row => row.est_descripcion,   cellExport: row => row.est_descripcion, sortable: true },
    { cell:(row) =>
      <div className="align-items-center">
        { row.gpa_marchivo === ""? 
          <p className='d-none'>nimg</p>
          :
          <a href={"/archivos/"+row.gpa_marchivo} target="_blank" rel="noreferrer">
            {row.gpa_marchivo.split('.')[1] === 'PDF' ?
              <img src={img_pdf} alt="PDF imagess" width="18" className="me-2"></img>
              :
              <i className='bx bxs-file-image bx-ms me-2'></i>
            }
          </a>
        }
        <button type="button" className="btn btn-sm btn-icon btn-label-danger" onClick={onClickDeleteDt}  id={JSON.stringify(row)}><i id={JSON.stringify(row)} className='bx bx-x-circle bx-ms'></i></button>
      </div>
      ,ignoreRowClick: true, allowOverflow: true, button: true, name: 'OPCIÓN' },
    // { name: 'est_des',    selector: row => row.gpa_fecha,       cellExport: row => row.gpa_fecha, sortable: true, omit: true },
    // { name: 'est_des',    selector: row => row.par_cod,         cellExport: row => row.par_cod, sortable: true, omit: true },
    // { name: 'est_des',    selector: row => row.gpa_mreferencia, cellExport: row => row.gpa_mreferencia, sortable: true, omit: true },
    // { name: 'est_des',    selector: row => row.gpa_observacion, cellExport: row => row.gpa_observacion, sortable: true, omit: true },
  ]

  const onClickDeleteDt = async(state) => {
    try {
      const mun_placa = $(".modal_cod_placa").text()
      const dtLlegada = await JSON.parse(state.target.id);
      const codigo = dtLlegada.gpa_id
      const del_body = {  txt_periodo:txt_periodo, slc_t_suc:slc_t_suc, slc_t_sem:slc_t_sem, txt_nplaca:mun_placa, txt_codigo: codigo, txt_usuid: cookies.get('dtUser_id') };
      const del_opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(del_body) }
    
      Swal.fire({
        title: 'Advertencia',
        text: '¿Esta seguro de eliminar este registro?',
        icon: 'error',
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si",
        confirmButtonColor: "#696cff",
        customClass: {
          container: 'my-swal'
        }
        
      }).then(async response =>  {
        if (response.isConfirmed) {
          const respondt = await fetch(dta_url,del_opPe);
          const resdatos = await respondt.json();
          //console.log("resdatospr",resdatos);
          if (resdatos.vl_insert === 0){
            notify_warning("ERROR AL ELIMINAR!!!")
          }else if (resdatos.vl_insert === 1 ){
            notify_valido("USUARIO SE ELIMINO CORRECTAMENTE!!!")
            Swal.fire({title:'EXITO', text:'USUARIO SE ELIMINO CORRECTAMENTE!!!', icon:'success',showConfirmButton:false, timer: 1000})
            body['txt_nplaca'] =$(".modal_cod_placa").text()
            const lstTablaModal = async () => {
              setpending2(true)
              let columns = lst_columns2
              const opPe2 = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
              const response2 = await fetch(ltd_url,opPe2);
              const data = await response2.json();
              const tableData = { columns, data };
              //console.log('data2', data2);
              setData_ldt(tableData)
              setpending2(false)

            }
            lstTablaModal()
          }
          PintarTablaSelect(resdatos.vl_smonto)
        }else if(response.isDenied){
          Swal.close();
        }
      })
    } catch (error) {
      console.log(error);
    }
  }
  

  const onClicModalDetallado = async(state) => {
    try {
      $(".tabla-detalle .rdt_Table").addClass("table");
      const dtLlegada = await JSON.parse(state.target.id);
      $(".modal_cod_placa").text(dtLlegada.mun_placa)
      $(".modal_des_semana").text($("#slc_semanas option:selected").text())
      $(".modal_txt_diaini").text(dtLlegada.sop_diainicio)
      $(".modal_txt_diafin").text(dtLlegada.sop_diafin)
      body['txt_nplaca'] = dtLlegada.mun_placa

      const lstTablaModal = async () => {
        setpending2(true)
        let columns = lst_columns2
        const opPe2 = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        const response2 = await fetch(ltd_url,opPe2);
        const data = await response2.json();
        const tableData = { columns, data };
        //console.log('data2', data2);
        setData_ldt(tableData)
        setpending2(false)

      }
      lstTablaModal()



    } catch (error) {
      console.log(error);;
    }
  }

  const handleRowClicked = (row) => {
    selectTabla("tabla-resumen")
    
  }
  const PintarTablaSelect = (monto) => {
    var iddato =  $('.tabla-resumen .table .table-danger #cell-2-undefined')
    iddato.find('div').text(monto)
    //console.log("iddato",iddato.find('div').text("hola mundo"));
  };
  

  return (
    <div>
        <h4 className="fw-bold py-1"> Resumen de Gastos </h4>
        <div className="card card-action mb-4">
            <div className="card-body">
              <div className="row">
                  <div className="col-lg-2">Sucursal:</div>
                  <div className="col-lg-2">
                    <SelectSucursal id="emp_slc_suc" value={slc_t_suc} onChange={changeSlc_t_suc} disabled={disabled}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">Periodo:</div>
                  <div className="col-md-2">
                    <DatePicker
                      locale="es"
                      className="form-control form-control-sm text-center"
                      selected={txt_periodo} 
                      onChange={date => {setTxt_periodo(date); setSlc_t_sem(""); setlst([])}} 
                      dateFormat="yyyy-MM"
                      showMonthYearPicker
                      disabled={disabled}
                    />
                  </div>
                  <div className="col-md-2">Operación:</div>
                  <div className="col-md-2">
                    <SelectTipo id="slc_semanas" url={url_sem+'?txt_periodo='+moment(txt_periodo).format('YYYY-MM')} value={slc_t_sem} onChange={changeSlc_t_sem} disabled={disabled}/>
                  </div>
                  <div className="col-md-4 text-center ">
                    <button className="btn btn-secondary btn-sm d-none" id="btn_nprocesar" onClick={onClickListarNewConsult} >
                      <i className='bx bx-revision bx-ms' id="btn-nprocesar_icon"></i>
                      &nbsp;Nueva Consultar
                    </button>
                    <button className="btn btn-primary btn-sm" id="btn_procesar" onClick={onClickListarResumen}  >
                      <i className='bx bx-poll bx-ms' id="btn_procesar_icon"></i>
                      <span className="spinner-border d-none" id="btn_procesar_spinner"  role="status" aria-hidden="true"></span>
                      &nbsp;Consultar
                    </button>
                  </div>
                </div>
            </div>
        </div>
        <div className="card card-action">
            <div className="card-header d-none">
                <div className="card-action-title">Lista </div>
                <div className="card-action-element">
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                            <a href="javascript:void(0);" className="card-expand d-none" ><i className="tf-icons bx bx-fullscreen"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body">
                <div className="card-datatable table-responsive">
                    <DataTableExtensions {...lst} >
                        <DataTable className="tabla-resumen"
                            Header
                            defaultSortField="id"
                            defaultSortAsc={true}
                            pagination
                            responsive  
                            highlightOnHover
                            progressPending={pending}
                            noContextMenu="No hay registros para mostrar"
                            onRowClicked={handleRowClicked}
                        />
                    </DataTableExtensions>
                </div>
            </div>
        </div>
        <ModalDetallado dataLlegada={body} dataldt={data_ldt} pending2={pending2} />
        <ToastContainer />
    </div>
  )
}

export default MGR_Resumen