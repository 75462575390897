import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'; 
//import "./assets/vendor/fonts/boxicons.css";
//import "./assets/vendor/fonts/fontawesome.css";
//import "./assets/vendor/fonts/flag-icons.css";

//import "./assets/vendor/css/rtl/core.css";
//import "./assets/vendor/css/rtl/theme-default.css";
//import "./assets/css/demo.css";

//import "./assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
//import "./assets/vendor/libs/typeahead-js/typeahead.css";
//import "./assets/vendor/libs/apex-charts/apex-charts.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);



/**
 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


 */