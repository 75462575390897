import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie';
import {ModalsDetVen} from '../Modals/ModalsDetVen'
//import * as myLibreryBlock from '../../assets/vendor/libs/block-ui/block-ui'
//import $ from 'jquery'
//import '../../assets/vendor/libs/block-ui/block-ui'
const cookies = new Cookies();
const dtUser = cookies.get('dtUser'); 

export const TablesCortes = () => {
  const txt_Sucursal = dtUser.sl_dt_suc[0].suc_nombre
  const txt_CodSuper = dtUser.CodigoSupervisor
  //const txt_IdSuperv = dtUser.idsupervisor

  const [dataAvance, setdataAvance] = useState([])

  useEffect(() => {
    const showDataAvance = async () => {
        const apig = '/api-tab-avance'
        const body = { txt_Sucursal: txt_Sucursal, txt_CodSuper: txt_CodSuper };
        const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        const response = await fetch(apig,opPe);
        const datadtos = await response.json();
        setdataAvance(datadtos)
        // $(".card .blockUI").addClass("d-none")
    }
    showDataAvance()
  },[txt_Sucursal,txt_CodSuper])

 
  const [data_PorLineaVe, setData_PorLineaVe] = useState('')
  const clickHandler = async(state) => {
    const dtLlegada = JSON.parse(state.target.id);
    setData_PorLineaVe(dtLlegada)
  }

  return (
      <>
        <div className="table-responsive text-nowrap">
          <table className="table table-sm table-hover">
            <thead>
              
              <tr className='text-center '>
                
                <th colSpan={6}></th>
                <th colSpan={4} className="table-primary">Corte 1</th>
                <th colSpan={4} className="table-primary">Corte 2</th>
                <th colSpan={4} className="table-primary">Corte 3</th>
                <th colSpan={4} className="table-primary">Cierre</th>
              </tr>
              <tr className='text-center '>
                <th  className="table-info">OPTION</th>
                <th  className="table-info">Descripción Grupo Ventas</th>
                <th  className="table-info">CodVendedor</th>
                <th  className="table-info">NomVendedor</th>
                <th  className="table-warning" >Obj/Pedidos</th>
                <th  className="table-warning" >Obj/Soles</th>
                <th colSpan={2} className="table-info">Avance Pedidos</th>
                <th colSpan={2} className="table-info">Avance Volumen</th>
                <th colSpan={2} className="table-info">Avance Pedidos</th>
                <th colSpan={2} className="table-info">Avance Volumen</th>
                <th colSpan={2} className="table-info">Avance Pedidos</th>
                <th colSpan={2} className="table-info">Avance Volumen</th>
                <th colSpan={2} className="table-info">Avance Pedidos</th>
                <th colSpan={2} className="table-info">Avance Volumen</th>
              </tr>
            </thead>
            <tbody className="table-border-bottom-0" style={{fontSize: '12px'}}>
              {
                dataAvance?.map((item,index) => {
                  var p1 = 0.25;
                  var p2 = 0.50;
                  var p3 = 0.72;
                  var p4 = 1;

                  var v1c = item.ObjCant;
                  var v2s = Math.round(item.ObjSoles);
                  /** Corte 1 - 25% */
                  var r1c = (item.C1_ObjCant/v1c).toFixed(2);
                  var m1c = (r1c >= p1) ? <i className='bx bx-check text-success' ></i> : <i className='bx bx-x text-danger' ></i>
                  var r1s = (Math.round(item.C1_ObjSoles)/v2s).toFixed(2);
                  var m1s = (r1s >= p1) ? <i className='bx bx-check text-success' ></i> : <i className='bx bx-x text-danger' ></i>
                  
                  /** Corte 2 - 50% */
                  var r2c = (item.C2_ObjCant/v1c).toFixed(2);
                  var m2c = (r2c >= p2) ? <i className='bx bx-check text-success' ></i> : <i className='bx bx-x text-danger' ></i>
                  var r2s = (Math.round(item.C2_ObjSoles)/v2s).toFixed(2);
                  var m2s = (r2s >= p2) ? <i className='bx bx-check text-success' ></i> : <i className='bx bx-x text-danger' ></i>

                  /** Corte 3 - 75% */
                  var r3c = (item.C3_ObjCant/v1c).toFixed(2);
                  var m3c = (r3c >= p3) ? <i className='bx bx-check text-success' ></i> : <i className='bx bx-x text-danger' ></i>
                  var r3s = (Math.round(item.C3_ObjSoles)/v2s).toFixed(2);
                  var m3s = (r3s >= p3) ? <i className='bx bx-check text-success' ></i> : <i className='bx bx-x text-danger' ></i>

                  /** Cierre - 100% */
                  var r4c = (item.C4_ObjCant/v1c).toFixed(2);
                  var m4c = (r4c >= p4) ? <i className='bx bx-check text-success' ></i> : <i className='bx bx-x text-danger' ></i>
                  var r4s = (Math.round(item.C4_ObjSoles)/v2s).toFixed(2);
                  var m4s = (r4s >= p4) ? <i className='bx bx-check text-success' ></i> : <i className='bx bx-x text-danger' ></i>


                  return (<tr key={index}>
                            <td><button type="button" className="btn btn-sm btn-icon btn-label-info" onClick={clickHandler} id={JSON.stringify(item)}  data-bs-toggle="modal" data-bs-target="#exLargeModal">+</button></td>
                            <td>{item.DescripcionGrupoVentas}</td>
                            <td>{item.CodigoVendedor}</td>
                            <td>{item.NombreAgente}</td>
                            <td className="table-warning text-center">{item.ObjCant}</td>
                            <td className="table-warning text-center">S/ {v2s}</td>
                            <td className='text-center'>{item.C1_ObjCant}</td>
                            <td>{m1c}</td>
                            <td className='text-end'>S/ {item.C1_ObjSoles}</td>
                            <td>{m1s}</td>
                            <td className='text-center'>{item.C2_ObjCant}</td>
                            <td>{(item.C2_ObjCant)?m2c:''}</td>
                            <td className='text-end'>S/ {item.C2_ObjSoles}</td>
                            <td>{(item.C2_ObjSoles)?m2s:''}</td>
                            <td className='text-center'>{item.C3_ObjCant}</td>
                            <td>{(item.C3_ObjCant)?m3c:''}</td>
                            <td className='text-end'>S/ {item.C3_ObjSoles}</td>
                            <td>{(item.C3_ObjSoles)?m3s:''}</td>
                            <td className='text-center'>{item.C4_ObjCant}</td>
                            <td>{(item.C4_ObjCant)?m4c:''}</td>
                            <td className='text-end'>S/ {item.C4_ObjSoles}</td>
                            <td>{(item.C4_ObjSoles)?m4s:''}</td>

                          </tr>)
                })
              }
            </tbody>
          </table>
        </div>
        <ModalsDetVen dtPorLineaVe={data_PorLineaVe} />
      </>
  )
}
export const TablesCortes2 = (llegada) => {
  //const txt_Sucursal = dtUser.sl_dt_suc[0].suc_nombre
  const txt_CodJefe = dtUser.usu_dni
  //const txt_IdSuperv = dtUser.idsupervisor
  //console.log(llegada.dt_llegada);
  const [dataAvance, setdataAvance] = useState([])

  useEffect(() => {
    const showDataAvance = async () => {
        const apig = '/api-tab-avance-jefes'
        const body = { txt_Sucursal: llegada.dt_llegada, txt_CodJefe: txt_CodJefe };
        const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        const response = await fetch(apig,opPe);
        const datadtos = await response.json();
        //console.log(datadtos);
        setdataAvance(datadtos)
        //$(".card .blockUI").addClass("d-none")
    }
    showDataAvance()
  },[llegada.dt_llegada,txt_CodJefe])

 
  // const [data_PorLineaVe, setData_PorLineaVe] = useState('')
  // const clickHandler = async(state) => {
  //   const dtLlegada = JSON.parse(state.target.id);
  //   setData_PorLineaVe(dtLlegada)
  // }

  return (
      <>
        <div className="table-responsive text-nowrap">
          <table className="table table-sm table-hover">
            <thead>           
              <tr className='text-center'>
                
                <th colSpan={6} className='text-start table-danger' > *SUCURSAL {llegada.dt_llegada}</th>
                <th colSpan={4} className="table-primary">Corte 1</th>
                <th colSpan={4} className="table-primary">Corte 2</th>
                <th colSpan={4} className="table-primary">Corte 3</th>
                <th colSpan={4} className="table-primary">Cierre</th>
              </tr>
              <tr className='text-center '>
                <th  className="table-info">Descripción Grupo Ventas</th>
                <th  className="table-info">Supervisor</th>
                <th  className="table-info">CodVendedor</th>
                <th  className="table-info">NomVendedor</th>
                <th  className="table-warning" >Obj/Pedidos</th>
                <th  className="table-warning" >Obj/Soles</th>
                <th colSpan={2} className="table-info">Avance Pedidos</th>
                <th colSpan={2} className="table-info">Avance Volumen</th>
                <th colSpan={2} className="table-info">Avance Pedidos</th>
                <th colSpan={2} className="table-info">Avance Volumen</th>
                <th colSpan={2} className="table-info">Avance Pedidos</th>
                <th colSpan={2} className="table-info">Avance Volumen</th>
                <th colSpan={2} className="table-info">Avance Pedidos</th>
                <th colSpan={2} className="table-info">Avance Volumen</th>
              </tr>
            </thead>
            <tbody className="table-border-bottom-0" style={{fontSize: '12px'}}>
              {
                dataAvance?.map((item,index) => {
                  var p1 = 0.25;
                  var p2 = 0.50;
                  var p3 = 0.72;
                  var p4 = 1;

                  var v1c = item.ObjCant;
                  var v2s = Math.round(item.ObjSoles);
                  /** Corte 1 - 25% */
                  var r1c = (item.C1_ObjCant/v1c).toFixed(2);
                  var m1c = (r1c >= p1) ? <i className='bx bx-check text-success' ></i> : <i className='bx bx-x text-danger' ></i>
                  var r1s = (Math.round(item.C1_ObjSoles)/v2s).toFixed(2);
                  var m1s = (r1s >= p1) ? <i className='bx bx-check text-success' ></i> : <i className='bx bx-x text-danger' ></i>
                  
                  /** Corte 2 - 50% */
                  var r2c = (item.C2_ObjCant/v1c).toFixed(2);
                  var m2c = (r2c >= p2) ? <i className='bx bx-check text-success' ></i> : <i className='bx bx-x text-danger' ></i>
                  var r2s = (Math.round(item.C2_ObjSoles)/v2s).toFixed(2);
                  var m2s = (r2s >= p2) ? <i className='bx bx-check text-success' ></i> : <i className='bx bx-x text-danger' ></i>

                  /** Corte 3 - 75% */
                  var r3c = (item.C3_ObjCant/v1c).toFixed(2);
                  var m3c = (r3c >= p3) ? <i className='bx bx-check text-success' ></i> : <i className='bx bx-x text-danger' ></i>
                  var r3s = (Math.round(item.C3_ObjSoles)/v2s).toFixed(2);
                  var m3s = (r3s >= p3) ? <i className='bx bx-check text-success' ></i> : <i className='bx bx-x text-danger' ></i>

                  /** Cierre - 100% */
                  var r4c = (item.C4_ObjCant/v1c).toFixed(2);
                  var m4c = (r4c >= p4) ? <i className='bx bx-check text-success' ></i> : <i className='bx bx-x text-danger' ></i>
                  var r4s = (Math.round(item.C4_ObjSoles)/v2s).toFixed(2);
                  var m4s = (r4s >= p4) ? <i className='bx bx-check text-success' ></i> : <i className='bx bx-x text-danger' ></i>


                  return (<tr key={index}>
                            
                            <td>{item.DescripcionGrupoVentas}</td>
                            <td>{item.CodigoSupervisor} - {item.NombreSupervisor}</td>
                            <td>{item.CodigoVendedor}</td>
                            <td>{item.NombreAgente}</td>
                            <td className="table-warning text-center">{item.ObjCant}</td>
                            <td className="table-warning text-center">S/ {v2s}</td>
                            <td className='text-center'>{item.C1_ObjCant}</td>
                            <td>{m1c}</td>
                            <td className='text-end'>S/ {item.C1_ObjSoles}</td>
                            <td>{m1s}</td>
                            <td className='text-center'>{item.C2_ObjCant}</td>
                            <td>{(item.C2_ObjCant)?m2c:''}</td>
                            <td className='text-end'>S/ {item.C2_ObjSoles}</td>
                            <td>{(item.C2_ObjSoles)?m2s:''}</td>
                            <td className='text-center'>{item.C3_ObjCant}</td>
                            <td>{(item.C3_ObjCant)?m3c:''}</td>
                            <td className='text-end'>S/ {item.C3_ObjSoles}</td>
                            <td>{(item.C3_ObjSoles)?m3s:''}</td>
                            <td className='text-center'>{item.C4_ObjCant}</td>
                            <td>{(item.C4_ObjCant)?m4c:''}</td>
                            <td className='text-end'>S/ {item.C4_ObjSoles}</td>
                            <td>{(item.C4_ObjSoles)?m4s:''}</td>

                          </tr>)
                })
              }
            </tbody>
          </table>
        </div>
      </>
  )
}
export const TablesCortesHoras = () => {
  const txt_Sucursal = dtUser.sl_dt_suc[0].suc_nombre
  const txt_CodSuper = dtUser.CodigoSupervisor
  //const txt_IdSuperv = dtUser.idsupervisor

  const [dataAvance, setdataAvance] = useState([])

  useEffect(() => {
    const showDataAvance = async () => {
        const apig = '/api-tab-avporhora'
        const body = { txt_Sucursal: txt_Sucursal, txt_CodSuper: txt_CodSuper };
        const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        const response = await fetch(apig,opPe);
        const datadtos = await response.json();
        //console.log(datadtos);
        setdataAvance(datadtos)
        // $(".card .blockUI").addClass("d-none")
    }
    showDataAvance()
  },[txt_Sucursal,txt_CodSuper])


  return (
      <>
        <div className="table-responsive text-nowrap">
          <table className="table table-sm table-bordered table-striped">
            <thead>
              
              <tr className='text-center '>
                
                <th className="table-info">CodVendedor</th>
                <th className="table-info">NomVendedor</th>
                <th className="table-info">TOTAL</th>
                <th className="table-info">SOLES</th>
                <th className="table-info">00AM-07AM</th>
                <th className="table-info">07AM-08AM</th>
                <th className="table-info">08AM-09AM</th>
                <th className="table-info">09AM-10AM</th>
                <th className="table-info">10AM-11AM</th>
                <th className="table-info">11AM-12PM</th>
                <th className="table-info">12PM-01PM</th>
                <th className="table-info">01PM-02PM</th>
                <th className="table-info">02PM-03PM</th>
                <th className="table-info">03PM-04PM</th>
                <th className="table-info">04PM-05PM</th>
                <th className="table-info">05PM {'>'}</th>
              </tr>
            </thead>
            <tbody className="" style={{fontSize: '12px'}}>
              {
                dataAvance?.map((item,index) => {
                  return (<tr key={index}>
                            <td>{item.CodigoVendedor}</td>
                            <td>{item.NombreAgente}</td>
                            <td className="text-center">{item.TotalPedidos}</td>
                            <td className="text-center">S/ {item.Soles}</td>
                            <td className="text-center">{(item.a === 0)?'': item.a}</td>
                            <td className="text-center">{(item.b === 0)?'': item.b}</td>
                            <td className="text-center">{(item.c === 0)?'': item.c}</td>
                            <td className="text-center">{(item.d === 0)?'': item.d}</td>
                            <td className="text-center">{(item.e === 0)?'': item.e}</td>
                            <td className="text-center">{(item.f === 0)?'': item.f}</td>
                            <td className="text-center">{(item.g === 0)?'': item.g}</td>
                            <td className="text-center">{(item.h === 0)?'': item.h}</td>
                            <td className="text-center">{(item.i === 0)?'': item.i}</td>
                            <td className="text-center">{(item.j === 0)?'': item.j}</td>
                            <td className="text-center">{(item.k === 0)?'': item.k}</td>
                            <td className="text-center">{(item.m === 0)?'': item.m}</td>

                          </tr>)
                })
              }
            </tbody>
          </table>
        </div>
      </>
  )
}

export const TablesCortesHoras2 = (llegada) => {
  //const txt_Sucursal = dtUser.sl_dt_suc[0].suc_nombre
  //const txt_CodSuper = dtUser.CodigoSupervisor
  //const txt_IdSuperv = dtUser.idsupervisor

  const [dataAvance, setdataAvance] = useState([])

  useEffect(() => {
    const showDataAvance = async () => {
        const apig = '/api-tab-avporhorajefe'
        const body = { txt_Sucursal: llegada.dt_llegada, };
        const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        const response = await fetch(apig,opPe);
        const datadtos = await response.json();
        //console.log(datadtos);
        setdataAvance(datadtos)
        // $(".card .blockUI").addClass("d-none")
    }
    showDataAvance()
  },[llegada.dt_llegada])


  return (
      <>
        <div className="table-responsive text-nowrap">
          <table className="table table-sm table-bordered table-striped">
            <thead>
            <tr className='text-center'>
                <th colSpan={18} className='text-start table-danger' > *SUCURSAL {llegada.dt_llegada}</th>
              </tr>
              <tr className='text-center '>

                <th className="table-info">CodSupervisor</th>
                <th className="table-info">NomSupervisor</th>
                <th className="table-info">CodVendedor</th>
                <th className="table-info">NomVendedor</th>
                <th className="table-info">TOTAL</th>
                <th className="table-info">SOLES</th>
                <th className="table-info">00AM-07AM</th>
                <th className="table-info">07AM-08AM</th>
                <th className="table-info">08AM-09AM</th>
                <th className="table-info">09AM-10AM</th>
                <th className="table-info">10AM-11AM</th>
                <th className="table-info">11AM-12PM</th>
                <th className="table-info">12PM-01PM</th>
                <th className="table-info">01PM-02PM</th>
                <th className="table-info">02PM-03PM</th>
                <th className="table-info">03PM-04PM</th>
                <th className="table-info">04PM-05PM</th>
                <th className="table-info">05PM {'>'}</th>
              </tr>
            </thead>
            <tbody className="" style={{fontSize: '12px'}}>
              {
                dataAvance?.map((item,index) => {
                  return (<tr key={index}>
                            <td>{item.CodigoSupervisor}</td>
                            <td>{item.NombreSupervisor}</td>
                            <td>{item.CodigoVendedor}</td>
                            <td>{item.NombreAgente}</td>
                            <td className="text-center">{item.TotalPedidos}</td>
                            <td className="text-center">S/ {item.Soles}</td>
                            <td className="text-center">{(item.a === 0)?'': item.a}</td>
                            <td className="text-center">{(item.b === 0)?'': item.b}</td>
                            <td className="text-center">{(item.c === 0)?'': item.c}</td>
                            <td className="text-center">{(item.d === 0)?'': item.d}</td>
                            <td className="text-center">{(item.e === 0)?'': item.e}</td>
                            <td className="text-center">{(item.f === 0)?'': item.f}</td>
                            <td className="text-center">{(item.g === 0)?'': item.g}</td>
                            <td className="text-center">{(item.h === 0)?'': item.h}</td>
                            <td className="text-center">{(item.i === 0)?'': item.i}</td>
                            <td className="text-center">{(item.j === 0)?'': item.j}</td>
                            <td className="text-center">{(item.k === 0)?'': item.k}</td>
                            <td className="text-center">{(item.m === 0)?'': item.m}</td>

                          </tr>)
                })
              }
            </tbody>
          </table>
        </div>
      </>
  )
}


//export default TablesCortes