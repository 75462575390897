import * as RB from 'reactstrap'
import React, { useEffect } from 'react'

const Clientes = () => {
  const divStyle={
    overflowY: 'scroll',
    width:'73rem',
    float: 'left',
    position:'relative',
    fontSize: '7px' ,
  };
  /**const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial",
    fontSize: "7.5rem"
  };**/

  //Guardar ultima pagina en cookie
  useEffect(() => {
    savePathInCookie();
  }, []);
  function savePathInCookie() {
    const path = window.location.pathname.substr(1); // Obtiene la parte después de la barra diagonal

    // Guarda el valor en una cookie con nombre "ultimapag"
    document.cookie = `ultimapag=${path}`;
  }
  //size="sm"
  return (
    <div style={divStyle} className="px-1">
    <RB.Table responsive >
      <thead className='tab_cabecera1'  >
        <tr className='text-center' >
          <th style={{background: "rgb(112, 48, 160)", color: "white", fontSize: "8.5px", }} > COD.VI </th>
          <th style={{background: "rgb(0, 176, 80)", color: "white", fontSize: "8.5px", }} > COD.PADRE ANTI </th>
          <th style={{background: "rgb(0, 176, 80)", color: "white", fontSize: "8.5px",  }} > COD.HIJO </th>
          <th style={{background: "rgb(47, 117, 181)", color: "white", fontSize: "8.5px",  }} > COD.PADRE.(SAP) </th>
          <th style={{background: "rgb(47, 117, 181)", color: "white", fontSize: "8.5px",  }} > COD.HIJO.1.(SAP)  </th>
          <th style={{background: "rgb(32, 55, 100)", color: "white", fontSize: "8.5px",  }} > CIUDAD </th>
          <th style={{background: "rgb(32, 55, 100)", color: "white", fontSize: "8.5px",  }} > CODIGO VENDEDOR </th>
          <th style={{background: "rgb(32, 55, 100)", color: "white", fontSize: "8.5px",  }} > CANAL </th>
          <th style={{background: "rgb(32, 55, 100)", color: "white", fontSize: "8.5px",  }} > NOMBRE DE.PLAZA </th>
          <th style={{background: "rgb(32, 55, 100)", color: "white", fontSize: "8.5px",  }} > CLIENTE TIPO </th>
          <th style={{background: "rgb(32, 55, 100)", color: "white", fontSize: "8.5px", }} > PLAN.DON.PEPE O DON.PEPITO </th>
          <th style={{background: "rgb(32, 55, 100)", color: "white", fontSize: "8.5px",  }} > RAZON.SOCIAL </th>
          <th style={{background: "rgb(0, 176, 80)", color: "white", fontSize: "8.5px",  }} > Cuota.JA22.(con IGV) </th>
          <th style={{background: "rgb(0, 176, 80)", color: "white", fontSize: "8.5px",  }} > Avance.Julio.(con IGV) </th>
          <th style={{background: "rgb(0, 176, 80)", color: "white", fontSize: "8.5px",  }} > Avance.Agosto.(con IGV) </th>
          <th style={{background: "rgb(0, 176, 80)", color: "white", fontSize: "8.5px",  }} > Cierre.JA22.(Con IGV) </th>
          <th style={{background: "rgb(0, 176, 80)", color: "white", fontSize: "8.5px",  }} > Surtid Base.Vendidos </th>
          <th style={{background: "rgb(0, 176, 80)", color: "white", fontSize: "8.5px",  }} > Surtid Julio.vendidos </th>
          <th style={{background: "rgb(0, 176, 80)", color: "white", fontSize: "8.5px",  }} > Surtid Agosto.vendidos </th>
          <th style={{background: "rgb(112, 48, 160)", color: "white", fontSize: "8.5px",  }} > Surtido Visible.Base </th>
          <th style={{background: "rgb(112, 48, 160)", color: "white", fontSize: "8.5px",  }} > Auditoría 1</th>
          <th style={{background: "rgb(112, 48, 160)", color: "white", fontSize: "8.5px",  }} > Auditoría 2 (Cierre) </th>
          <th style={{background: "rgb(32, 55, 100)", color: "white", fontSize: "8.5px",  }} > IDX.Cierre CUOTA </th>
          <th style={{background: "rgb(32, 55, 100)", color: "white", fontSize: "8.5px",  }} > Escala </th>
          <th style={{background: "rgb(32, 55, 100)", color: "white", fontSize: "8.5px",  }} > Factor Cuota </th>
          <th style={{background: "rgb(32, 55, 100)", color: "white", fontSize: "8.5px",  }} > Premio cuota </th>
          <th style={{background: "rgb(32, 55, 100)", color: "white", fontSize: "8.5px",  }} > Premio Visib </th>
          <th style={{background: "rgb(32, 55, 100)", color: "white", fontSize: "8.5px",  }} > Premio Total </th>
          <th style={{background: "rgb(32, 55, 100)", color: "white", fontSize: "8.5px",  }} > Observaciones./.Comentarios </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
        <tr>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
          <td> 00001 </td>
        </tr>
      </tbody>

    </RB.Table>
    </div>
  )
}

export default Clientes


/**import React, {useEffect, useState} from 'react';
//import './App.css';
import {Prueba} from './pru_view';
function Clientes() {
  const [productData, setProductData] = useState([]);
  //const [finalData, setFinalData] = useState([]);
  //const [finalDataSend, setFinalDataSend] = useState([]);
  useEffect(()=> {
    //Product Api
    //fetch('https://jsonplaceholder.typicode.com/users')
    fetch('/api')
    .then(response => response.json())
    .then(json => {
      //console.log("json",json.recordset);
      console.log("json",json);
      setProductData(json)
    })
  },[])
  
 
  return (
    <div className="App">
     
      <Prueba userDatail={productData}/>
    </div>
  );
}

export default Clientes;


import * as React from 'react';
import {columns,data} from '../components/utils';
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

export default function Clientes() {
  const tableData = {
    columns,
    data
  };

  return (
    <div className="main">
      <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          highlightOnHover
          dense
        />
      </DataTableExtensions>
    </div>
  );
}



import React from "react";
import { downloadExcel } from "react-export-table-to-excel";

const Clientes = () => {
  const header = ["Firstname", "Lastname", "Age"];
  const body = [
    ["Edison", "Padilla", 14],
    ["Cheila", "Rodrigez", 56],
  ];


  const body2 = [
    { firstname: "Edison", lastname: "Padilla", age: 14 },
    { firstname: "Cheila", lastname: "Rodrigez", age: 56 },
  ];

  function handleDownloadExcel() {
    downloadExcel({
      fileName: "react-export-table-to-excel -> downloadExcel method",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,
        // accept two different data structures
        body: body,
      },
    });
  }

  return (
    <div>
      <button onClick={handleDownloadExcel}>download excel</button>

      <table>
        <tbody>
          <tr>
            {header.map((head) => (
              <th key={head}> {head} </th>
            ))}
          </tr>

          {body.map((item, i) => (
            <tr key={i}>
              {item.map((it) => (
                <td key={it}>{it}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Clientes;**/





/**import React from "react";
class Clientes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0
    };
  }

  componentDidMount() {
    document.title = `You clicked 1 ${this.state.count} times`;
  }
  componentDidUpdate() {
    document.title = `You clicked 2 ${this.state.count} times`;
  }

  render() {
    return (
      <div>
        <p>You clicked {this.state.count} times</p>
        <button onClick={() => this.setState({ count: this.state.count + 1 })}>
          Click me
        </button>
      </div>
    );
  }
}
export default Clientes;**/

/**useEffect(()=> {
        //User Api
        fetch('https://jsonplaceholder.typicode.com/users')
        .then(response => response.json())
        .then(json => {
          setTimeout(()=> {
            const res = [...productData]
            res.push({
              category:"user",
              data:json
            })
            setFinalData(res)
          },1000)
          
        })
  },[productData])
  useEffect(()=> {
    
    fetch('https://jsonplaceholder.typicode.com/comments')
    .then(response => response.json())
    .then(json => {
      setTimeout(()=> {
        console.log("ressssssssss", finalData)
        const res = [...finalData]
       
        res.push({
          category:"Comment",
          data:json
        })
        setFinalDataSend(res)
      },1000)
      
    })
  },[finalData])**/