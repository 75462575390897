import React,{useState} from 'react'
import { SelectSucursal, SelectTipo } from '../../components/Selects/SelectsPrincipales'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
//import axios from 'axios';
const moment = require('moment')

const MGR_Reportes = () => {
  const url_tga = '/get-data-paridas'
  const url_sem = '/get-data-soperacion';
  //const url_con = '/post-data-subpartidas'

  const [txt_periodo, setTxt_periodo] = useState(new Date());
  const [slc_t_suc, setSlc_t_suc] = useState('');
  const [slc_t_tga, setSlc_t_tga] = useState('');
  const [slc_t_sem, setSlc_t_sem] = useState('');
  const [slc_t_rpo, setSlc_t_rpo] = useState('');
  //const [slc_conceptos, setSlc_conceptos] = useState([]);
  const changeSlc_t_suc = (event) => { setSlc_t_suc(event.target.value);}
  const changeSlc_t_sem = (event) => { setSlc_t_sem(event.target.value);}
  const changeSlc_t_tga = (event) => { 
    setSlc_t_tga(event.target.value); 
    //fc_lstConceptos(event.target.value)
  }
  const changeSlc_t_rpo = (event) => { setSlc_t_rpo(event.target.value); }

  // const fc_lstConceptos= async (r_cod) => {
  //   let body = { txt_cod: r_cod };
  //   const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  //   const response = await fetch(url_con,opPe);
  //   const data = await response.json();
  //   setSlc_conceptos(data)
  // }

  const onClickC= async () => {
    alert("hola button onClickC")
  }
  const onClickP= async () => {
    alert("hola button onClickP")
  }
  const url1 = '/gr_repor_R001';
  const url2 = '/gr_repor_R002';
  const body = {  txt_perio: txt_periodo, slc_t_suc: slc_t_suc, slc_t_tga: slc_t_tga, slc_t_sem: slc_t_sem, slc_t_rpo: slc_t_rpo };
  const opti = { method: "POST", headers: {'Content-Type': 'application/json'},compress: true, body: JSON.stringify(body) }

  const rp_r001 = async() => {
    const response = await fetch(url1,opti);
    const datadtos = await response.json();
    const ws = XLSX.utils.json_to_sheet(datadtos);
    const wb = {Sheets:{data:ws}, SheetNames:["data"]};
    const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
    const data = new Blob([excelBuffer], {type:"xlsx"});
    FileSaver.saveAs(data, `R001 - Formato control diario.xlsx`);
  }
  const rp_r002 = async() => {
    const response = await fetch(url2,opti);
    const datadtos = await response.json();
    const ws = XLSX.utils.json_to_sheet(datadtos);
    const wb = {Sheets:{data:ws}, SheetNames:["data"]};
    const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
    const data = new Blob([excelBuffer], {type:"xlsx"});
    FileSaver.saveAs(data, `R002 - Gasto general por periodo.xlsx`);
  }


  const onClickE= async () => {

    switch (slc_t_rpo) {
      case 'R001':
        rp_r001()
        break;
      case 'R002':
        rp_r002()
        //   const url = "https://theunitedstates.io/congress-legislators/executive.json";
        //   const raw_data = (await axios(url, {responseType: "json"})).data;

        //   /* filter for the Presidents */
        //   const prez = raw_data.filter(row => row.terms.some(term => term.type === "prez"));

        //   /* flatten objects */
        //   const rows = prez.map(row => ({
        //     name: row.name.first + " " + row.name.last,
        //     birthday: row.bio.birthday
        //   }));

        //     /* generate worksheet and workbook */
        //     const worksheet = XLSX.utils.json_to_sheet(rows);
        //     const workbook = XLSX.utils.book_new();
        //     XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");
        //     //worksheet["!margins"]={left:0.7, right:0.7, top:0.75,bottom:0.75,header:0.3,footer:0.3}
        //     /* fix headers */
            
        //     XLSX.utils.sheet_add_aoa(worksheet, [["Name", "Birthdayw"]], { origin: "A1" });
        //     XLSX.utils.sheet_add_aoa(worksheet, [["Created "+new Date().toISOString()]], {origin:"D6"})
        //     /* calculate column width */
        //     const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
        //     worksheet["!cols"] = [ { wch: max_width } ];
        //     /** combinar Aquí s s start =, r = row, c = col, e = end */
        //     //const merge = [{ s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },{ s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },];
        //     //worksheet["!merges"] = merge;
        //     /* create an XLSX file and try to save to Presidents.xlsx */
        //     XLSX.writeFile(workbook, "Presidents.xlsx");

        break;
    
      default:
        break;
    }


    //alert("hola button onClickE")
    // const fileType = "xlsx"
    // const nameFileType = "CUBOFLEXDATA"
    // const response = await fetch(url0,opti);
    // const datadtos = await response.json();
    // const ws = XLSX.utils.json_to_sheet(datadtos);
    // const wb = {Sheets:{data:ws}, SheetNames:["data"]};
    // const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
    // const data = new Blob([excelBuffer], {type:fileType});
    // FileSaver.saveAs(data, `${nameFileType}.xlsx`);

    
  }


  return (
    
    <div>
        <h4 className="fw-bold py-1"> Reportes de Gastos </h4>
        <div className="card card-action mb-4">
            <div className="card-body">
              <div className="row">
                  <div className="col-lg-2">Sucursal:</div>
                  <div className="col-lg-2">
                    <SelectSucursal id="emp_slc_suc" value={slc_t_suc} onChange={changeSlc_t_suc} />
                  </div>
                  <div className="col-md-2">Periodo:</div>
                  <div className="col-md-2">
                    <DatePicker
                      locale="es"
                      className="form-control form-control-sm text-center"
                      selected={txt_periodo} 
                      onChange={date => setTxt_periodo(date)} 
                      dateFormat="yyyy-MM"
                      showMonthYearPicker
                    />
                  </div>
                  <div className="col-md-2">Operación:</div>
                  <div className="col-md-2">
                    <SelectTipo id="slc_semanas" url={url_sem+'?txt_periodo='+moment(txt_periodo).format('YYYY-MM')} value={slc_t_sem} onChange={changeSlc_t_sem} />
                  </div>
                </div>
                <div className="row mb-2">
                  
                  <div className="col-md-2">Tipo de Gasto:</div>
                  <div className="col-md-2">
                    <SelectTipo id="slc_t_tga" url={url_tga} value={slc_t_tga} onChange={changeSlc_t_tga} />
                  </div>
                  <div className="col-md-2">Operación:</div>
                  <div className="col-md-6">
                  <select  className="form-select form-select-sm" value={slc_t_rpo} onChange={changeSlc_t_rpo} >
                      <option value='9999' className='text-center' >-- Selecionar --</option>
                      <option value='R001' >R001 - Formato control diario</option>
                      <option value='R002' >R002 - Gasto general por periodo</option>
                  </select>
                  </div>
                </div>
                
                <div className="row">
                  <div className=" text-center ">
                    <button className="btn btn-primary btn-sm" id="btn_consultar"  onClick={onClickC} >
                      <i className='bx bx-cloud-download bx-ms' id="btn_consultar_icon"></i>
                      <span className="spinner-border d-none" id="btn_consultar_spinner"  role="status" aria-hidden="true"></span>
                      &nbsp;Consultar
                    </button>
                    <button className="btn btn-danger btn-sm" id="btn_rppdf"  onClick={onClickP} >
                      <i className='bx bxs-file-pdf bx-ms' id="btn_rppdf_icon"></i>
                      <span className="spinner-border d-none" id="btn_rppdf_spinner"  role="status" aria-hidden="true"></span>
                      &nbsp;PDF
                    </button>
                    <button className="btn btn-success btn-sm" id="btn_rpexcel"  onClick={onClickE} >
                      <i className='bx bx-file bx-ms' id="btn_rpexcel_icon"></i>
                      <span className="spinner-border d-none" id="btn_rpexcel_spinner"   role="status" aria-hidden="true"></span>
                      &nbsp;EXCEL
                    </button>
                  </div>
                </div>
                
            </div>
        </div>
      </div>
  )
}

export default MGR_Reportes