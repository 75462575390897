/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
//, useEffect , Box
//notify_warning
import React, { useState, useEffect, useCallback } from "react";
import Cookies from 'universal-cookie';
import { ToastContainer } from 'react-toastify';
import { notify_warning } from '../components/utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Tooltip, Box }from '@mui/material';
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css';
import { SelectTipo } from '../components/Selects/SelectsPrincipales';


import AsyncSelect from 'react-select/async';
import '../App.css';

import PdfEJEM from "../components/pdf/PdfEjemplo";
import { PDFDownloadLink } from "@react-pdf/renderer"
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


const styles = {
    control: (provided, state) => ({
        ...provided,
        background: `#fff`,
        borderColor: `#D2D5DC`,
        minHeight: `29px`,
        height: `29px`,
        boxShadow: state.isFocused ? null : null,
        border: '1px solid #D2D5DC',

      }),
  
      valueContainer: (provided, state) => ({
        ...provided,
        height: `29px`,
        padding: `0 6px`,
        color: `#697a8d`,
      }),
  
      input: (provided, state) => ({
        ...provided,
        color: `#697a8d`,
        fontSize: `0.75rem`,
        
      }),
      indicatorSeparator: state => ({
        display: `none`,
        
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: `29px`,
        
      }),
};

const MasterCVentas = () => {
    const cookies = new Cookies();
    const dtUser = cookies.get('dtUser');
    const dtgrve = dtUser.ls_gr_ven
    const dtSucu = dtUser.sl_dt_suc[0].suc_nombre
    //const txt_IdSuperv = dtUser.idsupervisor
    const txt_IdSuperv = dtUser.CodigoSupervisor
    const [pending, setPending] = useState(false);
    const [users, setUsers] = useState( [] )
    const [sl_vendedores, setsl_vendedores] = useState([]);

    /** Filtros inputs */
    const [sl_t_suc, setsl_t_suc] = useState(dtSucu);
    const [sl_t_ven, setsl_t_ven] = useState('');
    const [sl_t_cli, setsl_t_cli] = useState([""]);
    const [sl_t_mesas, setsl_mesas] = useState('');
    const changeSetsl_mesas = (event) => { setsl_mesas(event.target.value); }

    useEffect(() => {
        savePathInCookie();
      }, []);
      function savePathInCookie() {
        const path = window.location.pathname.substr(1); // Obtiene la parte después de la barra diagonal
    
        // Guarda el valor en una cookie con nombre "ultimapag"
        document.cookie = `ultimapag=${path}`;
      }
    
    const changeSl_t_suc = (event) => { 
        setsl_t_suc(event.target.value); 
        fc_lstVen(event.target.value)
    }
    const changeSl_t_ven = (event) => { setsl_t_ven(event.target.value); }
    const [inp_text_nuncompr, setinp_text_nuncompr] = useState('');
    const [inp_text_fecdesde, setInp_text_fecdesde] = useState(new Date());
    const [inp_text_fechasta, setInp_text_fechasta] = useState(new Date());
    // const [sl_dt_suc, setSl_dt_suc] = useState([]);
    // const fc_lstSuc = useCallback(async (r_tpu,r_cod) => {
    //     const url_ven = '/post-data-sucursales'
    //     let body = { txt_tpu: r_tpu,txt_cod: r_cod};
    //     const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    //     const response = await fetch(url_ven,opPe);
    //     const data = await response.json();
    //     setSl_dt_suc(data)
    // },[])
    //const [inp_text_ncredito, setinp_text_ncredito] = useState('');

    
    const changeinp_text_nuncompr = (event) => { setinp_text_nuncompr(event.target.value); }
    //const changeinp_text_ncredito = (event) => { setinp_text_ncredito(event.target.value); }

    const fc_lstVen = useCallback(async (r_suc) => {
        const url_ven = '/post-data-vendedores'
        let body = { sl_t_suc: r_suc, lst_graven: dtgrve,txt_IdSuperv:txt_IdSuperv };
        const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        const response = await fetch(url_ven,opPe);
        const data = await response.json();
        setsl_vendedores(data)
    },[dtgrve,txt_IdSuperv])

    const fc_getOptionsSelect = async (inputValue) => {
        const url_ven = '/post-sl-clientes'
        let body = { sl_t_suc: sl_t_suc, inp_text: inputValue, lst_graven: dtgrve, };
        const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        const response = await fetch(url_ven,opPe);
        const data = await response.json();
        return data;
    }
    
    const dtcodu = dtUser.usu_dni
    const [sl_t_grupoventa, setsl_t_grupoventa] = useState('9999');
    const changeSl_t_grupoventa = (event) => { setsl_t_grupoventa(event.target.value); }
    const [sl_grupoventa, setSl_grupoventa] = useState([]);
    const fc_lstGrupoVenta = useCallback(async (r_suc) => {
        const url_ven = '/post-data-grupoventas'
        let body = { usu_tipo: dtUser.tpu_id, sl_t_suc: r_suc, usu_dni: dtcodu };
        const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        const response = await fetch(url_ven,opPe);
        const data = await response.json();
        setSl_grupoventa(data)
    },[dtcodu,dtUser.tpu_id])
    useEffect(() => {
        fc_lstGrupoVenta(sl_t_suc)
    },[fc_lstGrupoVenta,sl_t_suc])





    /** text modal */
    const [txt_codOperacion, setTxt_codOperacion] = useState('')
    const changeTxt_codOperacion = (event) => { setTxt_codOperacion(event.target.value); }
    const [txt_detOperacion, setTxt_detOperacion] = useState('')
    const changeTxt_detOperacion = (event) => { setTxt_detOperacion(event.target.value); }
    const [txt_detDocumento, setTxt_detDocumento] = useState('')
    const changeTxt_detDocumento = (event) => { setTxt_detDocumento(event.target.value); }
    const [txt_codDocumento, setTxt_codDocumento] = useState('')
    const changeTxt_codDocumento = (event) => { setTxt_codDocumento(event.target.value); }
    const [txt_codCliente, setTxt_codCliente] = useState('')
    const changeTxt_codCliente = (event) => { setTxt_codCliente(event.target.value); }
    const [txt_detCliente, setTxt_detCliente] = useState('')
    const changeTxt_detCliente = (event) => { setTxt_detCliente(event.target.value); }
    const [txt_docIdentidad, setTxt_docIdentidad] = useState('')
    const changeTxt_docIdentidad = (event) => { setTxt_docIdentidad(event.target.value); }
    const [txt_fecha, setTxt_fecha] = useState('')
    const changeTxt_fecha = (event) => { setTxt_fecha(event.target.value); }
    const [txt_moneda, setTxt_moneda] = useState('S/')
    const changeTxt_moneda = (event) => { setTxt_moneda(event.target.value); }
    const [txt_fecEmision, setTxt_fecEmision] = useState('')
    const changeTxt_fecEmision = (event) => { setTxt_fecEmision(event.target.value); }
    const [txt_codAlmacen, setTxt_codAlmacen] = useState('')
    const changeTxt_codAlmacen = (event) => { setTxt_codAlmacen(event.target.value); }
    const [txt_detAlmacen, setTxt_detAlmacen] = useState('')
    const changeTxt_detAlmacen = (event) => { setTxt_detAlmacen(event.target.value); }
    /** ddw1 */
    const [txt_codVendedor, setTxt_codVendedor] = useState('')
    const changeTxt_codVendedor = (event) => { setTxt_codVendedor(event.target.value); }
    const [txt_detVendedor, setTxt_detVendedor] = useState('')
    const changeTxt_detVendedor = (event) => { setTxt_detVendedor(event.target.value); }
    const [txt_observacion, setTxt_observacion] = useState('')
    const changeTxt_observacion = (event) => { setTxt_observacion(event.target.value); }
    /** ddw2 */
    const [txt_subtotal, setTxt_subtotal] = useState('0.00')
    const changeTxt_subtotal = (event) => { setTxt_subtotal(event.target.value); }
    const [txt_valorAfecto, setTxt_valorAfecto] = useState('0.00')
    const changeTxt_valorAfecto = (event) => { setTxt_valorAfecto(event.target.value); }
    const [txt_descuentoA, setTxt_descuentoA] = useState('0.00')
    const changeTxt_descuentoA = (event) => { setTxt_descuentoA(event.target.value); }
    const [txt_inpuesto, setTxt_inpuesto] = useState('')
    const changeTxt_inpuesto = (event) => { setTxt_inpuesto(event.target.value); }
    const [txt_descuentoB, setTxt_descuentoB] = useState('0.00')
    const changeTxt_descuentoB = (event) => { setTxt_descuentoB(event.target.value); }
    const [txt_valorInafecto, setTxt_valorInafecto] = useState('0.00')
    const changeTxt_valorInafecto = (event) => { setTxt_valorInafecto(event.target.value); }
    const [txt_recargo, setTxt_recargo] = useState('0.00')
    const changeTxt_recargo = (event) => { setTxt_recargo(event.target.value); }
    const [txt_Exonerado, setTxt_Exonerado] = useState('0.00')
    const changeTxt_Exonerado = (event) => { setTxt_Exonerado(event.target.value); }
    const [txt_percepcion, setTxt_percepcion] = useState('0.00')
    const changeTxt_percepcion = (event) => { setTxt_percepcion(event.target.value); }
    const [txt_icb, setTxt_icb] = useState('0.00')
    const changeTxt_icb = (event) => { setTxt_icb(event.target.value); }
    const [txt_total, setTxt_total] = useState('0.00')
    const changeTxt_total = (event) => { setTxt_total(event.target.value); }
    

    const [data_lleg, setData_lleg] = useState([]);
    const [data_docu, setData_docu] = useState([]);
    const [data_comp, setData_comp] = useState([]);


    const url0 = '/post-clientes-comprobantes';
    const url1 = '/post-numdocumento';
    const url2 = '/post-lstcomp';
    const url3 = '/api-sl-mesas';
    let body_t = { 
        idt_text: dtUser.tpu_id,
        sl_t_suc: sl_t_suc,
        sl_t_ven: sl_t_ven,
        sl_t_cli: sl_t_cli,
        lst_graven: dtgrve,
        sl_t_grupoventa: sl_t_grupoventa,
        inp_text_nuncompr: inp_text_nuncompr,
        inp_text_fecdesde: inp_text_fecdesde,
        inp_text_fechasta: inp_text_fechasta,
        sl_t_mesas: sl_t_mesas
    };
    const opti_t = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body_t) }
    const clickHandler = async(state) => {
        const dtLlegada = JSON.parse(state.target.id);
        setData_lleg(dtLlegada)
        let body = { inp_text: dtLlegada.Documento, txt_sucu: dtLlegada.Sucursal, inp_fecha_emision: dtLlegada.FechaEmision };
        const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        const response = await fetch(url1,opPe);
        const data = await response.json();
        //console.log("data-> ", data.Concepto);
        setData_docu(data)

        setTxt_codOperacion(data.Cod_Concepto)
        setTxt_detOperacion(data.Concepto)
        //setTxt_detDocumento()
        setTxt_codDocumento(dtLlegada.Documento)
        setTxt_codCliente(data.CodCliente)
        setTxt_detCliente(dtLlegada.Cliente)
        setTxt_docIdentidad(data.DocIdentidad)
        setTxt_fecha(dtLlegada.FechaEmision)
        //setTxt_moneda()
        setTxt_fecEmision(dtLlegada.FechaEmision)
        setTxt_codAlmacen(data.IDSucursal)
        setTxt_detAlmacen(data.Sucursal)

        setTxt_codVendedor(dtLlegada.CodVendedor)
        setTxt_detVendedor(dtLlegada.Vendedor)
        setTxt_descuentoA(data.ValorVentaBonif)
        setTxt_descuentoB(data.ValorDescuento)
        setTxt_subtotal(data.Monto_Origen)
        setTxt_valorAfecto(data.VentaAfecta)
        setTxt_inpuesto(Number(data.ValorIGV).toFixed(2))
        setTxt_total(data.VentaTotal)

        const lstTabla = async () => {
            let body2 = { inp_text: dtLlegada.Documento, txt_sucu: dtLlegada.Sucursal, inp_fecha_emision: dtLlegada.FechaEmision };
            const opPe2 = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body2) }
            const response2 = await fetch(url2,opPe2);
            const data2 = await response2.json();
            setData_comp(data2)
        }
        lstTabla()

    };
    
    const columns = [
        { cell:(row) =>
          <div className="align-items-center">
              <button type="button" className="btn btn-sm btn-icon btn-label-info" onClick={clickHandler} id={JSON.stringify(row)} data-bs-toggle="modal" data-bs-target="#exLargeModal">+</button>
          </div>
          ,ignoreRowClick: true, allowOverflow: true, button: true, name: 'OPCIÓN' },
        { name: 'Documento',    selector: row => row.Documento,    cellExport: row => row.Documento,      sortable: true, width: "9rem" },
        { name: 'Cliente',      selector: row => row.Cliente,      cellExport: row => row.Cliente,        sortable: true, width: "20rem" },
        { name: 'Mesa',         selector: row => row.Mesa,       cellExport: row => row.Mesa,       sortable: true },
        { name: 'Abre',         selector: row => row.Abre,         cellExport: row => row.Abre,           sortable: true },
        { name: 'FechaEmision', selector: row => row.FechaEmision, cellExport: row => row.FechaEmision,   sortable: true },
        { name: 'V.Afecta',        selector: row => row.Total,        cellExport: row => row.Total,          sortable: true },
        { name: 'V.Total',        selector: row => row.Saldo,        cellExport: row => row.Saldo,          sortable: true },
        { name: 'Saldo',  selector: row => row.Saldo2,  cellExport: row => row.Saldo2,    sortable: true },
        { name: 'Estado',       selector: row => row.Estado,       cellExport: row => row.Estado,         sortable: true },
        { name: 'CodVendedor',  selector: row => row.CodVendedor,  cellExport: row => row.CodVendedor,    sortable: true },
        { name: 'Vendedor',     selector: row => row.Vendedor,     cellExport: row => row.Vendedor,       sortable: true, width: "20rem" },
        { name: 'Vehiculo',     selector: row => row.Vehiculo,     cellExport: row => row.Vehiculo,       sortable: true },
        { name: 'Conductor',    selector: row => row.Conductor,    cellExport: row => row.Conductor,      sortable: true, width: "20rem" },
        { name: 'Detraccion',   selector: row => row.Detraccion,   cellExport: row => row.Detraccion,     sortable: true },
        { name: 'M_Detraccion', selector: row => row.M_Detraccion, cellExport: row => row.M_Detraccion,   sortable: true },
        { name: 'Credito',      selector: row => row.Credito,      cellExport: row => row.Credito,        sortable: true },
        { name: 'M_Credito',    selector: row => row.M_Credito,    cellExport: row => row.M_Credito,      sortable: true },
        { name: 'Sucursal',     selector: row => row.Sucursal,     cellExport: row => row.Sucursal,       sortable: true },
        { name: 'Observacion',  selector: row => row.observacion,  cellExport: row => row.observacion,    sortable: true },
    ];

    const onClickProce = async () => {

      setPending(true)
      document.getElementById('btn_procesar').disabled = "true";
      document.getElementById('btn_procesar_icon').setAttribute('class', 'd-none');
      document.getElementById('btn_procesar_spinner').className = 'spinner-border';
      
        const showData = async () => {
            const response = await fetch(url0,opti_t);
            const data = await response.json();
            const tableData = { columns, data, };
            setUsers(tableData)
            document.getElementById('btn_procesar').disabled = "";
            document.getElementById('btn_procesar_icon').setAttribute('class', 'bx bx-cloud-download bx-ms');
            document.getElementById('btn_procesar_spinner').className = 'spinner-border d-none';
            setPending(false)
        }
        showData()
    }

    const onClickExcel = async () => {
        const fileType = "xlsx"
        const nameFileType = "LST_COMPORBANTES"
        document.getElementById('btn_procesar_excel').disabled = "true";
        document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'd-none');
        document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border';
        const showData = async () => {
          const response = await fetch(url0,opti_t);
          const datadtos = await response.json();

          if (datadtos !== '0' ) {
            const ws = XLSX.utils.json_to_sheet(datadtos);
            const wb = {Sheets:{data:ws}, SheetNames:["data"]};
            const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
            const data = new Blob([excelBuffer], {type:fileType});
            FileSaver.saveAs(data, `${nameFileType}.xlsx`);
          }else{
            notify_warning("No existen datos, verificar campos")
            document.getElementById('btn_procesar_excel').disabled = "";
            document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
            document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';
          }
          
        }
        showData();
    }

    const onClickReset = () => {
        setinp_text_nuncompr("")
        setInp_text_fecdesde(new Date())
        setInp_text_fechasta(new Date())
        //setinp_text_ncredito("")
        
        setsl_t_cli("")
        setsl_t_suc("9999")
        setsl_t_ven("9999")
    }
    const onClickClose = () => {
        setTxt_codOperacion('')
        setTxt_detOperacion('')
        setTxt_detDocumento('')
        setTxt_codDocumento('')
        setTxt_codCliente('')
        setTxt_detCliente('')
        setTxt_docIdentidad('')
        setTxt_fecha('')
        setTxt_moneda('')
        setTxt_fecEmision('')
        setTxt_codAlmacen('')
        setTxt_detAlmacen('')
        setTxt_codVendedor('')
        setTxt_detVendedor('')
        setTxt_observacion('')
        setTxt_subtotal('0.00')
        setTxt_valorAfecto('0.00')
        setTxt_descuentoA('0.00')
        setTxt_inpuesto('0.00')
        setTxt_descuentoB('0.00')
        setTxt_valorInafecto('0.00')
        setTxt_recargo('0.00')
        setTxt_Exonerado('0.00')
        setTxt_percepcion('0.00')
        setTxt_icb('0.00')
        setTxt_total('0.00')
        setData_comp([])
    }

    useEffect(() => {
        fc_lstVen(dtSucu)
        //(dtUser.tpu_id,dtUser.usu_dni)
    },[fc_lstVen,dtSucu])
    //},[fc_lstVen,fc_lstSuc,dtUser.SUCURSAL,dtUser.tpu_id,dtUser.usu_dni])

  return (
    <div>
        <h4 className="fw-bold py-3 mb-4 d-none"> Filtrar </h4>
        <div className="card card-action mb-4">
            <div className="card-header">
                <div className="card-action-title">Filtrar</div>
                <div className="card-action-element">
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                            <a href="javascript:void(0);" className="card-expand d-none"><i className="tf-icons bx bx-fullscreen"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body">
            <div className="row ">
                <div className="col-md-2">Sucursal:</div>
                  <div className="col-md-4">
                    <select className="form-select form-select-sm"  value={sl_t_suc} onChange={changeSl_t_suc} >
                        {
                            dtUser.sl_dt_suc?.map((item,index) => {
                                return <option value={item?.suc_nombre} key={index} >{item?.suc_nombre}</option>
                            })
                        }
                        {/* <option value={"9999"} >TODOS</option>
                        <option value={"TUMBES"} >TUMBES</option>
                        <option value={"TRUJILLO"} >TRUJILLO</option>
                        <option value={"CHICLAYO"} >CHICLAYO</option>
                        <option value={"CHIMBOTE"} >CHIMBOTE</option>
                        <option value={"PIURA"} >PIURA</option>
                        <option value={"HUAMACHUCO"} >HUAMACHUCO</option>
                        <option value={"CAJAMARCA"} >CAJAMARCA</option> */}
                    </select>
                  </div>
            </div> 
                <div className="row ">
                <div className="col-md-2">Mesa:</div>
                  <div className="col-md-2">
                    <SelectTipo id="slc_semanas" url={url3+'?idt_text='+dtUser.tpu_id+'&sl_t_suc='+sl_t_suc+'&inp_text='+dtcodu} value={sl_t_mesas} onChange={changeSetsl_mesas} />
                </div>
                  <div className="col-md-2">GrupoVenta:</div>
                    <div className="col-md-4">
                        <select className="form-select form-select-sm"  value={sl_t_grupoventa} onChange={changeSl_t_grupoventa} >
                            <option value={"9999"} >TODOS</option>
                            {   
                                sl_grupoventa?.map((item,index) => {
                                    return <option value={item?.GrupoVenta} key={index} >{item?.DescripcionGrupoVentas}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="row ">
                  <div className="col-md-2">NComprobante:</div>
                  <div className="col-md-2">
                      <input type="text" id="txt_numcomp" name="txt_filtro" className="form-control form-control-sm" value={inp_text_nuncompr} onChange={changeinp_text_nuncompr} ></input>
                  </div>
                  <div className="col-md-2">Cliente:</div>
                  <div className="col-md-6 ">
                    {/** from-control form-control-sm select2 form-select form-select-lg */}
                    <AsyncSelect
                        styles={styles}
                        placeholder="Busque cliente por codigo o descripción"
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm"  
                        value={sl_t_cli}
                        cacheOptions
                        defaultOptions={false}
                        getOptionLabel={e => e.desclientes}
                        getOptionValue={e => e.desclientes}
                        loadOptions={fc_getOptionsSelect}
                        onChange={setsl_t_cli}
                        isClearable={true}
                        openMenuOnClick={false}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-2">Fecha Emision:</div>
                  <div className="col-md-2">
                    <Tooltip title="Desde" placement="left">
                        <Box>
                            <DatePicker 
                                locale="es"
                                className="form-control form-control-sm text-center"
                                selected={inp_text_fecdesde} 
                                onChange={date => setInp_text_fecdesde(date)} 
                                dateFormat="yyyy-MM-dd"
                            />
                        </Box>
                    </Tooltip>
                    
                  </div>
                  <div className="col-md-2">
                    <Tooltip title="Hasta" placement="left" >
                        <Box >                   
                            <DatePicker 
                                locale="es"
                                className="form-control form-control-sm text-center"
                                selected={inp_text_fechasta} 
                                onChange={date => setInp_text_fechasta(date)} 
                                dateFormat="yyyy-MM-dd"
                            />
                        </Box>
                    </Tooltip>
                  </div>
                  <div className="col-md-2">C.Vendedor:</div>
                  <div className="col-md-2">
                    <Tooltip title="Vendedores" placement="top">
                        <Box>
                            <select className="form-select form-select-sm" value={sl_t_ven}  onChange={changeSl_t_ven}>
                                <option value={"9999"} >TODOS</option>
                                {
                                    sl_vendedores?.map((item,index) => {
                                       return <option value={item?.vu_detalle} key={index} >{item?.vu_detalle}</option>
                                    })
                                }

                            </select>
                        </Box>
                    </Tooltip>
                  </div>
                  
                </div>

                <div className="row ">
                    <div className="col-md-4 "></div>
                    <div className="col-md-4 text-center ">
                        <Tooltip title="Consultar información" placement="top">
                            <button className="btn btn-primary btn-sm" id="btn_procesar" onClick={onClickProce} >
                                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                                &nbsp;Consultar
                            </button>
                        </Tooltip>
                        <Tooltip title="Exportar Excel" placement="top">
                            <button className="btn btn-success btn-sm" id="btn_procesar_excel" onClick={onClickExcel} >
                                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                                <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                                &nbsp;Exportar Excel
                            </button>
                        </Tooltip>
                        <Tooltip title="Resetar Campos" placement="top">
                            <button className="btn btn-dark btn-sm" id="btn_reset" onClick={onClickReset} >
                                <i className='bx bx-reset bx-ms' id="btn_procesar_icon"></i>
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div className="card card-action mb-4">
            <div className="card-header">
                <div className="card-action-title">Lista Comprobantes de Ventas</div>
                <div className="card-action-element">
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                            <a href="javascript:void(0);" className="card-expand d-none" ><i className="tf-icons bx bx-fullscreen"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body">
                <div className="card-datatable table-responsive">
                    <DataTableExtensions {...users} >
                        <DataTable
                            Header
                            defaultSortField="id"
                            defaultSortAsc={true}
                            pagination
                            responsive  
                            highlightOnHover
                            progressPending={pending}
                        />
                    </DataTableExtensions>
                </div>
            </div>
        </div>
        {/** Modal */}
        <div className="modal fade" id="exLargeModal" data-bs-backdrop="static" tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content" >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel1">Comprobante de venta</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-lg-1"><label htmlFor="text" className="form-label">Operación</label></div>
                            <div className="col-lg-2">
                                <div className="input-group">
                                    <input type="text" id="txt_document" className="form-control form-control-sm" placeholder="" value={txt_codOperacion} onChange={changeTxt_codOperacion} disabled />
                                    <button className="btn btn-danger btn-sm" type="button" id="button-addon2" disabled >
                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="input-group">
                                    <input type="text" id="nameBasiwc" className="form-control form-control-sm" placeholder=""  value={txt_detOperacion} onChange={changeTxt_detOperacion} disabled />
                                    <button className="btn btn-danger btn-sm" type="button" id="button-addon2" disabled >
                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-1"><label htmlFor="text" className="form-label">Documento</label></div>
                            <div className="col-lg-3">
                                <div className="input-group">
                                    <input type="text" id="nameBasiwc" className="form-control form-control-sm" placeholder="" value={txt_detDocumento} onChange={changeTxt_detDocumento} disabled />
                                    <button className="btn btn-danger btn-sm" type="button" id="button-addon2" disabled >
                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <input type="text" id="nameBasiwc" className="form-control form-control-sm" placeholder="" value={txt_codDocumento} onChange={changeTxt_codDocumento} disabled />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-1"><label htmlFor="text" className="form-label">Cliente</label></div>
                            <div className="col-lg-2">
                                <div className="input-group">
                                    <input type="text" id="nameBasiwc" className="form-control form-control-sm" placeholder="" value={txt_codCliente} onChange={changeTxt_codCliente} disabled />
                                    <button className="btn btn-danger btn-sm" type="button" id="button-addon2" disabled >
                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="input-group">
                                    <input type="text" id="nameBasiwc" className="form-control form-control-sm" placeholder="" value={txt_detCliente} onChange={changeTxt_detCliente} disabled />
                                    <button className="btn btn-danger btn-sm" type="button" id="button-addon2" disabled >
                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-1"><label htmlFor="text" className="form-label">Doc.Identid</label></div>
                            <div className="col-lg-2">
                                <div className="input-group">
                                    <input type="text" id="nameBasiwc" className="form-control form-control-sm" placeholder="" value={txt_docIdentidad} onChange={changeTxt_docIdentidad} disabled />
                                    <button className="btn btn-danger btn-sm" type="button" id="button-addon2" disabled >
                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-1"><label htmlFor="text" className="form-label">Fecha</label></div>
                            <div className="col-lg-2">
                                <input type="text" id="nameBasiwc" className="form-control form-control-sm" placeholder="" value={txt_fecha} onChange={changeTxt_fecha} disabled />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-1"><label htmlFor="text" className="form-label">Monedad</label></div>
                            <div className="col-lg-2">
                                <div className="input-group">
                                    <input type="text" id="nameBasiwc" className="form-control form-control-sm" placeholder="" value={txt_moneda} onChange={changeTxt_moneda} disabled />
                                    <button className="btn btn-danger btn-sm" type="button" id="button-addon2" disabled>
                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6"></div>
                            <div className="col-lg-1"><label htmlFor="text" className="form-label">F.Emision</label></div>
                            <div className="col-lg-2">
                                <input type="text" id="nameBasiwc" className="form-control form-control-sm" placeholder="" value={txt_fecEmision} onChange={changeTxt_fecEmision} disabled />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-1"><label htmlFor="text" className="form-label">Almacén</label></div>
                            <div className="col-lg-2">
                                <div className="input-group">
                                    <input type="text" id="nameBasiwc" className="form-control form-control-sm" placeholder="" value={txt_codAlmacen} onChange={changeTxt_codAlmacen} disabled/>
                                    <button className="btn btn-danger btn-sm" type="button" id="button-addon2" disabled >
                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="input-group">
                                    <input type="text" id="nameBasiwc" className="form-control form-control-sm" placeholder="" value={txt_detAlmacen} onChange={changeTxt_detAlmacen} disabled/>
                                    <button className="btn btn-danger btn-sm" type="button" id="button-addon2" disabled >
                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="nav-align-top" style={{fontSize:12}}>
                            <ul className="nav nav-tabs" role="tablist" >
                                <li className="nav-item">
                                    <button className="btn btn-danger nav-link active" data-bs-toggle="tab" data-bs-target="#form-tabs-personal" role="tab" aria-selected="true">Detalle</button>
                                </li>
                                <li className="nav-item d-none">
                                    <button className="btn btn-danger  nav-link " data-bs-toggle="tab" data-bs-target="#form-tabs-account" role="tab" aria-selected="false">Grupos</button>
                                </li>
                                <li className="nav-item d-none">
                                    <button className="btn btn-danger  nav-link" data-bs-toggle="tab" data-bs-target="#form-tabs-social" role="tab" aria-selected="false">Logística</button>
                                </li>
                            </ul>
                            <div className="tab-content  px-0 py-0">
                                <div className="tab-pane fade active show" id="form-tabs-personal" role="tabpanel">

                                    <div className="table-responsive text-nowrap">
                                        <table className="table table-sm" >
                                            <thead >
                                                <tr className="table-danger" >
                                                    <th>Codigo</th>
                                                    <th>Descripción</th>
                                                    <th>Cantidad</th>
                                                    <th>Unidad</th>
                                                    <th>N°Lote</th>
                                                    <th>PrecioUnitario</th>
                                                    <th>Alamacen</th>
                                                    <th>%Descuento</th>
                                                    <th>PrecioNeto</th>
                                                    <th>Total</th>
                                                    <th>Vale</th>
                                                    <th>EscalaP.</th>
                                                    <th>Bonif.</th>
                                                    <th>Bonif.Manual</th>
                                                    <th>Observación</th>
                                                    <th>% Detracción</th>
                                                    <th>MontoDetra.</th>
                                                    <th>ValorRef.</th>
                                                    <th>Descuento</th>
                                                    <th>Recargo</th>
                                                    <th>Inafecto</th>
                                                    <th>Exonerado</th>
                                                    <th>Ctas.Destino</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0">
                                            {
                                                data_comp?.map((item,index) => {
                                                    const preUnitario = (Number(item.Monto_Origen)/Number(item.Cantidad)).toFixed(2)
                                                    return (<tr key={index} >
                                                        <td>{item.CodProducto}</td>
                                                        <td>{item.Producto}</td>
                                                        <td>{item.Cantidad}</td>
                                                        <td>{item.Unidad}</td>
                                                        <td></td>
                                                        <td>{preUnitario}</td>
                                                        <td>{item.Sucursal}</td>
                                                        <td>0.00</td>
                                                        <td>{preUnitario}</td>
                                                        <td>{Number(item.VentaTotal).toFixed(2)}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>)
                                                })
                                            }

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className="tab-pane fade" id="form-tabs-account" role="tabpanel">
                                    Hola 2
                                </div>
                                <div className="tab-pane fade" id="form-tabs-social" role="tabpanel">
                                    Hola 3
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 py-3">
                                <div className="row">
                                    <div className="col-lg-2"><label htmlFor="text" className="form-label">Vendedor</label></div>
                                    <div className="col-lg-3">
                                        <div className="input-group">
                                            <input type="text" id="text_vendedor" className="form-control form-control-sm" placeholder="" value={txt_codVendedor} onChange={changeTxt_codVendedor} disabled />
                                            <button className="btn btn-danger btn-sm" type="button" id="button-addon2" disabled >
                                                <i className="bx bx-dots-horizontal-rounded"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="input-group">
                                            <input type="text" id="text_vendedor" className="form-control form-control-sm" placeholder="" value={txt_detVendedor} onChange={changeTxt_detVendedor} disabled />
                                            <button className="btn btn-danger btn-sm" type="button" id="button-addon2" disabled >
                                                <i className="bx bx-dots-horizontal-rounded"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2"><label htmlFor="text" className="form-label">Observación</label></div>
                                    <div className="col-lg-10">
                                        <textarea  type="text" id="text_vendedor" className="form-control" placeholder="" rows={3} value={txt_observacion} onChange={changeTxt_observacion} disabled ></textarea >
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-6 py-3">
                                <div className="row">
                                    <div className="col-lg-3"><label htmlFor="text" className="form-label">Sub Total</label></div>
                                    <div className="col-lg-3"><input type="text" id="text_subTotal" className="form-control form-control-sm text-end" placeholder="" value={txt_subtotal} onChange={changeTxt_subtotal} disabled /></div>
                                    <div className="col-lg-3"><label htmlFor="text" className="form-label">Valor Afecto</label></div>
                                    <div className="col-lg-3"><input type="text" id="text_subTotal" className="form-control form-control-sm text-end" placeholder="" value={txt_valorAfecto} onChange={changeTxt_valorAfecto} disabled /></div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3"><label htmlFor="text" className="form-label">Descuento A</label></div>
                                    <div className="col-lg-3"><input type="text" id="text_subTotal" className="form-control form-control-sm text-end" placeholder="" value={txt_descuentoA} onChange={changeTxt_descuentoA} disabled /></div>
                                    <div className="col-lg-3"><label htmlFor="text" className="form-label">Impuesto</label></div>
                                    <div className="col-lg-3"><input type="text" id="text_subTotal" className="form-control form-control-sm text-end" placeholder="" value={txt_inpuesto} onChange={changeTxt_inpuesto} disabled /></div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3"><label htmlFor="text" className="form-label">Descuento B</label></div>
                                    <div className="col-lg-3"><input type="text" id="text_subTotal" className="form-control form-control-sm text-end" placeholder="" value={txt_descuentoB} onChange={changeTxt_descuentoB} disabled /></div>
                                    <div className="col-lg-3"><label htmlFor="text" className="form-label">Valor Ina.</label></div>
                                    <div className="col-lg-3"><input type="text" id="text_subTotal" className="form-control form-control-sm text-end" placeholder="" value={txt_valorInafecto} onChange={changeTxt_valorInafecto} disabled /></div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3"><label htmlFor="text" className="form-label">Recargo</label></div>
                                    <div className="col-lg-3"><input type="text" id="text_subTotal" className="form-control form-control-sm text-end" placeholder="" value={txt_recargo} onChange={changeTxt_recargo} disabled /></div>
                                    <div className="col-lg-3"><label htmlFor="text" className="form-label">Valor Exo.</label></div>
                                    <div className="col-lg-3"><input type="text" id="text_subTotal" className="form-control form-control-sm text-end" placeholder="" value={txt_Exonerado} onChange={changeTxt_Exonerado} disabled /></div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3"><label htmlFor="text" className="form-label">Percepción</label></div>
                                    <div className="col-lg-3"><input type="text" id="text_subTotal" className="form-control form-control-sm text-end" placeholder="" value={txt_percepcion} onChange={changeTxt_percepcion} disabled /></div>
                                    <div className="col-lg-3"><label htmlFor="text" className="form-label">I.C.B</label></div>
                                    <div className="col-lg-3"><input type="text" id="text_subTotal" className="form-control form-control-sm text-end" placeholder="" value={txt_icb} onChange={changeTxt_icb} disabled /></div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6"></div>
                                    <div className="col-lg-3"><label htmlFor="text" className="form-label">Total</label></div>
                                    <div className="col-lg-3"><input type="text" id="text_subTotal" className="form-control form-control-sm text-end" placeholder="" value={txt_total} onChange={changeTxt_total} disabled /></div>
                                </div>

                            </div>
                        </div>


                    </div>
                    {/** [data_comp,data_docu] */}
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-label-secondary" data-bs-dismiss="modal" onClick={onClickClose}>Close</button>
                        <PDFDownloadLink document={<PdfEJEM datallegada={data_comp} datallegadapri={data_lleg} datallegadadoc={data_docu}/>} fileName={txt_codDocumento}>
                        {({loading}) => (loading ? <button type="button" className="btn btn-sm btn-danger "> Loading document...</button> : <button type="button" className="btn btn-sm btn-danger "><i className="tf-icons bx bxs-file-pdf"></i>&nbsp; Exportar PDF</button>)}
                        </PDFDownloadLink>
                        <button type="button" className="btn btn-sm btn-danger d-none">Save changes</button>
                    </div>
                </div>
            </div>
        </div>
        <ToastContainer />
    </div>
  )
}

export default MasterCVentas