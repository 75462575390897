import React from 'react'
import Cookies from 'universal-cookie';

const MPdashboards = () => {
  const cookies = new Cookies();
  const NomProv = cookies.get('dtUserProv_NomProv');

  
  return (
    <div>
      <div className="card-action-title h4">Dashboards - {NomProv}</div>
      <div className="card card-action">
        <div className="card-body ">
          <iframe title="Report Section" width="1140" height="541.25" 
            src="https://app.powerbi.com/view?r=eyJrIjoiYzgxYTQ4YTMtYzc5Yi00YWYyLWE3NGEtZTUxNmI2YWYzOWEyIiwidCI6IjJmZGE2YzgwLTBhMGUtNDA0Ny05YThjLTgyNDk3Mzk4MmRlYiIsImMiOjR9" 
            frameBorder="0" allowFullScreen={true}>
            
          </iframe>
        </div>
      </div>

    </div>
  )
}

export default MPdashboards