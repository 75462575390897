/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect, useCallback } from 'react';
import Cookies from 'universal-cookie';
import { ToastContainer } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { notify_warning } from '../components/utils';
import {Tooltip, Box }from '@mui/material';
//getDateYM,getDateA
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css';
import AsyncSelect from 'react-select/async';
import '../App.css';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { SelectTipo } from '../components/Selects/SelectsPrincipales';

const MasterVStock = () => {
  const cookies = new Cookies();
  const dtUser = cookies.get('dtUser');
  const dtgrve =  dtUser.ls_gr_ven
  const dtSucu = dtUser.sl_dt_suc[0].suc_nombre;
  const [pending, setPending] = useState(false);
  const [users, setUsers] = useState( [] )
  const [sl_proveedores, setsl_proveedores] = useState([]);
  const [sl_almacen, setsl_almacen] = useState([]);
  const [sl_t_unid, setsl_t_unid] = useState([]);
  /** Filtros inputs */
  //const [txt_year, setTxt_year] = useState(getDateA());
  //const [sl_t_fec, setSl_t_fec] = useState('');
  const [sl_t_suc, setsl_t_suc] = useState(dtSucu);
  const [sl_t_alm, setsl_t_alm] = useState('');
  const [sl_t_pro, setsl_t_pro] = useState('');
  const [sl_t_mesas, setsl_mesas] = useState('');

  //const changeTxt_year = (event) => { setTxt_year(event.target.value); }
  //const changeSl_t_fec = (event) => { setSl_t_fec(event.target.value); }
  useEffect(() => {
    savePathInCookie();
  }, []);
  function savePathInCookie() {
    const path = window.location.pathname.substr(1); // Obtiene la parte después de la barra diagonal

    // Guarda el valor en una cookie con nombre "ultimapag"
    document.cookie = `ultimapag=${path}`;
  }

  
  const changeSl_t_suc = (event) => { 
    setsl_t_suc(event.target.value); 
    fc_lstProv(event.target.value);
    fc_lstAlmacen(event.target.value)
  }
  const changesl_t_alm = (event) => { setsl_t_alm(event.target.value); }
  const changesl_t_pro = (event) => { setsl_t_pro(event.target.value); }
  const [inp_text_fe_desde, setInp_text_fe_desde] = useState(new Date());
  const changeSetsl_mesas = (event) => { setsl_mesas(event.target.value); }
  //const [inp_text_fe_hasta, setInp_text_fe_hasta] = useState(getDateD("-"));
  //const [inp_text_peri_mes, setInp_text_peri_mes] = useState(getDateYM("-"));

  const fc_lstProv = useCallback(async (r_suc) => {
    const url_prov = '/post-sl-proveedores'
    let body = { sl_t_suc: r_suc, periodo: inp_text_fe_desde, lst_graven: dtgrve, };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(url_prov,opPe);
    const data = await response.json();
    setsl_proveedores(data)
  },[inp_text_fe_desde,dtgrve])
  const fc_lstAlmacen = useCallback(async (r_suc) => {
    const url_prov = '/post-sl-almacen'
    let body = { sl_t_suc: r_suc, periodo: inp_text_fe_desde, };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(url_prov,opPe);
    const data = await response.json();
    setsl_almacen(data)
  },[inp_text_fe_desde])
  const fc_getOptionsSelect = async (inputValue) => {
    const url_ven = '/post-sl-unidadnegocio'
    let body = { sl_t_suc: sl_t_suc, inp_text: inputValue, lst_graven: dtgrve, };
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(url_ven,opPe);
    const data = await response.json();
    return data;
  }

  const dtcodu = dtUser.usu_dni
  const [sl_t_grupoventa, setsl_t_grupoventa] = useState('9999');
  const changeSl_t_grupoventa = (event) => { setsl_t_grupoventa(event.target.value); }
  const [sl_grupoventa, setSl_grupoventa] = useState([]);
  const fc_lstGrupoVenta = useCallback(async (r_suc) => {
      const url_ven = '/post-data-grupoventas'
      let body = { usu_tipo: dtUser.tpu_id, sl_t_suc: r_suc, usu_dni: dtcodu };
      const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
      const response = await fetch(url_ven,opPe);
      const data = await response.json();
      setSl_grupoventa(data)
  },[dtcodu,dtUser.tpu_id])
  useEffect(() => {
      fc_lstGrupoVenta(sl_t_suc)
  },[fc_lstGrupoVenta,sl_t_suc])

  const url0 = '/post-visualizarstock';
  const url1 = '/api-sl-mesas';

  let body_t = { 
    idt_text: dtUser.tpu_id,
    periodo: inp_text_fe_desde,
    sl_t_suc: sl_t_suc,
    sl_t_alm: sl_t_alm,
    sl_t_pro: sl_t_pro,
    sl_t_uni: sl_t_unid,
    lst_graven: dtgrve,
    sl_t_grupoventa: sl_t_grupoventa,
    sl_t_mesas: sl_t_mesas,
  };
  const opti_t = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body_t) }

  const columns = [   
    { name: 'Sucursal',       selector: row => row.NomSucursal,   cellExport: row => row.NomSucursal,   sortable: true },
    { name: 'Proveedor',      selector: row => row.NomProveedor,  cellExport: row => row.NomProveedor,  sortable: true, width: "10rem" },
    { name: 'Mesa',         selector: row => row.Mesa,       cellExport: row => row.Mesa,       sortable: true },
    { name: 'UnidadNegocio',  selector: row => row.UnidadNegocio, cellExport: row => row.UnidadNegocio, sortable: true, width: "10rem" },
    { name: 'Categoria',      selector: row => row.CategoriaAB,   cellExport: row => row.CategoriaAB,   sortable: true, width: "11rem" },
    { name: 'Codigo',         selector: row => row.Codigo,        cellExport: row => row.Codigo,        sortable: true },
    { name: 'Descripcion',    selector: row => row.Descripcion,   cellExport: row => row.Descripcion,   sortable: true, width: "20rem" },
    { name: 'Uni/PI',         selector: row => row.UMInven,       cellExport: row => row.UMInven,       sortable: true },
    { name: 'Stock',          selector: row => row.CantUMInv,     cellExport: row => row.CantUMInv,     sortable: true },
    { name: 'Uni/PV',         selector: row => row.UMRefe,        cellExport: row => row.UMRefe,        sortable: true},
    { name: 'Stock',          selector: row => row.CantUMRef,     cellExport: row => row.CantUMRef,     sortable: true },
    { name: 'Uni/PC',         selector: row => row.UMCaja,        cellExport: row => row.UMCaja,        sortable: true, width: "10rem" },
    { name: 'Stock',          selector: row => row.CantCaja,      cellExport: row => row.CantCaja,      sortable: true },
    { name: 'Valorizado',     selector: row => row.ValorizadoRea, cellExport: row => row.ValorizadoRea, sortable: true },
    { name: 'FechaRegistro',  selector: row => row.FECHAREG,      cellExport: row => row.FECHAREG,      sortable: true, width: "10rem" },
  ];

  const onClickProce = async () => {

    setPending(true)
    document.getElementById('btn_procesar').disabled = "true";
    document.getElementById('btn_procesar_icon').setAttribute('class', 'd-none');
    document.getElementById('btn_procesar_spinner').className = 'spinner-border';
    
      const showData = async () => {
          const response = await fetch(url0,opti_t);
          const data = await response.json();
          const tableData = { columns, data, };
          setUsers(tableData)
          document.getElementById('btn_procesar').disabled = "";
          document.getElementById('btn_procesar_icon').setAttribute('class', 'bx bx-cloud-download bx-ms');
          document.getElementById('btn_procesar_spinner').className = 'spinner-border d-none';
          setPending(false)
      }
      showData()
  }

  const onClickExcel = async () => {
    //console.log(sl_t_unid.desLinea);
    const fileType = "xlsx"
    const nameFileType = "LISTA_STOCK_"+inp_text_fe_desde;
    document.getElementById('btn_procesar_excel').disabled = "true";
    document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'd-none');
    document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border';
    const showData = async () => {
      const response = await fetch(url0,opti_t);
      const datadtos = await response.json();
      //console.log("datadtos", datadtos);
      if (datadtos !== "0" ) {
        const ws = XLSX.utils.json_to_sheet(datadtos);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
        document.getElementById('btn_procesar_excel').disabled = "";
        document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
        document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';
      }else{ 
        notify_warning("No existen datos, verificar campos") 
        document.getElementById('btn_procesar_excel').disabled = "";
        document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
        document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';
      }
    }
    showData();
  }

  const onClickReset = () => {
      setInp_text_fe_desde(new Date())
      setsl_t_suc("9999")
      setsl_t_pro("9999")
      setsl_t_alm("9999")
      setsl_t_unid("")
  }

  useEffect(() => {
    //fc_lstSuc(dtUser.tpu_id,dtUser.usu_dni)
    fc_lstProv(dtSucu)
    fc_lstAlmacen(dtSucu)

  },[fc_lstProv,fc_lstAlmacen,dtSucu])


  return (
    <div>
        <h4 className="fw-bold py-3 mb-4 d-none"> Filtrar </h4>
        <div className="card card-action mb-4">
            <div className="card-header">
                <div className="card-action-title">Filtrar</div>
                <div className="card-action-element">
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                            <a href="javascript:void(0);" className="card-expand d-none"><i className="tf-icons bx bx-fullscreen"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body">
            <div className="row ">
                <div className="col-md-2">Periodo:</div>
                  <div className="col-md-2">
                    <DatePicker 
                      locale="es"
                      className="form-control form-control-sm text-center"
                      name="inp_text_fe_desde"
                      selected={inp_text_fe_desde} 
                      onChange={date => setInp_text_fe_desde(date)} 
                      dateFormat="yyyy-MM"
                      showMonthYearPicker
                    />
                  </div>
            </div>
                <div className="row ">
                  
                  <div className="col-md-2">Sucursal:</div>
                  <div className="col-md-2">
                    <Tooltip title="Selecionar Almacen" placement="top">
                      <Box>
                        <select className="form-select form-select-sm"  value={sl_t_suc} onChange={changeSl_t_suc}  >
                          {
                            dtUser.sl_dt_suc?.map((item,index) => {
                                return <option value={item?.suc_nombre} key={index} >{item?.suc_nombre}</option>
                            })
                          }
                        </select>
                      </Box>
                    </Tooltip>
                  </div>
                  <div className="col-md-2">Mesa:</div>
                  <div className="col-md-2">
                    <SelectTipo id="slc_semanas" url={url1+'?idt_text='+dtUser.tpu_id+'&sl_t_suc='+sl_t_suc+'&inp_text='+dtcodu} value={sl_t_mesas} onChange={changeSetsl_mesas} />
                  </div>
                  <div className="col-md-2">GrupoVenta:</div>
                    <div className="col-md-2">
                        <select className="form-select form-select-sm"  value={sl_t_grupoventa} onChange={changeSl_t_grupoventa} >
                            <option value={"9999"} >TODOS</option>
                            {   
                                sl_grupoventa?.map((item,index) => {
                                    return <option value={item?.GrupoVenta} key={index} >{item?.DescripcionGrupoVentas}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="row ">
                  <div className="col-md-2">Almacen:</div>
                  <div className="col-md-4">
                    <Tooltip title="Proveedores" placement="top">
                      <Box>
                        <select className="form-select form-select-sm" value={sl_t_alm}  onChange={changesl_t_alm}>
                          <option value={"9999"} >TODOS</option>
                          {
                            sl_almacen?.map((item,index) => {
                                return <option value={item?.CodAlmacen} key={index} >{item?.NomAlmacen}</option>
                            })
                          }

                        </select>
                      </Box>
                    </Tooltip>
                  </div>
                  <div className="col-md-2">Proveedor:</div>
                  <div className="col-md-4">
                    <Tooltip title="Proveedores" placement="top">
                      <Box>
                        <select className="form-select form-select-sm" value={sl_t_pro}  onChange={changesl_t_pro}>
                          <option value={"9999"} >TODOS</option>
                          {
                            sl_proveedores?.map((item,index) => {
                                return <option value={item?.CodProveedor} key={index} >{item?.NomProveedor}</option>
                            })
                          }

                        </select>
                      </Box>
                    </Tooltip>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-2">UnidadNegocio:</div>
                  <div className="col-md-10">
                    <AsyncSelect
                      isMulti
                      placeholder="Busque codigo o descripción"
                      name="form-field-name"
                      className="sele_pru from-control form-control-sm"  
                      value={sl_t_unid}
                      cacheOptions
                      defaultOptions={false}
                      getOptionLabel={e => e.desLinea}
                      getOptionValue={e => e.desLinea}
                      loadOptions={fc_getOptionsSelect}
                      onChange={setsl_t_unid}
                      isClearable={true}
                      openMenuOnClick={false}
                    />
                  </div>
                </div>

                <div className="row ">
                    <div className="col-md-4 "></div>
                    <div className="col-md-4 text-center ">
                        <Tooltip title="Consultar información" placement="top">
                          <button className="btn btn-primary btn-sm" id="btn_procesar" onClick={onClickProce} >
                            <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                            <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                            &nbsp;Consultar
                          </button>
                        </Tooltip>
                        <Tooltip title="Exportar Excel" placement="top">
                            <button className="btn btn-success btn-sm" id="btn_procesar_excel" onClick={onClickExcel} >
                                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                                <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                                &nbsp;Exportar Excel
                            </button>
                        </Tooltip>
                        <Tooltip title="Resetar Campos" placement="top">
                            <button className="btn btn-dark btn-sm" id="btn_reset" onClick={onClickReset} >
                                <i className='bx bx-reset bx-ms' id="btn_procesar_icon"></i>
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div className="card card-action mb-4">
            <div className="card-header">
                <div className="card-action-title">Listar Stock </div>
                <div className="card-action-element">
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                            <a href="javascript:void(0);" className="card-expand d-none" ><i className="tf-icons bx bx-fullscreen"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body">
                <div className="card-datatable table-responsive">
                    <DataTableExtensions {...users} >
                        <DataTable
                            Header
                            defaultSortField="id"
                            defaultSortAsc={true}
                            pagination
                            responsive  
                            highlightOnHover
                            progressPending={pending}
                        />
                    </DataTableExtensions>
                </div>
            </div>
        </div>
        <ToastContainer />
    </div>
  )
}

export default MasterVStock