/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react'
import { NavLink, useLocation  } from 'react-router-dom';
import Cookies from 'universal-cookie';
import logo_ab from '../assets/img/logo/logo_ab.png';
//import logo_ab from '../assets/img/logo/logo_distribuidora_alvarez_bohl_home.png';


export default function Menu() {
  let location = useLocation();
  
  const urlqlle = location.pathname;
  const cookies = new Cookies();
  const dtUser = cookies.get('dtUser');
  
  /** Datos usuario */
  
  
  //const arr_dtUser = JSON.parse(decodeURI(dtUser));
  
  /** Listar Menu */
  const [listMenu, setListaMenu] = useState([])
  const [listMenu2, setListaMenu2] = useState([])
  const [listMenu3, setListaMenu3] = useState([])

  const url_lstMenu = '/api-menu1'
  const url_lstMenu2 = '/api-menu2'
  const url_lstMenu3 = '/api-menu3'
  
  useEffect(() => {
    
    const f_lstMenu = async() => {
      let body = { txt_idusu: dtUser.usu_id, txt_idtusu: dtUser.tpu_id  };
      const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
      const response = await fetch(url_lstMenu,opPe);
      const data = await response.json();
      setListaMenu(data) 
    }
  
    const f_lstMenu2 = async() => {
      let body = { txt_idusu: dtUser.usu_id, txt_idtusu: dtUser.tpu_id };
      const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
      const response = await fetch(url_lstMenu2,opPe);
      const data = await response.json();
      setListaMenu2(data)
      //setPrube(data)
    }

    const f_lstMenu3 = async() => {
      let body = { txt_idusu: dtUser.usu_id, txt_idtusu: dtUser.tpu_id };
      const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
      const response = await fetch(url_lstMenu3,opPe);
      const data = await response.json();
      setListaMenu3(data)
    }
    
    f_lstMenu()
    f_lstMenu2()
    f_lstMenu3()

    
  },[dtUser.usu_id,dtUser.tpu_id])

  /** Seleccionar item */
  const actMenuItem = (det) =>{
    return (urlqlle === det) ? "menu-item active" : "menu-item"
  }

  const actMenu = (blue) =>{
    const resde2 = listMenu2.find(element => element.descMenu === urlqlle)
    const susnd2 = (resde2) ? resde2.subNivel : 0
    return ( blue ===  susnd2  ) ? "menu-item active open" : "menu-item "
  }



  return (
    <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
      <div className="app-brand demo ">
          <NavLink to={dtUser.descMenu} className="app-brand-link" > 
          <span className="app-brand-logo demo">
            <img src={logo_ab} alt="logo Alvarez Bohl"/>
          </span>
          <span className="app-brand-text demo menu-text fw-bolder ms-1">Alvarez Bohl</span>
          </NavLink>
          <a href="javascript:void(0);" className="layout-menu-toggle menu-link text-large ms-auto" >
              <i className="bx bx-chevron-left bx-sm align-middle" />
          </a>
      </div>
      <div className="menu-inner-shadow" />
      <ul className="menu-inner py-1">
        {
          listMenu?.map((item,index) => {
            if (item.descMenu) {
              //console.log("nivel 1 => ",index);
              return (
                <li className={actMenuItem(item.descMenu)} key={index}>
                  <NavLink to={item.descMenu} className="menu-link" >
                    <i className={item.iconMenu}></i>
                    <div data-i18n={item.nomMenu} >{item.nomMenu}</div>
                  </NavLink>
                </li>
              )
            } else {
              //console.log("nivel 1 => ",index);
                return (
                  <li className={actMenu(item.menu_id)} key={index}>
                    <a href="javascript:void(0);" className="menu-link menu-toggle">
                      <i className={item.iconMenu}></i>
                      <div data-i18n={item.nomMenu} >{item.nomMenu}</div>
                    </a>
                    <ul className="menu-sub">
                      {
                        listMenu2?.map((item2,index2) => {
                          if (item.menu_id === item2.subNivel) {
                            if (item2.descMenu) {
                              //console.log("nivel 2 => ",index2);
                              return (
                                <li className={actMenuItem(item2.descMenu,item2.subNivel)} key={index2}>
                                  <NavLink to={item2.descMenu} className="menu-link" >
                                    <div data-i18n={item2.nomMenu} >{item2.nomMenu}</div>
                                  </NavLink>
                                </li>
                              )
                            } else {
                              //console.log("nivel 2 => ",index2);
                              return (
                                <li className="menu-item" key={index2}>
                                    <a href="javascript:void(0);" className="menu-link menu-toggle" >
                                      <div data-i18n={item2.nomMenu} >{item2.nomMenu}</div>
                                    </a>
                                    <ul className="menu-sub">
                                      {
                                        listMenu3?.map((item3,index3) => {
                                          if (item2.menu_id === item3.subNivel) {
                                            if (item3.descMenu) {
                                              return (
                                                <li className="menu-item " key={index3}>
                                                  <NavLink to={item3.descMenu} className="menu-link" >
                                                    <div data-i18n={item3.nomMenu} >{item3.nomMenu}</div>
                                                  </NavLink>
                                                </li>
                                              )
                                            } else {
                                              return null
                                            }
                                            
                                          } else {
                                            return null
                                          }
                                        })
                                      }

                                    </ul>
                                </li>
                              )
                            }
                          } else{ return null }
                        })
                      }
                    </ul>
                  </li>
                )

            }

          })
        }
      </ul>
    </aside>
  )
}
