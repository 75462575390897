/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react'
import Cookies from 'universal-cookie';
import { ToastContainer } from 'react-toastify';
import { notify_warning } from '../components/utils';
import DatePicker from "react-datepicker";
//import DatePicker,{ registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import es from 'date-fns/locale/es';
//import {Tooltip, Box }from '@mui/material';
import {Tooltip }from '@mui/material';
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css';
import AsyncSelect from 'react-select/async';
import { SelectTipo } from '../components/Selects/SelectsPrincipales';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
//registerLocale('es', es)

const styles = {
    control: (provided, state) => ({
        ...provided,
        background: `#fff`,
        borderColor: `#D2D5DC`,
        minHeight: `29px`,
        height: `29px`,
        boxShadow: state.isFocused ? null : null,
        border: '1px solid #D2D5DC',

      }),
  
      valueContainer: (provided, state) => ({
        ...provided,
        height: `29px`,
        padding: `0 6px`,
        color: `#697a8d`,
      }),
  
      input: (provided, state) => ({
        ...provided,
        color: `#697a8d`,
        fontSize: `0.75rem`,
        
      }),
      indicatorSeparator: state => ({
        display: `none`,
        
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: `29px`,
        
      }),
};

const MasterListaPrecios = () => {
  const cookies = new Cookies();
  const dtUser = cookies.get('dtUser');
  const dtgrve =  dtUser.ls_gr_ven
  const [pending, setPending] = useState(false);
  const [users, setUsers] = useState( [] )
    //const [sl_proveedores, setsl_proveedores] = useState([]);
  const [sl_t_unid, setsl_t_unid] = useState([]);
  const [sl_t_mesas, setsl_mesas] = useState('');

    /** Filtros inputs */
    //var sucur = dtUser.SUCURSAL
    //if (sucur === 'PIURA') { sucur = '001'; } else if (sucur === 'TUMBES') { sucur = '002'; } else if (sucur === 'TRUJILLO') { sucur = '003'; } else if (sucur === 'CHIMBOTE') { sucur = '004'; } else if (sucur === 'CHICLAYO') { sucur = '005'; } else if (sucur === 'CAJAMARCA') { sucur = '006'; } else if (sucur === 'HUAMACHUCO') { sucur = '008'; } else { sucur = '9999'; }
    
    const [sl_t_suc, setsl_t_suc] = useState(dtUser.sl_dt_suc[0].suc_cod);
    const dtSucu = dtUser.sl_dt_suc[0].suc_nombre;
    const [sl_t_suc2, setsl_t_suc2] = useState(dtSucu);
    //const [sl_t_pro, setsl_t_pro] = useState('');
    const changeSl_t_suc = (event) => {
        setsl_t_suc2(event.target.value)
        setsl_t_suc(event.target.value);
    }
    const changeSetsl_mesas = (event) => { setsl_mesas(event.target.value); }
    //const changeSl_t_pro = (event) => { setsl_t_pro(event.target.value); }
    //const [inp_text_femision, setInp_text_femision] = useState(new Date());
    const [inp_text_peri_mes, setInp_text_peri_mes] = useState(new Date());

    useEffect(() => {
        savePathInCookie();
      }, []);
      function savePathInCookie() {
        const path = window.location.pathname.substr(1); // Obtiene la parte después de la barra diagonal
    
        // Guarda el valor en una cookie con nombre "ultimapag"
        document.cookie = `ultimapag=${path}`;
      }

    const fc_getOptionsSelect = async (inputValue) => {
        const url_ven = '/post-sl-unidadnegocio'
        let body = { sl_t_suc: sl_t_suc, inp_text: inputValue, lst_graven: dtgrve, };
        const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        const response = await fetch(url_ven,opPe);
        const data = await response.json();
        return data;
      }
    const dtcodu = dtUser.usu_dni
    const [sl_t_grupoventa, setsl_t_grupoventa] = useState('9999');
    const changeSl_t_grupoventa = (event) => { setsl_t_grupoventa(event.target.value); }
    const [sl_grupoventa, setSl_grupoventa] = useState([]);
    const fc_lstGrupoVenta = useCallback(async (r_suc) => {
        const url_ven = '/post-data-grupoventas'
        let body = { usu_tipo: dtUser.tpu_id, sl_t_suc: r_suc, usu_dni: dtcodu };
        const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
        const response = await fetch(url_ven,opPe);
        const data = await response.json();
        setSl_grupoventa(data)
    },[dtcodu,dtUser.tpu_id])
    useEffect(() => {
        fc_lstGrupoVenta(sl_t_suc)
    },[fc_lstGrupoVenta,sl_t_suc])

    const url0 = '/post-listaprecios';
    const url1 = '/api-sl-mesas';
    
    let body_t = { 
        idt_text: dtUser.tpu_id,
        sl_t_suc: sl_t_suc,
        sl_t_unid: sl_t_unid,
        periodo: inp_text_peri_mes,
        lst_graven: dtgrve,
        sl_t_grupoventa: sl_t_grupoventa,
        usu_tipo: dtUser.tpu_id,
        sl_t_mesas: sl_t_mesas
        //inp_text_femision: inp_text_femision.replace("-","").replace("-",""),
    };
    const opti_t = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body_t) }

    const columns = [
        { name: 'Sucursal',         selector: row => row.Sucursal,      cellExport: row => row.Sucursal,    sortable: true, width: "8rem"},
        { name: 'Periodo',          selector: row => row.Periodo,       cellExport: row => row.Periodo,     sortable: true, width: "7rem" },
        { name: 'Codigo',           selector: row => row.CodProducto,   cellExport: row => row.CodProducto, sortable: true },
        { name: 'Producto',         selector: row => row.Producto,      cellExport: row => row.Producto,    sortable: true, width: "20rem" },
        { name: 'Unidad',           selector: row => row.Unidad,        cellExport: row => row.Unidad,      sortable: true },
        { name: 'Bodegas',          selector: row => row.Bod,           cellExport: row => row.Bod,         sortable: true },
        { name: 'Mercados',         selector: row => row.Mer,           cellExport: row => row.Mer,         sortable: true },
        { name: 'Mayoristas',       selector: row => row.May,           cellExport: row => row.May,         sortable: true },
        { name: 'Stock',            selector: row => row.Stock,         cellExport: row => row.Stock,         sortable: true },    
        { name: 'UnidadNegocio',    selector: row => row.UnidadNegocio, cellExport: row => row.UnidadNegocio,         sortable: true }, 
        { name: 'Mesa',         selector: row => row.Mesa,       cellExport: row => row.Mesa,       sortable: true },
   
    ];

    const onClickProce = async () => {

    setPending(true)
    document.getElementById('btn_procesar').disabled = "true";
    document.getElementById('btn_procesar_icon').setAttribute('class', 'd-none');
    document.getElementById('btn_procesar_spinner').className = 'spinner-border';
    
        const showData = async () => {
            const response = await fetch(url0,opti_t);
            const data = await response.json();
            const tableData = { columns, data, };
            setUsers(tableData)
            document.getElementById('btn_procesar').disabled = "";
            document.getElementById('btn_procesar_icon').setAttribute('class', 'bx bx-cloud-download bx-ms');
            document.getElementById('btn_procesar_spinner').className = 'spinner-border d-none';
            setPending(false)
        }
        showData()
    }

    const onClickExcel = async () => {
        const fileType = "xlsx"
        const nameFileType = "LISTA_DE_PRECIOS_"+inp_text_peri_mes
        document.getElementById('btn_procesar_excel').disabled = "true";
        document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'd-none');
        document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border';
        const showData = async () => {
        const response = await fetch(url0,opti_t);
        const datadtos = await response.json();

        if (datadtos.length) {
            const ws = XLSX.utils.json_to_sheet(datadtos);
            const wb = {Sheets:{data:ws}, SheetNames:["data"]};
            const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
            const data = new Blob([excelBuffer], {type:fileType});
            FileSaver.saveAs(data, `${nameFileType}.xlsx`);
            document.getElementById('btn_procesar_excel').disabled = "";
            document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
            document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';
        } else {
            document.getElementById('btn_procesar_excel').disabled = "";
            document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
            document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';
            notify_warning("No existen datos, verificar campos");
        }
        }
        showData();
    }

    const onClickReset = () => {
        //setInp_text_femision(new Date())
        setInp_text_peri_mes(new Date())
        setsl_t_suc("9999")
        //setsl_t_pro("9999")
    }



  return (
    <div>
        <h4 className="fw-bold py-3 mb-4 d-none"> Filtrar </h4>
        <div className="card card-action mb-4">
            <div className="card-header">
                <div className="card-action-title">Filtrar</div>
                <div className="card-action-element">
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                            <a href="javascript:void(0);" className="card-expand d-none"><i className="tf-icons bx bx-fullscreen"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body">
            <div className="row ">
            <div className="col-md-2">Periodo:</div>
                  <div className="col-md-2">
                    <DatePicker
                      locale="es"
                      className="form-control form-control-sm text-center"
                      selected={inp_text_peri_mes} 
                      onChange={date => setInp_text_peri_mes(date)} 
                      dateFormat="yyyy-MM"
                      showMonthYearPicker
                    />
                  </div>
            </div> 
                <div className="row">
                  <div className="col-md-2">Sucursal:</div>
                  <div className="col-md-4">
                    <select className="form-select form-select-sm"  value={sl_t_suc} onChange={changeSl_t_suc}  >
                      {
                          dtUser.sl_dt_suc?.map((item,index) => {
                              return <option value={item?.suc_cod} key={index} >{item?.suc_nombre}</option>
                          })
                      }
                    </select>
                  </div> 
                   
                <div className="col-md-2">Mesa:</div>
                  <div className="col-md-2">
                    <SelectTipo id="slc_semanas" url={url1+'?idt_text='+dtUser.tpu_id+'&sl_t_suc='+sl_t_suc2+'&inp_text='+dtcodu} value={sl_t_mesas} onChange={changeSetsl_mesas} />
                </div>
                  
                  
                </div>
                <div className="row mb-3">
                  <div className="col-md-2">GrupoVenta:</div>
                    <div className="col-md-4">
                        <select className="form-select form-select-sm"  value={sl_t_grupoventa} onChange={changeSl_t_grupoventa} >
                            <option value={"9999"} >TODOS</option>
                            {   
                                sl_grupoventa?.map((item,index) => {
                                    return <option value={item?.GrupoVenta} key={index} >{item?.DescripcionGrupoVentas}</option>
                                })
                            }
                        </select>
                    </div>
                  <div className="col-md-2">Proveedor:</div>
                  <div className="col-md-4">
                    <AsyncSelect
                        styles={styles}
                        placeholder="Busque codigo o descripción"
                        name="form-field-name"
                        className="sele_pru from-control form-control-sm"  
                        value={sl_t_unid}
                        cacheOptions
                        defaultOptions={false}
                        getOptionLabel={e => e.desLinea}
                        getOptionValue={e => e.desLinea}
                        loadOptions={fc_getOptionsSelect}
                        onChange={setsl_t_unid}
                        isClearable={true}
                        openMenuOnClick={false}
                        />
                  </div>
                </div>
                
                

                <div className="row ">
                    <div className="col-md-4 "></div>
                    <div className="col-md-4 text-center ">
                        <Tooltip title="Consultar información" placement="top">
                            <button className="btn btn-primary btn-sm" id="btn_procesar" onClick={onClickProce} >
                                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                                &nbsp;Consultar
                            </button>
                        </Tooltip>
                        <Tooltip title="Exportar Excel" placement="top">
                            <button className="btn btn-success btn-sm" id="btn_procesar_excel" onClick={onClickExcel} >
                                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                                <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                                &nbsp;Exportar Excel
                            </button>
                        </Tooltip>
                        <Tooltip title="Resetar Campos" placement="top">
                            <button className="btn btn-dark btn-sm" id="btn_reset" onClick={onClickReset} >
                                <i className='bx bx-reset bx-ms' id="btn_procesar_icon"></i>
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div className="card card-action mb-4">
            <div className="card-header">
                <div className="card-action-title">Lista Clientes</div>
                <div className="card-action-element">
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                            <a href="javascript:void(0);" className="card-expand d-none" ><i className="tf-icons bx bx-fullscreen"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body">
                <div className="card-datatable table-responsive">
                    <DataTableExtensions {...users} >
                        <DataTable
                            Header
                            defaultSortField="id"
                            defaultSortAsc={true}
                            pagination
                            responsive  
                            highlightOnHover
                            progressPending={pending}
                        />
                    </DataTableExtensions>
                </div>
            </div>
        </div>
        <ToastContainer />
    </div>
  )
}

export default MasterListaPrecios