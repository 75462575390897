/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie';
import { ToastContainer } from 'react-toastify';
import { notify_warning } from '../components/utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip }from '@mui/material';
// import AsyncSelect from 'react-select/async';
//import '../App.css';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
//import $ from 'jquery'
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css';
import '../App.css';

const MasterComisiones = () => {

  useEffect(() => {
    savePathInCookie();
  }, []);
  function savePathInCookie() {
    const path = window.location.pathname.substr(1); // Obtiene la parte después de la barra diagonal

    // Guarda el valor en una cookie con nombre "ultimapag"
    document.cookie = `ultimapag=${path}`;
  }

  const cookies = new Cookies();
  const dtUser = cookies.get('dtUser');
  const dtSucu = dtUser.sl_dt_suc[0].suc_nombre
  const dtidsu = dtUser.idsupervisor
  const [pending, setPending] = useState(false);
  const [users, setUsers] = useState( [] )
  const [sl_t_suc, setsl_t_suc] = useState(dtSucu);
  const changeSl_t_suc = (event) => { setsl_t_suc(event.target.value); }
  const [inp_text_fe_desde, setInp_text_fe_desde] = useState(new Date());

  const url0 = '/post-cubo-comisiones';
  let body_t = { fec_desde: inp_text_fe_desde, slc_t_suc: sl_t_suc, txt_id: dtidsu };
  const opti_t = { method: "POST", headers: {'Content-Type': 'application/json'},compress: true, body: JSON.stringify(body_t) }

  const onClickExcel = async () => {
    //console.log(sl_t_unid.desLinea);
    const fileType = "xlsx"
    const nameFileType = "CUBOCOMISIONES_"+inp_text_fe_desde
    document.getElementById('btn_procesar_excel').disabled = "true";
    document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'd-none');
    document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border';
    const showData = async () => {
      const response = await fetch(url0,opti_t);
      const datadtos = await response.json();
      //console.log("--saas",datadtos);
      //setData(datadtos)
      if (datadtos !== '0' ) {
        const ws = XLSX.utils.json_to_sheet(datadtos);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);

        document.getElementById('btn_procesar_excel').disabled = "";
        document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
        document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';
      }else{
        notify_warning("No existen datos, verificar campos")
        document.getElementById('btn_procesar_excel').disabled = "";
        document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
        document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';
      }

      document.getElementById('btn_procesar_excel').disabled = "";
      document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
      document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';



    
    }
    showData();
  }

  const columns = [   
    { name: 'IDPeriodo',        selector: row => row.IDPeriodo,         cellExport: row => row.IDPeriodo,         sortable: true },
    { name: 'Periodo',          selector: row => row.Periodo,           cellExport: row => row.Periodo,           sortable: true },
    { name: 'IDSucursal',       selector: row => row.IDSucursal,        cellExport: row => row.IDSucursal,        sortable: true },
    { name: 'Sucursal',         selector: row => row.Sucursal,          cellExport: row => row.Sucursal,          sortable: true },
    { name: 'IDRuta',           selector: row => row.IDRuta,            cellExport: row => row.IDRuta,            sortable: true },
    { name: 'Ruta',             selector: row => row.Ruta,              cellExport: row => row.Ruta,              sortable: true },
    { name: 'IDSupervisor',     selector: row => row.IDSupervisor,      cellExport: row => row.IDSupervisor,      sortable: true },
    { name: 'Grupo',            selector: row => row.Grupo,             cellExport: row => row.Grupo,             sortable: true },
    { name: 'EtiquetaComision', selector: row => row.EtiquetaComision,  cellExport: row => row.EtiquetaComision,  sortable: true },
    { name: 'MetaVenta',        selector: row => row.MetaVenta,         cellExport: row => row.MetaVenta,         sortable: true },
    { name: 'AvanceVentas',     selector: row => row.AvanceVentas,      cellExport: row => row.AvanceVentas,      sortable: true },
    { name: 'Porcentaje',       selector: row => row.Porcentaje,        cellExport: row => row.Porcentaje,        sortable: true },
    { name: 'Canal',            selector: row => row.Canal,             cellExport: row => row.Canal,             sortable: true },
    { name: 'Mesa',             selector: row => row.Mesa,              cellExport: row => row.Mesa,              sortable: true },
    { name: 'Linea',            selector: row => row.Linea,             cellExport: row => row.Linea,             sortable: true }
  ];

  const onClickProce = async () => {
    setPending(true)
    document.getElementById('btn_procesar').disabled = "true";
    document.getElementById('btn_procesar_icon').setAttribute('class', 'd-none');
    document.getElementById('btn_procesar_spinner').className = 'spinner-border';
    
      const showData = async () => {
          const response = await fetch(url0,opti_t);
          const data = await response.json();
          const tableData = { columns, data, };
          setUsers(tableData)
          document.getElementById('btn_procesar').disabled = "";
          document.getElementById('btn_procesar_icon').setAttribute('class', 'bx bx-cloud-download bx-ms');
          document.getElementById('btn_procesar_spinner').className = 'spinner-border d-none';
          setPending(false)
      }
      showData()
  }


  return (
    <div>
        <div className="card card-action mb-4">
            <div className="card-header">
                <div className="card-action-title">Filtrar</div>
                <div className="card-action-element">
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                            <a href="javascript:void(0);" className="card-expand d-none"><i className="tf-icons bx bx-fullscreen"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-2">Periodo:</div>
                <div className="col-md-2">
                  <DatePicker 
                    locale="es"
                    className="form-control form-control-sm text-center" 
                    selected={inp_text_fe_desde} 
                    onChange={date => setInp_text_fe_desde(date)} 
                    dateFormat="yyyy-MM"
                    showMonthYearPicker
                  />
                </div>
                <div className="col-md-2">Sucursal:</div>
                <div className="col-md-2">
                  <select id='slc_sucur' className="form-select form-select-sm" value={sl_t_suc} onChange={changeSl_t_suc}  >
                    {
                      dtUser.sl_dt_suc?.map((item,index) => {
                        return <option value={item?.suc_nombre} key={index} >{item?.suc_nombre}</option>
                      })
                    }
                  </select>
                </div>
                <div className="col-md-4 text-center ">
                    <Tooltip title="Consultar información" placement="top">
                        <button className="btn btn-primary btn-sm" id="btn_procesar" onClick={onClickProce} >
                            <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                            <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                        </button>
                    </Tooltip>
                    <Tooltip title="Exportar Excel" placement="top">
                        <button className="btn btn-success btn-sm" id="btn_procesar_excel" onClick={onClickExcel} >
                            <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                            <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                        </button>
                    </Tooltip>
                    {/* <Tooltip title="Resetar Campos" placement="top">
                        <button className="btn btn-dark btn-sm" id="btn_reset" onClick={onClickReset} >
                            <i className='bx bx-reset bx-ms' id="btn_procesar_icon"></i>
                        </button>
                    </Tooltip> */}
                </div>
              </div>
            </div>
        </div>
        <div className="card card-action mb-4">
            <div className="card-header">
                <div className="card-action-title">Listar  </div>
            </div>
            <div className="card-body">
                <div className="card-datatable table-responsive">
                    <DataTableExtensions {...users} >
                        <DataTable className="tabla-comisiones"
                            Header
                            defaultSortField="id"
                            defaultSortAsc={true}
                            pagination
                            responsive  
                            highlightOnHover
                            progressPending={pending}
                        />
                    </DataTableExtensions>
                </div>
            </div>
        </div>
        <ToastContainer />
    </div>
  )
}

export default MasterComisiones