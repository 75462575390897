import React from 'react'
//import ImgError404 from '../assets/img/illustrations/page-misc-error-light.png';
import ImgError404 from '../../assets/img/illustrations/page-misc-error-light.png';
import Cookies from 'universal-cookie';
const error404 = () => {
  const cookies = new Cookies();
  const onClickCerrarSession = () => {
    cookies.set('CookieSession', 'False');
    cookies.remove('ultimapag');
    cookies.remove('dtUser_tipoUsu')
    cookies.remove('dtUser_nombre')
    cookies.remove('dtUser_sexo')
    cookies.remove('dtUser')
    //sessionStorage.getItem("user_logueado")
    sessionStorage.removeItem("user_logueado")
    //alert(deds)
    window.location.href = "/login"
  }


  return (
    <div className="container-xxl container-p-y text-center">
      <div className="misc-wrapper">
        <h2 className="mb-2 mx-2">Usted salio del sistema :(</h2>
        <p className="mb-4 mx-2">Tiene que iniciar session nuevamente por seguridad.</p>
        <button type='button' className="btn btn-primary" onClick={onClickCerrarSession} >Iniciar Sesión</button>
        <div className="mt-3">
          <img src={ImgError404} alt="page-misc-error-light" width={500} className="img-fluid" data-app-dark-img="illustrations/page-misc-error-dark.png" data-app-light-img="illustrations/page-misc-error-light.png" />
        </div>
      </div>
    </div>

  )
}
export default error404