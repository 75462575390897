import { useState } from "react";
import * as RB from "reactstrap";
import * as FaIcons from "react-icons/fa";
import { ToastContainer } from 'react-toastify';
import {notify_warning} from '../components/utils';
import {Tooltip, Box }from '@mui/material';
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css';

/** excel */
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const Salas =  () =>{
  
  const URL = '/post-data-stock' //api de la data;
  /** inputs */
  const [sl_t_suc, setsl_t_suc] = useState('');
  const changeSl_t_suc = (event) => { setsl_t_suc(event.target.value); };
  
  /** Fu para dar click en el tipo de select de fechas */
 

  // Fuc para enviar valores por ele api de los datos para el backend
  let body = { 
    sl_t_suc: sl_t_suc, 
  };
  // Estructura de envio por metodo POST, asi mismo enviando los datos en formato js
  const opPe = {
    method: "POST",
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body)
  }

  /** Funciona 2 **/
  const fileType = "xlsx"
  const nameFileType = "Ejemplo"
  const onClickExcel = async () => {
    document.getElementById('btn_procesar_excel').disabled = "true";
    document.getElementById('btn_excel_icon').setAttribute('class', 'd-none');
    document.getElementById('btn_excel_spinner').className = 'me-1 spinner-border-sm spinner-border';

    if (!sl_t_suc) { 
      notify_warning("Selecionar Sucursal")
      setTimeout(() => { 
        document.getElementById('btn_procesar_excel').disabled = "";
        document.getElementById('btn_excel_icon').setAttribute('class', 'me-1');
        document.getElementById('btn_excel_spinner').className = 'me-1 d-none spinner-border-sm spinner-border';
      }, 1000);

    }

    if (sl_t_suc ) {
      const showData = async () => {
        const response = await fetch(URL,opPe);
        const datadtos = await response.json();
        //setTimeout(()=> {
          console.log("datadtos",datadtos);
          //setUsers(datadtos)
        //}, 1000)
        const ws = XLSX.utils.json_to_sheet(datadtos);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);

        document.getElementById('btn_procesar_excel').disabled = "";
        document.getElementById('btn_excel_icon').setAttribute('class', 'me-1');
        document.getElementById('btn_excel_spinner').className = 'me-1 d-none spinner-border-sm spinner-border';
      }
      showData();        
    }

    
  }
  const [pending, setPending] = useState(true);
  const [users, setUsers] = useState( [] )
  const columns = [
    { name: 'CodProveedor', selector: row => row.CodProveedor, sortable: true },
    { name: 'NomProveedor', selector: row => row.NomProveedor, sortable: true },
    { name: 'CodArtPrv', selector: row => row.CodArtPrv, sortable: true },
    { name: 'CodUniNeg', selector: row => row.CodUniNeg, sortable: true },
    { name: 'NomUniNeg', selector: row => row.NomUniNeg, sortable: true },
    { name: 'CodSucursal', selector: row => row.CodSucursal, sortable: true },
    { name: 'NomSucursal', selector: row => row.NomSucursal, sortable: true },
    { name: 'CodAlmacen', selector: row => row.CodAlmacen, sortable: true },
    { name: 'NomAlmacen', selector: row => row.NomAlmacen, sortable: true },
    { name: 'Codigo', selector: row => row.Codigo, sortable: true },
    { name: 'Descripcion', selector: row => row.Descripcion, sortable: true },
    { name: 'Grupo', selector: row => row.Grupo, sortable: true },
    { name: 'Unidad', selector: row => row.Unidad, sortable: true },
    { name: 'CategoriaAB', selector: row => row.CategoriaAB, sortable: true },
    { name: 'SubCategoriaAB', selector: row => row.CategoriaAB, sortable: true },
    { name: 'Stock', selector: row => row.CategoriaAB, sortable: true },
    { name: 'CostoPrm', selector: row => row.CategoriaAB, sortable: true },
    { name: 'ValorizadoPrm', selector: row => row.CategoriaAB, sortable: true },
    { name: 'CostoReal', selector: row => row.CategoriaAB, sortable: true },
    { name: 'ValorizadoRea', selector: row => row.CategoriaAB, sortable: true },
    { name: 'MarcaPrv', selector: row => row.CategoriaAB, sortable: true },
    { name: 'CategoriaPrv', selector: row => row.CategoriaAB, sortable: true },
    { name: 'SubCategoriaPrv', selector: row => row.CategoriaAB, sortable: true },
    { name: 'CantCaja', selector: row => row.CategoriaAB, sortable: true },
    { name: 'UMCaja', selector: row => row.CategoriaAB, sortable: true },
    { name: 'CantUMRef', selector: row => row.CategoriaAB, sortable: true },
    { name: 'UMRefe', selector: row => row.CategoriaAB, sortable: true },
    { name: 'CantUMInv', selector: row => row.CategoriaAB, sortable: true },
    { name: 'UMInven', selector: row => row.CategoriaAB, sortable: true },
    { name: 'U_AB_CEAN', selector: row => row.CategoriaAB, sortable: true }
  ];
  //const [tableData, setTableData] = useState( [] )
  const onClickProce = async () => {
    document.getElementById('btn_procesar').disabled = "true";
    document.getElementById('btn_procesar_icon').setAttribute('class', 'd-none');
    document.getElementById('btn_procesar_spinner').className = 'me-1 spinner-border-sm spinner-border';

    if (!sl_t_suc) { 
      notify_warning("Selecionar Sucursal")
      setTimeout(() => { 
        document.getElementById('btn_procesar').disabled = "";
        document.getElementById('btn_procesar_icon').setAttribute('class', 'me-1');
        document.getElementById('btn_procesar_spinner').className = 'me-1 d-none spinner-border-sm spinner-border';
      }, 1000);

    }

    if (sl_t_suc ) {
      const showData = async () => {
        const response = await fetch(URL,opPe);
        const data = await response.json();

        const tableData = {
          columns,
          data,
        };

        setUsers(tableData)
        //console.log(data.recordsets[0]);
        document.getElementById('btn_procesar').disabled = "";
        document.getElementById('btn_procesar_icon').setAttribute('class', 'me-1');
        document.getElementById('btn_procesar_spinner').className = 'me-1 d-none spinner-border-sm spinner-border';
      }

      const timeout = setTimeout(() => {
        showData()
        setPending(false)
      },2000);
      
      return () => clearTimeout(timeout) 
      
         
    }

    
  }



  

  return (
    <div >
      <RB.Container className='py-4'>           
        <RB.Card className='w-100 '>
          <RB.CardHeader>
            <RB.CardTitle tag="h5">
              STOCK ACTUAL
            </RB.CardTitle>
            <RB.CardSubtitle className="text-muted" tag="h6" >
              Datos del Sql Server
            </RB.CardSubtitle>
          </RB.CardHeader>
          <RB.CardBody>
            <RB.Row>
              <RB.Col lg={12}>
                <RB.Row>
                  <RB.Col md={1}>
                    <RB.Label >Almacen</RB.Label>
                  </RB.Col>
                  <RB.Col md={3}>
                    <Tooltip title="Selecionar Almacen" placement="top">
                      <Box>
                        <RB.FormGroup>
                          <RB.Input bsSize="sm" className="mb-3" id="sl_t_suc" name="sl_t_suc" type="select"  value={sl_t_suc}  onChange={changeSl_t_suc}  >
                            <option value={""} >-- Elija --</option>
                            <option value={"0010"} >Tumbes</option>
                            <option value={"0012"} >Trujillo</option>
                            <option value={"0014"} >Chiclayo</option>
                            <option value={"0015"} >Chimbote</option>
                            <option value={"0016"} >Piura</option>
                            <option value={"0017"} >Huamachuco</option>
                            <option value={"006"} >Cajamarca</option>
                            <option value={"9999"} >Todos</option>
                          </RB.Input>
                        </RB.FormGroup>
                      </Box>
                    </Tooltip>
                  </RB.Col>
                  <RB.Col md={2}>
                    <Tooltip title="Consultar información" placement="top">
                      <Box>
                        <RB.Button size="sm"
                          block color="primary" 
                          id="btn_procesar" 
                          onClick={onClickProce}  >
                          <RB.Spinner id="btn_procesar_spinner" className="me-1 d-none" size="sm">
                            Loading...
                          </RB.Spinner>
                          <FaIcons.FaCloudDownloadAlt id="btn_procesar_icon" className="me-1" size={20} /> 
                          Consultar
                        </RB.Button>
                      </Box>
                    </Tooltip>
                  </RB.Col>
                  <RB.Col md={1}>
                    <Tooltip title="Exportar Excel" placement="top">
                      <Box>
                        <RB.Button size="sm"
                          color="success" 
                          id="btn_procesar_excel" 
                          onClick={onClickExcel}  >
                          <RB.Spinner id="btn_excel_spinner" className="me-1 d-none" size="sm">
                            Loading...
                          </RB.Spinner>
                          <FaIcons.FaFileExcel id="btn_excel_icon" className="me-1" size={20} /> 
                        </RB.Button>
                      </Box>
                    </Tooltip>
                  </RB.Col>
                  
                </RB.Row>
              </RB.Col>
            </RB.Row>
            <ToastContainer />
          </RB.CardBody>
        </RB.Card>
      </RB.Container>
      <RB.Container className='py-4'>
        <RB.Card className='w-100 '>
          <RB.CardHeader>
            <RB.CardTitle tag="h5">
              Listar
            </RB.CardTitle>
            <RB.CardSubtitle className="text-muted" tag="h6" >
              Datos del Sql Server
            </RB.CardSubtitle>
          </RB.CardHeader>
          <RB.CardBody>
            <DataTableExtensions {...users} >
              <DataTable
                Header
                defaultSortField="id"
                defaultSortAsc={false}
                pagination
                highlightOnHover
                progressPending={pending}
              />
            </DataTableExtensions>
          </RB.CardBody>
        </RB.Card>
      </RB.Container>
    </div>
  )

}
export default Salas;



/**
 *
import React, { Component } from "react";
import {Tooltip, Box }from '@mui/material';
import * as RB from "reactstrap";
import * as FaIcons from "react-icons/fa";
import { ToastContainer } from 'react-toastify';
import {getDateA,getDateD} from '../components/utils'
import Select from 'react-select';
import DataTable from "react-data-table-component";

const options = [
  { value: 'N01', label: 'Vendedor' },
  { value: 'N02', label: 'Zona' },
  { value: 'N03', label: 'Ruta' },
  { value: 'N04', label: 'Proveedor' },
  { value: 'N05', label: 'Clase' },
  { value: 'N06', label: 'Mesa' },
  { value: 'N07', label: 'Linea' },
  { value: 'N07', label: 'Producto' },
  { value: 'N07', label: 'Cliente' },
  { value: 'N07', label: 'Giro' },
]

/**
 * {
  IDSucursal:"ss",
  Sucursal:"ss",
  CodCliente:"ss",
}
 * 
 */
/**
const columns = [
  { name: 'IDSucursal', selector: row => row.IDSucursal, sortable: true },
  { name: 'Sucursal', selector: row => row.Sucursal, sortable: true },
  { name: 'CodCliente', selector: row => row.CodCliente, sortable: true },
  { name: 'Estado', selector: row => row.CodCliente, sortable: true },
  { name: 'Periodo', selector: row => row.CodCliente, sortable: true },
  { name: 'FechaEmision', selector: row => row.CodCliente, sortable: true },
  { name: 'CodCliente', selector: row => row.CodCliente, sortable: true },
]



class Salas extends Component{
  constructor(props){
    super(props)
    this.state = {
      sl_t_suc : "",
      sl_t_po1 : "",
      sl_t_po4 : "",
      sl_t_po6 : "",
      ip_f_ann: getDateA() || "",
      ip_f_did: getDateD("-") || "",
      ip_f_dih: getDateD("-") || "",
    }
    this.handleChange = this.handleChange.bind(this)
    
  }
  

  state = {
    sl_t_niv: null,
  }
  handleChange2 = sl_t_niv => {
    this.setState({sl_t_niv})
    console.log("Option selected:", sl_t_niv);
  }


  handleChange = (event) => {
    const { target } = event;
    //const value = target.type === 'checkbox' ? target.checked : target.value;
    const value = target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };


  
  handleClick (e) {
    
    //notify_pru("Hola reatr")
    //console.log( 'sl_t_suc: ' + this.state.sl_t_suc + ' sl_t_po1: ' + this.state.sl_t_po1 + ' sl_t_niv: ' + this.state.sl_t_niv +  ' f_ann: '+ this.state.ip_f_ann + ' f_did: ' + this.state.ip_f_did + ' f_dih: ' + this.state.ip_f_dih  );
    
    const URL = '/post-data';
    let body = { nom:'0015', apellido: 'sernaque' };
    const opPe = {
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    }
    const showData = async () => {
      const response = await fetch(URL,opPe);
      const data = await response.json();
      console.log("data => ", data.recordsets[0]);
      //setUsers(data.recordsets[0])
      return data.recordsets[0];
    }
    
    showData()

  }
  

  //sl_t_niv,

  render (){
    const { sl_t_suc, sl_t_po1, sl_t_po4, sl_t_po6, ip_f_ann, ip_f_did, ip_f_dih } = this.state;
    return (
      <div >
        <RB.Container className='py-4'>
          <RB.Card className='w-100 '>
            <RB.CardHeader>
              <RB.CardTitle tag="h5">
                Consultar
              </RB.CardTitle>
              <RB.CardSubtitle className="text-muted" tag="h6" >
                Datos del Sql Server
              </RB.CardSubtitle>
            </RB.CardHeader>
            <RB.CardBody>
              <RB.Row>
                <RB.Col lg={8}>
                  <RB.Row>
                    <RB.Col md={6}>
                      <Tooltip title="Selecionar Sucursales" placement="top">
                        <Box>
                          <RB.FormGroup>
                            <RB.Label >Sucursales</RB.Label>
                            <RB.Input bsSize="sm" className="mb-3" id="sl_t_suc" name="sl_t_suc" type="select"  value={sl_t_suc}  onChange= {(e) => this.handleChange(e)}  >
                              <option value={""} >-- Elija --</option>
                              <option value={"001"} >Piura</option>
                              <option value={"002"} >Tumbes</option>
                              <option value={"003"} >Trujillo</option>
                              <option value={"004"} >Chimbote</option>
                              <option value={"005"} >Chiclayo</option>
                              <option value={"006"} >Cajamarca</option>
                              <option value={"008"} >Huamachuco</option>
                              <option value={"999"} >Todos</option>
                            </RB.Input>
                            
                          </RB.FormGroup>
                        </Box>
                      </Tooltip>
                    </RB.Col>
                    <RB.Col md={3}>
                      <RB.FormGroup>
                      <RB.Label >Seleccione</RB.Label>
                        <RB.Input bsSize="sm" className="mb-0" id="sl_t_po1" name="sl_t_po1" type="select" value={sl_t_po1}  onChange= {(e) => this.handleChange(e)}  >
                          <option value={"Facturación"} >Facturación</option>
                          <option value={"Cobertura"} >Cobertura</option>
                        </RB.Input>  
                      </RB.FormGroup>
                    </RB.Col>
                    <RB.Col md={3}>
                      <RB.FormGroup>
                      <RB.Label >Canales</RB.Label>

                        <RB.Input bsSize="sm" className="mb-0" id="sl_t_po6" name="sl_t_po6" type="select" value={sl_t_po6}  onChange= {(e) => this.handleChange(e)}  >
                          <option value={"Todos"} >Todos</option>
                          <option value={"Ambos"} >Ambos</option>
                          <option value={"Mayoristas"}>Mayoristas</option>
                          <option value={"Mercados"} >Mercados</option>
                          <option value={"Bodegas"} >Bodegas</option>
                        </RB.Input>  
                      </RB.FormGroup>
                    </RB.Col> 
                  </RB.Row>
                  <RB.Row>
                    <RB.Col md={3}>
                      <RB.FormGroup>
                        <RB.Label >Fechas</RB.Label>
                        <RB.Input bsSize="sm" className="mb-0" id="sl_t_po4" name="sl_t_po4" type="select" value={sl_t_po4}  onChange= {(e) => this.handleChange(e)}  >
                          <option value={"001"} >Rango fechas</option>
                          <option value={"002"} >Meses</option>
                        </RB.Input>  
                      </RB.FormGroup>
                    </RB.Col>
                    <RB.Col md={3}>
                      <Tooltip title="Consultar Año" placement="top">
                      <Box>
                        <RB.FormGroup>
                        <RB.Label >Año</RB.Label>

                          <RB.Input bsSize="sm" className="mb-0 text-center" id="ip_f_ann" name="ip_f_ann" type="number" placeholder="Año" value={ip_f_ann}  onChange= {(e) => this.handleChange(e)}  />
                        </RB.FormGroup>
                        </Box>
                    </Tooltip>
                    </RB.Col>
                    <RB.Col md={3}>
                      <Tooltip title="Periodo Desde" placement="top">
                        <Box>
                          <RB.FormGroup>
                        <RB.Label >Periodo Desde</RB.Label>

                            <RB.Input bsSize="sm" className="mb-0 text-center" id="ip_f_did" name="ip_f_did" type="text" placeholder="Fecha Desde" value={ip_f_did}  onChange= {(e) => this.handleChange(e)} />
                          </RB.FormGroup>
                        </Box>
                      </Tooltip>
                    </RB.Col>
                    <RB.Col md={3}>
                      <Tooltip title="Periodo Hasta" placement="top">
                        <Box>
                          <RB.FormGroup>
                        <RB.Label >Periodo Hasta</RB.Label>

                            <RB.Input bsSize="sm" className="mb-0 text-center" id="ip_f_dih" name="ip_f_dih" type="text" placeholder="Fecha Hasta" value={ip_f_dih}  onChange= {(e) => this.handleChange(e)} />
                          </RB.FormGroup>
                        </Box>
                      </Tooltip>
                    </RB.Col>
                    <RB.Col md={2}></RB.Col> 
                  </RB.Row>
                </RB.Col>
                <RB.Col lg={4}>
                  <RB.Col md={12}>
                    <Tooltip title="Seleccione el nivel de agrupación" placement="top">
                        <Box>
                          <RB.FormGroup>
                            <RB.Label >Seleccione el nivel de agrupación</RB.Label>

                            <Select  isMulti options={options} placeholder="Seleccione el nivel de agrupación " className="from-control" value={this.state.sl_t_niv}  onChange= {this.handleChange2}  />
                          </RB.FormGroup>
                        </Box>
                      </Tooltip>
                  </RB.Col>   
                </RB.Col>
              </RB.Row>
              <RB.Row className="mb-3">
                <RB.Col md={10}></RB.Col>
                <RB.Col md={2}>
                  <Tooltip title="Consultar información" placement="top">
                    <Box>
                      <RB.Button size="sm" block color="primary" id="btn_procesar" onClick={ (e) => this.handleClick(e) }  > <FaIcons.FaCloudDownloadAlt className="me-1" size={20} /> Procesar</RB.Button>
                    </Box>
                  </Tooltip>
                </RB.Col>
              </RB.Row>
            </RB.CardBody>
          </RB.Card>
        </RB.Container>
        <ToastContainer />
        <RB.Container className='py-4'>           
          <RB.Card className='w-100 '>
            <RB.CardHeader>
              <RB.CardTitle tag="h5">
                Listar
              </RB.CardTitle>
              <RB.CardSubtitle className="text-muted" tag="h6" >
                Datos del Sql Server
              </RB.CardSubtitle>
            </RB.CardHeader>
            <RB.CardBody>
              <DataTable
                  columns={columns}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="300px"
              />
            </RB.CardBody>
          </RB.Card>
        </RB.Container>
      </div>
    )
  }

}
export default Salas;

 columns={columns}
                  data={users}
                  progressPending={pending}
                <RB.Col md={2}>
                  <RB.FormGroup>
                    <RB.Input bsSize="sm" className="mb-0" id="sl_t_po2" name="sl_t_po2" type="select" value={sl_t_po2}  onChange= {(e) => this.handleChange(e)}  >
                      <option value={"Vendedor1"} >Vendedor</option>
                      <option value={"Vendedor2"} >Vendedor</option>
                    </RB.Input>  
                  </RB.FormGroup>
                </RB.Col> 
                <RB.Col md={2}>
                  <RB.FormGroup>
                    <RB.Input bsSize="sm" className="mb-0 " id="sl_t_po3" name="sl_t_po3" type="select" value={sl_t_po3}  onChange= {(e) => this.handleChange(e)}  disabled>
                      <option value={"Producto1"} >Producto</option>
                      <option value={"Producto2"} >Producto</option>
                    </RB.Input>  
                  </RB.FormGroup>
                </RB.Col> 
 * 
 * Class Salas extends Component{
  const handleClick = () => {
    alert("hola mundo")
  }

  return (
    <div >
      <RB.Container className='py-4'>           
        <RB.Card className='w-100 '>
          <RB.CardHeader>
            <RB.CardTitle tag="h5">
              Consultar
            </RB.CardTitle>
            <RB.CardSubtitle className="text-muted" tag="h6" >
              Datos del Sql Server
            </RB.CardSubtitle>
          </RB.CardHeader>
          <RB.CardBody>            
            <RB.Row>
              <RB.Col md={4}>
                <RB.FormGroup>
                  <RB.Input bsSize="sm" className="mb-3"  name="sl_g_sucursales" type="select" >
                    <option>Seleccione Sucursales</option>
                    <option>Ejemplo Sucursar 1</option>
                    <option>Ejemplo Sucursar 3</option>
                    <option>Ejemplo Sucursar 4</option>
                    <option>Ejemplo Sucursar 5</option>
                    <option>Consolidado</option>
                  </RB.Input>  
                </RB.FormGroup>
              </RB.Col>
              <RB.Col md={2}>
                <RB.FormGroup>
                  <RB.Input bsSize="sm" className="mb-0" id="sl_tipo1" name="sl_tipo1" type="select" >
                    <option>Facturación</option>
                    <option>Cobertura</option>
                  </RB.Input>  
                </RB.FormGroup>
              </RB.Col> 
              <RB.Col md={2}>
                <RB.FormGroup>
                  <RB.Input bsSize="sm" className="mb-0" id="sl_nivel" name="sl_nivel" type="select" >
                    <option>Un Nivel</option>
                    <option>Dos Nivel</option>
                  </RB.Input>  
                </RB.FormGroup>
              </RB.Col> 
              <RB.Col md={2}>
                <RB.FormGroup>
                  <RB.Input bsSize="sm" className="mb-0" id="sl_tipo2" name="sl_tipo2" type="select" >
                    <option>Vendedor</option>
                    <option>Vendedor</option>
                  </RB.Input>  
                </RB.FormGroup>
              </RB.Col> 
              <RB.Col md={2}>
                <RB.FormGroup>
                  <RB.Input bsSize="sm" className="mb-0 " id="sl_tipo3" name="sl_tipo3" type="select" disabled>
                    <option>Producto</option>
                    <option>Producto</option>
                  </RB.Input>  
                </RB.FormGroup>
              </RB.Col> 

            </RB.Row>
            <RB.Row>
              <RB.Col md={2}>
                <RB.FormGroup>
                  <RB.Input bsSize="sm" className="mb-0" id="sl_tipo4" name="sl_tipo4" type="select" >
                    <option>Fechas</option>
                    <option>Meses</option>
                  </RB.Input>  
                </RB.FormGroup>
              </RB.Col>
              <RB.Col md={2}>
                <RB.FormGroup>
                  <RB.Input bsSize="sm" className="mb-0" id="sl_tipo5" name="sl_tipo5" type="select" >
                    <option>Soles</option>
                    <option>Unidades</option>
                  </RB.Input>  
                </RB.FormGroup>
              </RB.Col>
              <RB.Col md={2}>
                <RB.FormGroup>
                  <RB.Input bsSize="sm" className="mb-0" id="sl_tipo6" name="sl_tipo6" type="select" >
                    <option>Ambos</option>
                    <option>Mayorista</option>
                    <option>Minorista</option>
                  </RB.Input>  
                </RB.FormGroup>
              </RB.Col> 
            </RB.Row>
            <RB.Row>
              <RB.Col md={2}>
                <RB.FormGroup>
                  <RB.Input bsSize="sm" className="mb-0 text-center" id="f_ann" name="f_ann" type="number" placeholder="Año" />
                </RB.FormGroup>
              </RB.Col>
              <RB.Col md={2}>
                <RB.FormGroup>
                  <RB.Input bsSize="sm" className="mb-0 text-center" id="f_tip" name="f_tip" type="text" placeholder="tipo"  />
                </RB.FormGroup>
              </RB.Col>
              <RB.Col md={2}>
                <RB.FormGroup>
                  <RB.Input bsSize="sm" className="mb-0 text-center" id="f_did" name="f_did" type="text" placeholder="Fecha Desde"  />
                </RB.FormGroup>
              </RB.Col>
              <RB.Col md={2}>
                <RB.FormGroup>
                  <RB.Input bsSize="sm" className="mb-0 text-center" id="f_dih" name="f_dih" type="text" placeholder="Fecha Hasta"  />
                </RB.FormGroup>
              </RB.Col>
              <RB.Col md={2}></RB.Col> 
            </RB.Row>
            <RB.Row className="mb-3">
              <RB.Col md={10}></RB.Col>
              <RB.Col md={2}>
                <RB.Button size="sm" block color="primary" id="btn_procesar" onClick={ handleClick }  > <FaIcons.FaCloudDownloadAlt className="me-1" size={20} /> Procesar</RB.Button>
              </RB.Col>
            </RB.Row>
          </RB.CardBody>
        </RB.Card>
      </RB.Container>
    </div>
  )

}
export default Salas;


 * 
 * 
/** otro pruba input 
const [myFormFields, setFormFields] = React.useState({
  sl_g_sucursales: "",
  sl_tipo1: "",
  sl_nivel: "",
  sl_tipo2: "",
  sl_tipo3: "",
  sl_tipo4: "",
  sl_tipo5: "",
  sl_tipo6: "",
  f_ann: getDateA() || "",
  f_tip: "" || "",
  f_did: getDateD('-') || "",
  f_dih: getDateD('-') || ""
})


// Always have your functions before your return to keep state batches in sync.
const onFormFieldChange = (e) => {
  // No need to return this function can be void  myFormFields,
  setFormFields({
    [e.target.f_ann]: e.target.value,
    [e.target.f_tip]: e.target.value,
    [e.target.f_did]: e.target.value,
    [e.target.f_dih]: e.target.value,
  })
}*/

/** Button 
const handleClick = () => {
console.log(myFormFields.sl_g_sucursales);
console.log(myFormFields.sl_tipo1);
console.log(myFormFields.sl_nivel);
console.log(myFormFields.sl_tipo2);
console.log(myFormFields.sl_tipo3);
console.log(myFormFields.sl_tipo4);
console.log(myFormFields.sl_tipo5);
console.log(myFormFields.sl_tipo6);
console.log(myFormFields.f_ann);
console.log(myFormFields.f_tip);
console.log(myFormFields.f_did);
console.log(myFormFields.f_dih);
} 


 * 
 * 
<RB.Row>
              <RB.Col md={2}>
                <RB.FormGroup>
                  <RB.Input bsSize="sm" className="mb-0 text-center" id="f_ann" name="f_ann" type="number" placeholder="Año" value={ myFormFields.f_ann } onChange={onFormFieldChange} />
                </RB.FormGroup>
              </RB.Col>
              <RB.Col md={2}>
                <RB.FormGroup>
                  <RB.Input bsSize="sm" className="mb-0 text-center" id="f_tip" name="f_tip" type="text" placeholder="tipo" value={ myFormFields.f_tip } onChange={onFormFieldChange}  />
                </RB.FormGroup>
              </RB.Col>
              <RB.Col md={2}>
                <RB.FormGroup>
                  <RB.Input bsSize="sm" className="mb-0 text-center" id="f_did" name="f_did" type="text" placeholder="Fecha Desde" value={ myFormFields.f_did } onChange={onFormFieldChange} />
                </RB.FormGroup>
              </RB.Col>
              <RB.Col md={2}>
                <RB.FormGroup>
                  <RB.Input bsSize="sm" className="mb-0 text-center" id="f_dih" name="f_dih" type="text" placeholder="Fecha Hasta" value={ myFormFields.f_dih } onChange={onFormFieldChange} />
                </RB.FormGroup>
              </RB.Col>
              <RB.Col md={2}></RB.Col> 
            </RB.Row>



<RB.CardText>
              Listar datos requeridos - prueb.2
            </RB.CardText>

<RB.Form action="post" autoComplete="off" method="post">
</RB.Form>


**/
/** Input 
  const [f_dia, setF_dia] = useState(getDateD('-'));
  const [f_mes, setF_mes] = useState(getDateMA('-'));
  const [f_ann, setF_ann] = useState(getDateA());
  const handleCDia = (event) => { setF_dia(event.target.f_dia); };
  const handleCMes = (event) => { setF_mes(event.target.f_mes); };
  const handleCAnn = (event) => { setF_ann(event.target.f_ann); };*/


  /** styles={customStyles} 
const customStyles = {
  control: base => ({
    ...base,
    height: 35,
    //minHeight: 35
  })
}*/