/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
//useEffect , useEffect, useCallback
import React, { useState,useEffect, useCallback } from "react";
import Cookies from 'universal-cookie';
import {Tooltip, Box }from '@mui/material';
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css';
import { newDateFValor } from '../../components/utils';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const DDuplicados = () => {
    const cookies = new Cookies();
    const dtUser = cookies.get('dtUser');
    const dtgrve = dtUser.ls_gr_ven
    const dtcodu = dtUser.usu_dni
    const [pending, setPending] = useState(false);
    const [users, setUsers] = useState( [] )

    useEffect(() => {
        savePathInCookie();
      }, []);
      function savePathInCookie() {
        const path = window.location.pathname.substr(1); // Obtiene la parte después de la barra diagonal
    
        // Guarda el valor en una cookie con nombre "ultimapag"
        document.cookie = `ultimapag=${path}`;
      }

    const [sl_filtro, setsl_filtro] = useState('');
    const dtSucu = dtUser.sl_dt_suc[0].suc_nombre;
    const [sl_t_suc2] = useState(dtSucu);//
    const [sl_t_suc, setsl_t_suc] = useState(dtUser.sl_dt_suc[0].suc_cod);
    const changeSl_t_suc = (event) => {
        setsl_t_suc(event.target.value);
    }
    
    
    const URL = `/apiListarPedidosDuplicados?idt_text=${dtUser.tpu_id}&sl_t_suc=${sl_t_suc}`;
    const opti_t = { method: "GET", headers: {'Content-Type': 'application/json'} }
    const columns = [
        { 
          name: 'Fecha',     
          selector: row => newDateFValor(row.DocDate),         
          cellExport: row => newDateFValor(row.DocDate),         
          sortable: true },
        { 
          name: 'CantidadRepetiodo',       
          selector: row => row.RepetitionCount,       
          cellExport: row => row.RepetitionCount,       
          sortable: true, width: '9rem' },
        { 
          name: 'DocEntry',      
          selector: row => row.DocEntry,          
          cellExport: row => row.DocEntry,          
          sortable: true },
        { 
          name: 'DocNum',         
          selector: row => row.DocNum,       
          cellExport: row => row.DocNum,       
          sortable: true },
        { 
          name: 'Codigo',   
          selector: row => row.CardCode,       
          cellExport: row => row.CardCode,       
          sortable: true},
        { 
          name: 'Nombre',    
          selector: row => row.CardName,     
          cellExport: row => row.CardName,     
          sortable: true, width: '25rem' },
        { 
          name: 'Total',    
          selector: row => row.DocTotal, 
          cellExport: row => row.DocTotal, 
          sortable: true, width: '10rem' },
        { 
          name: 'CodVendedor',    
          selector: row => row.SlpName,        
          cellExport: row => row.SlpName,        
          sortable: true, width: '15rem' },
        { 
          name: 'SUCURSAL.',     
          selector: row => row.U_AB_SUCURSAL,         
          cellExport: row => row.U_AB_SUCURSAL,         
          sortable: true },
        
    ];
    const onClickProce = async () => {
        setPending(true)
        document.getElementById('btn_procesar').disabled = "true";
        document.getElementById('btn_procesar_icon').setAttribute('class', 'd-none');
        document.getElementById('btn_procesar_spinner').className = 'spinner-border';

        const showData = async () => {
            const response = await fetch(URL,opti_t);
            const data = await response.json();
            const tableData = { columns, data, };
            setUsers(tableData)
            document.getElementById('btn_procesar').disabled = "";
            document.getElementById('btn_procesar_icon').setAttribute('class', 'bx bx-cloud-download bx-ms');
            document.getElementById('btn_procesar_spinner').className = 'spinner-border d-none';
            setPending(false)
        }
        showData();
    }

    const onClickExcel = async () => {
        const fileType = "xlsx"
        const nameFileType = "PedidosDuplicados"
        document.getElementById('btn_procesar_excel').disabled = "true";
        document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'd-none');
        document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border';
        const showData = async () => {
          const response = await fetch(URL,opti_t);
          const datadtos = await response.json();
          const ws = XLSX.utils.json_to_sheet(datadtos);
          const wb = {Sheets:{data:ws}, SheetNames:["data"]};
          const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
          const data = new Blob([excelBuffer], {type:fileType});
          FileSaver.saveAs(data, `${nameFileType}.xlsx`);

          document.getElementById('btn_procesar_excel').disabled = "";
          document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
          document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';
        }
        showData();
    }
    

  return (
    <div>
        <h4 className="fw-bold py-3 mb-4 d-none"> Filtrar Por </h4>
        <div className="card card-action mb-4">
            <div className="card-header">
                <div className="card-action-title">Filtrar Por:</div>
                <div className="card-action-element">
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                            <a href="javascript:void(0);" className="card-expand d-none"><i className="tf-icons bx bx-fullscreen"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body">
                    
                <div className="row">
                    <div className="col-md-2">Sucursal:</div>
                    <div className="col-md-2">
                        <select className="form-select form-select-sm"  value={sl_t_suc} onChange={changeSl_t_suc} >
                            {
                                dtUser.sl_dt_suc?.map((item,index) => {
                                    return <option value={item?.suc_cod} key={index} >{item?.suc_nombre}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-2 text-end ">
                        <Tooltip title="Consultar información" placement="top">
                            <button className="btn btn-primary btn-sm" id="btn_procesar" onClick={onClickProce} >
                                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                            </button>
                        </Tooltip>
                        <Tooltip title="Exportar Excel" placement="top">
                            <button className="btn btn-success btn-sm" id="btn_procesar_excel" onClick={onClickExcel} >
                                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                                <span 
                                  className="spinner-border d-none" 
                                  id="btn_procesar_excel_spinner" 
                                  role="status" 
                                  aria-hidden="true"
                                ></span>
                            </button>
                        </Tooltip>
                    </div>
                </div>
                
            </div>
        </div>
        <div className="card card-action mb-4">
            <div className="card-header">
                <div className="card-action-title">Lista Duplicados</div>
                <div className="card-action-element">
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                            <a href="javascript:void(0);" className="card-expand d-none" ><i className="tf-icons bx bx-fullscreen"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body">
                <div className="card-datatable table-responsive">
                    <DataTableExtensions {...users} >
                    <DataTable
                        Header
                        defaultSortField="id"
                        defaultSortAsc={true}
                        pagination
                        responsive
                        highlightOnHover
                        progressPending={pending}
                        noContextMenu="No hay registros para mostrar"
                    />
                    </DataTableExtensions>
                </div>
            </div>
        </div>
        <div className="modal fade" id="basicModal" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel1">Modal title</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="row">
                    <div className="col mb-3">
                        <label htmlFor="nameBasic" className="form-label">Name</label>
                        <input type="text" id="nameBasic" className="form-control" placeholder="Enter Name" />
                    </div>
                    </div>
                    <div className="row g-2">
                    <div className="col mb-0">
                        <label htmlFor="emailBasic" className="form-label">Email</label>
                        <input type="text" id="emailBasic" className="form-control" placeholder="xxxx@xxx.xx" />
                    </div>
                    <div className="col mb-0">
                        <label htmlFor="dobBasic" className="form-label">DOB</label>
                        <input type="text" id="dobBasic" className="form-control" placeholder="DD / MM / YY" />
                    </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button>
                </div>
                </div>
            </div>
            </div>
    </div>
  )
}

export default DDuplicados